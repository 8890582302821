var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useRegisterSW } from 'virtual:pwa-register/react';
var Alert = React.forwardRef(function Alert(props, ref) {
    return React.createElement(MuiAlert, __assign({ elevation: 6, ref: ref, variant: "filled" }, props));
});
var intervalMS = 60 * 60 * 1000;
var SW = function () {
    var _a = useRegisterSW({
        onRegistered: function (r) {
            r && setInterval(function () {
                r.update();
            }, intervalMS);
        },
        /*onRegistered(r) {
            // eslint-disable-next-line prefer-template
            console.log('SW Registered: ' + r);
        },*/
        onRegisterError: function (error) {
            console.log('SW registration error', error);
        },
    }), _b = __read(_a.offlineReady, 2), offlineReady = _b[0], setOfflineReady = _b[1], _c = __read(_a.needRefresh, 2), needRefresh = _c[0], setNeedRefresh = _c[1], updateServiceWorker = _a.updateServiceWorker;
    var close = function () {
        setOfflineReady(false);
        setNeedRefresh(false);
    };
    return (React.createElement(Snackbar, { open: offlineReady || needRefresh, autoHideDuration: offlineReady && !needRefresh ? 5000 : null, onClose: function () { return close(); } },
        React.createElement(Alert, { onClose: function () { return close(); }, severity: "info", sx: { width: '100%' }, action: needRefresh ? (React.createElement(Button, { color: "inherit", size: "small", onClick: function () { return updateServiceWorker(true); } }, "reload")) : null }, !needRefresh ? 'App ready to work offline' : 'New content available, click on reload button to update.')));
};
export default SW;
