var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { memo, useCallback, useMemo, useState } from 'react';
import { DialogContext } from './context';
import { Dialog, DialogContent, DialogActions, DialogTitle as MuiDialogTitle, Icon, IconButton, Typography, useMediaQuery, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
var titleStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    m: 0,
    p: 1,
    pl: 2,
    borderBottom: function (theme) { return "1px solid ".concat(theme.palette.divider); },
};
var closeBtnStyles = {
    maxHeight: 30,
    color: 'grey.500',
};
var DialogTitle = function (_a) {
    var children = _a.children, onClose = _a.onClose;
    return (React.createElement(MuiDialogTitle, { sx: titleStyles },
        React.createElement(Typography, { component: 'span', variant: 'subtitle1' }, children),
        onClose ? (React.createElement(IconButton, { size: 'small', "aria-label": "Close", onClick: onClose, sx: closeBtnStyles },
            React.createElement(Icon, null, "close"))) : null));
};
var DialogBase = memo(function (_a) {
    var id = _a.id, name = _a.name, content = _a.content, title = _a.title, size = _a.size, onClose = _a.onClose, fullScreen = _a.fullScreen;
    return (React.createElement(Dialog, { onClose: function (_, reason) {
            if (reason === 'backdropClick') {
                return;
            }
            onClose();
        }, open: true, scroll: 'paper', maxWidth: size, fullWidth: true, fullScreen: fullScreen, BackdropProps: {
            style: {
                backgroundColor: 'rgba(0, 0, 0, .1)',
                backdropFilter: 'blur(2px)',
            },
        }, 
        // disableAutoFocus
        // disableEnforceFocus
        // disableRestoreFocus
        // disableScrollLock
        disableEscapeKeyDown: true, closeAfterTransition: true, disablePortal: true },
        React.createElement(DialogTitle, { onClose: onClose }, title),
        React.createElement(DialogContent, { sx: {
                py: 1,
                px: 2,
                '&': function (theme) { return 'getScrollbarStyles' in theme ? theme.getScrollbarStyles(1) : {}; },
                position: 'relative',
                zIndex: 0,
                overflowX: 'hidden',
            }, style: { paddingTop: 8 } }, content),
        React.createElement(DialogActions, { id: "dialog-actions-".concat(name || id), disableSpacing: true, sx: { gap: 1, justifyContent: 'flex-start', px: 2, py: 1 } })));
});
DialogBase.displayName = 'MemoizedDialogBase';
var DialogProvider = memo(function (_a) {
    var children = _a.children;
    var _b = __read(useState(function () { return new Map(); }), 2), dialogs = _b[0], setDialogs = _b[1];
    var theme = useTheme();
    var fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var handleCloseAll = useCallback(function () {
        setDialogs(function () { return new Map(); });
    }, []);
    var handleCloseDialog = useCallback(function (id) {
        dialogs.delete(id);
        setDialogs(function () { return new Map(dialogs); });
    }, [dialogs]);
    var handleOpenDialog = useCallback(function (title, content, size, onClose, name) {
        var id = new Date().getTime();
        setDialogs(function () { return new Map(dialogs.set(id, {
            id: id,
            content: content,
            title: title,
            size: size !== null && size !== void 0 ? size : false,
            onClose: function () {
                if (typeof onClose === 'function') {
                    onClose();
                }
                handleCloseDialog(id);
            },
            name: name,
        })); });
        return id;
    }, [dialogs, handleCloseDialog]);
    var contextValue = useMemo(function () { return ({
        openDialog: handleOpenDialog,
        closeDialog: handleCloseDialog,
        closeAll: handleCloseAll,
    }); }, [handleCloseAll, handleCloseDialog, handleOpenDialog]);
    return (React.createElement(DialogContext.Provider, { value: contextValue },
        children,
        __spreadArray([], __read(dialogs.entries()), false).map(function (_a) {
            var _b = __read(_a, 2), id = _b[0], dialog = _b[1];
            return (React.createElement(DialogBase, __assign({ key: id }, dialog, { fullScreen: fullScreen })));
        })));
});
DialogProvider.displayName = 'DialogProvider';
export default DialogProvider;
