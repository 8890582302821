var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Stack, InputLabel } from '@mui/material';
import { TypedDefinition } from '@alta-m1/json-schema-editor/definitions';
var JSONInput = function (props) {
    var _a = props.input, name = _a.name, value = _a.value, onChange = _a.onChange, _b = props.options, label = _b.label, required = _b.required, labelProps = props.labelProps;
    var data = React.useMemo(function () {
        if (typeof value === 'string') {
            try {
                return JSON.parse(value);
            }
            catch (_a) {
                return {
                    type: 'string',
                };
            }
        }
        return {
            type: 'string',
        };
    }, [value]);
    var handleChange = function (data) {
        onChange(JSON.stringify(data));
    };
    return (React.createElement(Stack, { sx: { width: 1 } },
        React.createElement(InputLabel, __assign({ shrink: true, required: required }, labelProps), label),
        React.createElement(TypedDefinition, { data: data, name: name, onChange: handleChange })));
};
export default JSONInput;
