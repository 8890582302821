var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Stack, TextField, MenuItem } from '@mui/material';
import { parameters, isEmptyValue, } from '../utils';
import omit from 'lodash/omit';
var InputField = function (_a) {
    var _b;
    var name = _a.name, options = _a.options, value = _a.value, onChange = _a.onChange;
    var handleChange = React.useCallback(function (event) {
        var value = event.target.value;
        if (options.type === 'number') {
            value = isFinite(value) ? Number(value) : '';
        }
        if (onChange)
            onChange(value);
    }, [onChange, options.type]);
    switch (options.type) {
        case 'select':
            return (React.createElement(TextField, { name: name, value: value !== null && value !== void 0 ? value : '', label: name, select: true, InputLabelProps: {
                    shrink: true,
                }, onChange: handleChange },
                React.createElement(MenuItem, { value: '' },
                    React.createElement("i", null, "None")),
                ((_b = options.values) !== null && _b !== void 0 ? _b : []).map(function (value, i) { return (React.createElement(MenuItem, { key: i, value: value }, value)); })));
        case 'text':
        default:
            return (React.createElement(TextField, { name: name, value: value, label: name, InputLabelProps: {
                    shrink: true,
                }, onChange: handleChange, inputProps: options.type === 'number' ? { inputMode: 'numeric', pattern: '[0-9]*' } : undefined }));
    }
};
var Parameters = function (_a) {
    var data = _a.data, type = _a.type, onChange = _a.onChange;
    var _b = __read(React.useState(data), 2), state = _b[0], setState = _b[1];
    var handleChange = React.useCallback(function (key) { return function (value) {
        var _a;
        var newState = isEmptyValue(value) ? omit(state, [key]) : __assign(__assign({}, state), (_a = {}, _a[key] = value, _a));
        if (onChange) {
            onChange(newState);
        }
        setState(newState);
    }; }, [state, onChange]);
    return (React.createElement(Stack, { spacing: 1.5, sx: { p: 1 } }, Object.entries(parameters[type]).map(function (_a) {
        var _b = __read(_a, 2), name = _b[0], options = _b[1];
        return (React.createElement(InputField, { key: name, name: name, options: options, value: state[name], onChange: handleChange(name) }));
    })));
};
export default Parameters;
