var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useStoreMap, useStore } from 'effector-react';
import { $printersList, $customization, $print, $defaultPrinter, $printersLabels, $certificate, $serializersList } from '.';
export var useCertificateSettings = function () {
    return useStore($certificate);
};
export var usePrinter = function (id) {
    return useStoreMap({
        store: $printersList,
        keys: [id],
        fn: function (printers, _a) {
            var _b = __read(_a, 1), key = _b[0];
            return printers.get(key);
        },
    });
};
export var usePrinters = function () {
    return useStore($printersList);
};
export var usePrintersLabels = function () {
    return useStore($printersLabels);
};
export var useDefaultPrinter = function () {
    return useStore($defaultPrinter);
};
export var useCustomization = function (id) {
    return useStoreMap({
        store: $customization,
        keys: [id],
        fn: function (customization, _a) {
            var _b;
            var _c = __read(_a, 1), key = _c[0];
            return ((_b = customization[key]) !== null && _b !== void 0 ? _b : {
                widths: [],
                order: [],
                hidden: [],
                filters: {},
                sort: [],
            });
        },
    });
};
export var usePrintSettings = function () {
    return useStoreMap({
        store: $print,
        keys: [],
        fn: function (state) {
            return {
                value: state,
                originalValue: Object.fromEntries(Object.entries(state).map(function (_a) {
                    var _b = __read(_a, 2), key = _b[0], value = _b[1];
                    return [key, new RegExp(value)];
                })),
            };
        },
    });
};
export var useSerializers = function () {
    return useStore($serializersList);
};
export var useSerializer = function (id) {
    return useStoreMap({
        store: $serializersList,
        keys: [id],
        fn: function (serializer, _a) {
            var _b = __read(_a, 1), key = _b[0];
            return serializer.get(key);
        },
    });
};
