var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import MenuContext from './context';
import { useMediaQuery, } from '@mui/material';
import { useWhenValueChanges } from '@alta-m1/hooks';
var MenuProvider = function (_a) {
    var children = _a.children, _b = _a.position, position = _b === void 0 ? 'left' : _b, props = __rest(_a, ["children", "position"]);
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _c = __read(React.useState(!isMobile), 2), open = _c[0], setOpen = _c[1];
    var _d = __read(React.useState(props.width || 240), 1), width = _d[0];
    var mounted = React.useRef(false);
    React.useEffect(function () {
        mounted.current = true;
        return function () {
            mounted.current = false;
        };
    });
    useWhenValueChanges(isMobile, function () {
        if (mounted.current)
            setOpen(!isMobile);
    });
    var shift = !isMobile && open;
    var toggle = React.useCallback(function (open) {
        setOpen(open);
    }, []);
    var context = React.useMemo(function () { return ({
        open: open,
        width: width,
        isMobile: isMobile,
        position: position,
        shift: shift,
        toggle: toggle,
    }); }, [toggle, isMobile, open, position, width]);
    return (React.createElement(MenuContext.Provider, { value: context }, children));
};
export default MenuProvider;
