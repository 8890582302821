var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { TextField } from '@mui/material';
import { ErrorAdornment } from '../components';
var IntegerInput = React.memo(function (props) {
    var _a = props.input, value = _a.value, onChange = _a.onChange, inputs = __rest(_a, ["value", "onChange"]), active = props.meta.active, _b = props.options, label = _b.label, readonly = _b.readonly, disabled = _b.disabled, labelProps = props.labelProps, 
    // required,
    loading = props.loading, error = props.error;
    var handleChange = React.useCallback(function (event) {
        onChange(event.target.value || 0);
    }, [onChange]);
    return (React.createElement(TextField, __assign({}, inputs, { value: value || '', label: label, onChange: handleChange, fullWidth: true, autoComplete: "off", type: "text", inputProps: { inputMode: 'numeric', pattern: '[0-9]*' }, InputLabelProps: labelProps, InputProps: {
            readOnly: readonly,
            endAdornment: React.createElement(ErrorAdornment, { message: error, open: active }),
        }, error: !!error && !disabled, disabled: disabled || loading })));
});
export default IntegerInput;
