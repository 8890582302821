var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { $userStore as $user, 
// $userResources,
// $userInfo,
setUser, 
// setUserInfo,
setUserResources, userLocalStorage, $expiredSession, $hasAuthorization, changeUser, loadFx, } from '.';
import { logout, triggerFx } from '../authenticate';
import { $loadingModels } from '../config';
import { sample } from 'effector';
var onSetUser = function (store, _a) {
    var resources = _a.resources, _ = _a.token, info = __rest(_a, ["resources", "token"]);
    return __assign(__assign({}, store), { loginTime: new Date().getTime(), info: info, resources: resources != null && !Array.isArray(resources) && typeof resources === 'object'
            ? Object.values(resources)
            : [] });
};
var onSetUserResources = function (store, resources) {
    return __assign(__assign({}, store), { resources: resources != null && !Array.isArray(resources) && typeof resources === 'object'
            ? Object.values(resources)
            : __spreadArray([], __read(store.resources), false) });
};
$user
    .on(setUser, onSetUser)
    .on(setUserResources, onSetUserResources)
    .on(changeUser, function (_, payload) { return (__assign({}, payload)); })
    .on(logout, function () { return ({ info: {}, resources: [], loginTime: 0 }); });
// eslint-disable-next-line effector/no-watch
$user.watch(userLocalStorage);
sample({
    source: {
        expired: $expiredSession,
        has: $hasAuthorization,
    },
    filter: function (_a) {
        var expired = _a.expired, has = _a.has;
        return has && expired;
    },
    target: logout,
});
sample({
    clock: loadFx.doneData,
    filter: function (_a) {
        var data = _a.data;
        return data.query.message == null;
    },
    fn: function (_a) {
        var data = _a.data;
        var value = data.query.value;
        try {
            var resources = JSON.parse(value).resources;
            return resources;
        }
        catch (_b) {
            return {};
        }
    },
    target: setUserResources,
});
sample({
    clock: triggerFx.done,
    source: $loadingModels,
    filter: function (models, _a) {
        var result = _a.result;
        return models.includes('user') && result;
    },
    target: loadFx,
});
/* $userInfo
    .on(setUserInfo, (store, value) => ({ ...store, ...value }))
    .reset(logout);

$userResources
    .on(setUserResources, (ignored, { resources }) => Object.values(resources))
    .reset(logout);

sample({
    clock: setUser,
    fn(user) {
        const result = { ...user };
        delete result['token'];
        delete result['resources'];
        return result;
    },
    target: setUserInfo,
});

sample({
    clock: setUser,
    filter({ resources }) {
        return (
            resources != null &&
            !Array.isArray(resources) &&
            typeof resources === 'object'
        );
    },
    target: setUserResources,
}); */
