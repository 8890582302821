import React from 'react';
import { Typography, Box } from '@mui/material';
var Divider = function (_a) {
    var options = _a.options;
    var label = options.label;
    if (label && isNaN(parseInt(label.split('.').reverse()[0]))) {
        return (React.createElement(Box, { sx: {
                my: 0,
                mx: .5,
                position: 'relative',
                flex: '1 1 100%',
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 'calc(50% + 5px)',
                    left: '0',
                    borderTop: function (theme) { return "1px solid ".concat(theme.palette.divider); },
                    width: '100%',
                },
            } },
            React.createElement(Box, { sx: {
                    m: '15px 0 5px 0',
                    height: 20,
                    display: 'flex',
                } },
                React.createElement(Box, { sx: {
                        display: 'flex',
                        position: 'absolute',
                        width: 1,
                        justifyContent: 'center',
                    } },
                    React.createElement(Typography, { component: 'div', variant: "body2", noWrap: true, sx: {
                            backgroundColor: '#eaeaea',
                            py: 0,
                            px: .55,
                        } }, label)))));
    }
    return (React.createElement("div", { style: { flex: '1 1 100%' } }));
};
export default Divider;
