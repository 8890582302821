var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// import {store} from '../store';
import objectPath from 'object-path';
var store = {};
var operations = {
    $current: function () {
        var date = new Date();
        return date.toISOString().split('T')[0];
    },
    $user: function (context, path) {
        if (!path) {
            return context['user']['GUID'];
        }
        return context['user'][path];
    },
    $inn: function (context) {
        return context['organization']['Inn'];
    },
    $organization: function (context, path) {
        return objectPath.get(context, "organization.".concat(path)) || '';
    },
    empty: function () { return ''; },
};
var getOperation = function (operation) { return operations[operation] || operations['empty']; };
export var convertingValue = function (value) {
    var _a, _b;
    var user = store.user, organization = store.organization;
    var context = {
        user: (_a = user === null || user === void 0 ? void 0 : user.toJSON()) !== null && _a !== void 0 ? _a : {},
        organization: __assign(__assign({}, (_b = organization === null || organization === void 0 ? void 0 : organization.toJSON()) !== null && _b !== void 0 ? _b : {}), { Address: organization.Address }),
    };
    var type = String(value).split('.');
    var operation = getOperation(type[0]);
    return operation(context, type[1]);
};
export var isEmptyValue = function (value) { return value === null || value === undefined || value === ''; };
