var _a;
import objectPath from 'object-path';
var UserCulture = /** @class */ (function () {
    function UserCulture() {
        var _this = this;
        this._translations = {};
        this._subscribers = [];
        this.set = function (lang) { return _this._userCulture = lang; };
        this.setTranslations = function (value) {
            if (value) {
                _this._translations = value;
                _this._runSubscribers();
            }
        };
        this.subscribe = function (subscriber) { return _this._subscribers.push(subscriber); };
        this.unsubscribe = function (name) { return _this._subscribers = _this._subscribers.filter(function (subscriber) { return subscriber.name !== name; }); };
        this._runSubscribers = function () {
            _this._subscribers.forEach(function (_a) {
                var action = _a.action;
                return action();
            });
        };
        this.getCurrentCulture = function () {
            var culture = _this._userCulture;
            if (culture) {
                return culture;
            }
            throw new Error('culture is undefined');
        };
        this.localize = function (data) {
            if (!!data.ru && _this._isCulture('ru')) {
                return data.ru;
            }
            if (!!data.en && _this._isCulture('en')) {
                return data.en;
            }
            if (!!data.de && _this._isCulture('de')) {
                return data.de;
            }
            return data.en;
        };
        this.translate = function (key) {
            if (_this._translations) {
                if (key) {
                    return _this._translations[key];
                }
                throw new Error('invalid resource key');
            }
            throw new Error('translations must be a set');
        };
        this._isCulture = function (culture) {
            if (_this._userCulture) {
                return _this._userCulture.toLowerCase() === culture;
            }
            throw new Error('culture must be a set');
        };
    }
    return UserCulture;
}());
export var localize = (_a = new UserCulture(), _a.localize), set = _a.set, getCurrentCulture = _a.getCurrentCulture, translate = _a.translate, setTranslations = _a.setTranslations, subscribe = _a.subscribe, unsubscribe = _a.unsubscribe;
export var getTranslate = function (path, type) {
    if (path === void 0) { path = 'undef'; }
    if (type === void 0) { type = 'interface'; }
    path = path.replace(/\[\d+]/g, '');
    return objectPath.get(translate(type), path) || '';
};
