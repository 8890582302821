var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createEffect, attach, } from 'effector';
import { $token } from '../authenticate';
export var fetchGraphQLFx = createEffect('fetch');
export var fetchFx = attach({
    effect: fetchGraphQLFx,
    source: $token,
    mapParams: function (query, token) {
        // const token = tokenStore.get('token');
        return __assign(__assign({}, query), { token: token });
    },
    name: '@fetch',
});
