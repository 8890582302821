var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Drawer, Grid, Stack, Icon, IconButton, 
// Link as MuiLink,
Toolbar, } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import {Link} from 'react-router-dom';
import useMenu from './use-app-menu';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        drawer: (_a = {},
            _a[theme.breakpoints.up('sm')] = {
                width: function (_a) {
                    var width = _a.width;
                    return width;
                },
                flexShrink: 0,
                // backgroundColor: theme.palette.primary.main,
            },
            _a),
        paper: {
            backgroundColor: theme.accents.hulk.main,
            height: '100%',
        },
        drawerPaper: {
            width: function (_a) {
                var width = _a.width;
                return width;
            },
            height: '100%',
            // backgroundColor: theme.palette.primary.main,
            backgroundColor: theme.accents.hulk.main,
        },
        drawerPaperMobile: (_b = {
                height: '100%',
                // backgroundColor: theme.palette.primary.main,
                backgroundColor: theme.accents.hulk.main
            },
            _b[theme.breakpoints.only('xs')] = {
                width: '100%',
            },
            _b[theme.breakpoints.only('sm')] = {
                width: function (_a) {
                    var width = _a.width;
                    return width;
                },
            },
            _b),
        drawerContainer: __assign({ overflow: 'hidden', 
            // display: 'flex',
            // flexWrap: 'wrap',
            // flexDirection: 'column',
            // justifyContent: 'space-between',
            height: '100%' }, theme.getScrollbarStyles(0.5)),
        drawerHeader: {
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #404854',
            minHeight: 49,
            // backgroundColor: theme.palette.primary.main,
        },
        toolbar: theme.mixins.toolbar,
        logo: {
            padding: 0,
            paddingLeft: 10,
            // display: 'flex',
            // alignItems: 'center',
            textDecoration: 'none',
            color: theme.palette.primary.contrastText,
            // width: 'max-content',
            // maxWidth: 'max-content',
        },
        logoTitle: {
            // paddingRight: theme.typography.pxToRem(20),
            fontSize: 13,
            fontStyle: 'normal',
            fontFamily: '"Russo One"',
            textTransform: 'uppercase',
            fontWeight: 400,
            lineHeight: 1.448,
        },
        closeButton: {
            color: theme.palette.primary.contrastText,
            marginRight: 10,
        },
    });
});
var Section = function (_a) {
    var children = _a.children;
    var _b = useMenu(), width = _b.width, open = _b.open, toggle = _b.toggle, isMobile = _b.isMobile;
    var classes = useStyles({ width: width });
    return (React.createElement(Drawer
    // sx={{ display: { xs: 'none', sm: 'block' } }}
    , { 
        // sx={{ display: { xs: 'none', sm: 'block' } }}
        className: classes.drawer, variant: isMobile ? 'temporary' : 'persistent', anchor: 'left', open: open, transitionDuration: { enter: isMobile ? 300 : 550, exit: isMobile ? 200 : 300 }, ModalProps: {
            keepMounted: true,
        }, 
        /*PaperProps={{
            elevation: 2,
        }}*/
        classes: {
            paper: isMobile ? classes.drawerPaperMobile : classes.drawerPaper,
        } },
        React.createElement(Toolbar, { variant: "dense", className: classes.drawerHeader, disableGutters: true },
            React.createElement(Grid, { className: classes.logo, container: true, item: true, alignItems: 'center' },
                React.createElement(Stack, { direction: 'row', spacing: 1, 
                    // item
                    sx: { height: 1 }, 
                    // alignContent={'center'}
                    alignItems: 'center', className: classes.logoTitle },
                    React.createElement("img", { src: '/images/logo.png', width: "32", height: "32", alt: "Logotype" }),
                    React.createElement("div", null, "\u0430\u043B\u044C\u0442\u0430-\u0441\u043E\u0444\u0442"))),
            React.createElement(Grid, { item: true },
                React.createElement(IconButton, { className: classes.closeButton, onClick: function () { return toggle(false); }, size: 'small' },
                    React.createElement(Icon, null, "close")))),
        React.createElement("div", { className: classes.drawerContainer }, children)));
};
export default Section;
