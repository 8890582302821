var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { MenuItem, TextField, } from '@mui/material';
import { ErrorAdornment } from '@alta-m1/form';
import { useDefaultPrinter, usePrintersLabels } from '@alta-m1/models/hooks';
var PrintersInput = function (props) {
    var values = usePrintersLabels();
    var defaultPrinter = useDefaultPrinter();
    var 
    // value,
    _a = props.input, onBlur = _a.onBlur, onFocus = _a.onFocus, inputValue = _a.value, inputs = __rest(_a, ["onBlur", "onFocus", "value"]), active = props.meta.active, 
    // errors,
    _b = props.options, label = _b.label, readonly = _b.readonly, disabled = _b.disabled, labelProps = props.labelProps, 
    // required,
    loading = props.loading, error = props.error;
    // console.log(this.props)
    React.useEffect(function () {
        if (!inputValue && (defaultPrinter === null || defaultPrinter === void 0 ? void 0 : defaultPrinter.id)) {
            inputs.onChange(defaultPrinter === null || defaultPrinter === void 0 ? void 0 : defaultPrinter.id);
        }
    }, [inputValue]);
    var value = inputValue || (defaultPrinter === null || defaultPrinter === void 0 ? void 0 : defaultPrinter.id) || '';
    return (React.createElement(TextField, __assign({}, inputs, { value: value, label: label, select: true, fullWidth: true, autoComplete: "off", InputLabelProps: labelProps, InputProps: {
            onFocus: onFocus,
            onBlur: onBlur,
            readOnly: !!readonly,
            endAdornment: React.createElement(ErrorAdornment, { message: error, open: active }),
        }, error: !!error && !disabled, disabled: disabled || loading, SelectProps: {
            MenuProps: {
                BackdropProps: {
                    sx: {
                        backgroundColor: 'transparent',
                    },
                },
            },
            sx: {
                '& .MuiSelect-icon': {
                    position: 'relative',
                },
            },
        } }), values.map(function (_a) {
        var label = _a.label, value = _a.value;
        return (React.createElement(MenuItem, { key: value, value: value, dense: true }, label || value));
    })));
};
export default PrintersInput;
