var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { FormControl, FormGroup, FormHelperText, Grid, InputLabel, Switch, Typography, } from '@mui/material';
var SwitchInput = function (props) {
    var 
    // value,
    _a = props.input, name = _a.name, checked = _a.checked, onChange = _a.onChange, _b = _a.type, type = _b === void 0 ? 'checkbox' : _b, inputs = __rest(_a, ["name", "checked", "onChange", "type"]), _c = props.meta, validError = _c.error, submitError = _c.submitError, dirtySinceLastSubmit = _c.dirtySinceLastSubmit, 
    // errors,
    _d = props.options, _e = _d.label, label = _e === void 0 ? {} : _e, 
    // readonly,
    disabled = _d.disabled, required = _d.required, labelProps = props.labelProps, 
    // required,
    loading = props.loading;
    // console.log(this.props)
    var error = validError || (!dirtySinceLastSubmit && submitError);
    return (React.createElement(FormControl, { component: 'div', required: required, error: Boolean(error), disabled: disabled || loading, fullWidth: true, sx: {
            '& > label': {
                marginLeft: '-14px',
                zIndex: 'auto',
            },
        } },
        React.createElement(InputLabel, __assign({ htmlFor: name, shrink: true }, labelProps), label.value),
        React.createElement(FormGroup
        /*onClick={(event) => {
            // event.preventDefault();
            event.stopPropagation();
            // event.preventDefault();
        }}*/
        , { 
            /*onClick={(event) => {
                // event.preventDefault();
                event.stopPropagation();
                // event.preventDefault();
            }}*/
            id: name, sx: { mt: 1 } },
            React.createElement(Typography
            /*onClick={(event) => {
                // event.preventDefault();
                event.stopPropagation();
                // event.preventDefault();
            }}*/
            , { 
                /*onClick={(event) => {
                    // event.preventDefault();
                    event.stopPropagation();
                    // event.preventDefault();
                }}*/
                component: "div", sx: { height: '32px' }, noWrap: true, variant: 'body2' },
                React.createElement(Grid
                /*onClick={(event) => {
                    // event.preventDefault();
                    event.stopPropagation();
                    event.preventDefault();
                }}*/
                , { 
                    /*onClick={(event) => {
                        // event.preventDefault();
                        event.stopPropagation();
                        event.preventDefault();
                    }}*/
                    component: "label", container: true, alignItems: "center", spacing: 1 },
                    React.createElement(Grid, { item: true }, label.off || 'Off'),
                    React.createElement(Grid, { item: true },
                        React.createElement(Switch, __assign({ checked: checked, 
                            // value={name}
                            type: type, onChange: onChange }, inputs, { color: 'primary' }))),
                    React.createElement(Grid, { item: true }, label.on || 'On')))),
        React.createElement(FormHelperText, null, error)));
};
export default SwitchInput;
