import TextInput from './elements/text';
import TextAreaInput from './elements/textarea';
import NumberInput from './elements/number';
import IntegerInput from './elements/integer';
import HiddenInput from './elements/hidden';
import DateTimeInput from './elements/datetime';
import DateInput from './elements/date';
import SelectInput from './elements/select';
import CheckboxInput from './elements/checkbox';
import SwitchInput from './elements/switch';
import PasswordInput from './elements/password';
import AlertInput from './elements/alert';
// eslint-disable-next-line
export var elements = {
    // table: require('./table').default,
    text: TextInput,
    textarea: TextAreaInput,
    number: NumberInput,
    integer: IntegerInput,
    hidden: HiddenInput,
    datetime: DateTimeInput,
    date: DateInput,
    select: SelectInput,
    checkbox: CheckboxInput,
    switch: SwitchInput,
    // object: ObjectInput,
    password: PasswordInput,
    alert: AlertInput,
};
export default Object.assign(Object.create(null), elements);
