var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { Chip, Icon, Stack, Typography, Badge, IconButton } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '../components';
import { makeProperties } from '../utils';
import Definition from './definition';
import { TypedDefinition } from './index';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
var ObjectDefinition = React.memo(function (_a) {
    var _b, _c, _d, _e;
    var data = _a.data, onChange = _a.onChange, props = __rest(_a, ["data", "onChange"]);
    var _f = __read(React.useState(true), 2), expanded = _f[0], setExpanded = _f[1];
    var _g = __read(React.useState({
        name: (_b = props.name) !== null && _b !== void 0 ? _b : '',
        title: (_c = data.title) !== null && _c !== void 0 ? _c : '',
        description: (_d = data.description) !== null && _d !== void 0 ? _d : '',
        type: (_e = data.type) !== null && _e !== void 0 ? _e : 'object',
    }), 2), state = _g[0], setState = _g[1];
    var mounted = React.useRef(false);
    var handleChangeState = React.useCallback(function (newState) {
        setState(newState);
    }, []);
    var _h = __read(React.useState(function () { return new Map(data.type === 'object' ? makeProperties(data.properties) : []); }), 2), properties = _h[0], setProperties = _h[1];
    var _j = __read(React.useState((Array.isArray(data.required) ? data.required : []).filter(function (name) { return properties.has(name); })), 2), requiredProps = _j[0], setRequiredProps = _j[1];
    var handleDeleteRequiredProps = React.useCallback(function (name) { return function () {
        setRequiredProps(function (required) { return required.filter(function (r) { return r !== name; }); });
    }; }, []);
    var handleChangeExpanded = React.useCallback(function (_, isExpanded) {
        setExpanded(isExpanded);
    }, []);
    var handleChangeRequiredProps = React.useCallback(function (name) {
        // const index = requiredProps.indexOf(name);
        // const newRequiredProps = index === -1 ? [...requiredProps, name] : requiredProps.filter((prop) => prop !== name);
        setRequiredProps(function (requiredProps) { return requiredProps.includes(name) ? requiredProps.filter(function (prop) { return prop !== name; }) : __spreadArray(__spreadArray([], __read(requiredProps), false), [name], false); });
    }, []);
    var handleChangePropName = React.useCallback(function (name, newName) {
        if (properties.has(newName)) {
            return;
        }
        var required = requiredProps.includes(name);
        var entries = __spreadArray([], __read(properties.entries()), false);
        if (required) {
            setRequiredProps(function (prev) { return prev.map(function (n) { return n === name ? newName : n; }); });
        }
        setProperties(function () { return new Map(entries.map(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            return key === name ? [newName, __assign(__assign({}, value), { $id: "#/properties/".concat(newName) })] : [key, value];
        })); });
    }, [properties, requiredProps]);
    /*const handleChangeNull = React.useCallback((name) => {
        const prop = properties.get(name);
        if (prop) {
            const newProp = {
                ...prop,
                type: Array.isArray(prop.type) ? prop.type.filter((type) => type !== 'null')[0] : [prop.type ?? 'string' as any, 'null'],
            };
            properties.set(name, newProp);
            setProperties(() => new Map(properties));
        }
    }, [properties]);*/
    var handleChangeProps = React.useCallback(function (name) { return function (prop) {
        properties.set(name, prop);
        setProperties(function () { return new Map(properties); });
    }; }, [properties]);
    var handleDeleteProps = React.useCallback(function (name) {
        if (name) {
            handleDeleteRequiredProps(name)();
            properties.delete(name);
            setProperties(function () { return new Map(properties); });
        }
    }, [properties, handleDeleteRequiredProps]);
    var handleChangeIndex = React.useCallback(function (index, operation) {
        if (index === 0 && operation === 'dec')
            return;
        if (index === properties.size - 1 && operation === 'inc')
            return;
        var shift = operation === 'inc' ? 1 : -1;
        var entries = __spreadArray([], __read(properties.entries()), false);
        entries.splice(index + shift, 0, entries.splice(index, 1)[0]);
        setProperties(function () { return new Map(entries); });
    }, [properties]);
    var handleAddProps = React.useCallback(function () {
        var name = Date.now() + '';
        properties.set(name, { type: 'string' });
        setProperties(function () { return new Map(properties); });
    }, [properties]);
    React.useEffect(function () {
        mounted.current = true;
        return function () {
            mounted.current = false;
        };
    }, []);
    var newData = React.useMemo(function () { return (__assign(__assign({}, (props.isRoot ? omit(state, ['name']) : state)), { properties: __spreadArray([], __read(properties.entries()), false).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), key = _c[0], property = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[key] = property, _b)));
        }, {}), required: requiredProps })); }, [properties, props.isRoot, requiredProps, state]);
    var dirty = React.useMemo(function () { return !isEqual(data, newData); }, [data, newData]);
    React.useEffect(function () {
        if (mounted.current && onChange && dirty) {
            /*const data = {
                ...state,
                properties: [...properties.entries()].reduce((acc, [key, property]) => ({...acc, [key]: property}), {} as AnyObject<IDefinitionProps['data']>),
                required: requiredProps,
            };*/
            onChange(newData);
        }
    }, [dirty, newData, onChange]);
    return (React.createElement(Accordion, { expanded: expanded, onChange: handleChangeExpanded },
        React.createElement(AccordionSummary, null,
            React.createElement(Definition, __assign({}, props, { type: 'object', data: state, onChange: handleChangeState, onChangeName: handleChangePropName }))),
        React.createElement(AccordionDetails, null,
            React.createElement(Stack, { spacing: 1.5, sx: { width: 1 } },
                React.createElement(Stack, { sx: { width: 1 } },
                    React.createElement("div", null,
                        React.createElement(Badge, { badgeContent: requiredProps.length, color: 'success' },
                            React.createElement(Typography, { variant: 'button', sx: { pr: 1 } }, "Required properties"))),
                    React.createElement(Stack, { direction: 'row', sx: { width: 1, minHeight: 24 }, spacing: 1 }, requiredProps.length > 0 ? requiredProps.map(function (name) { return (React.createElement(Chip, { key: name, label: name, size: 'small', deleteIcon: React.createElement(Icon, null, "close"), onDelete: handleDeleteRequiredProps(name) })); }) : React.createElement(Typography, { variant: 'caption', sx: { color: 'grey.600' } }, "No required properties"))),
                React.createElement(Stack, { sx: { width: 1 } },
                    React.createElement(Stack, { direction: 'row', spacing: 1.5, alignItems: 'flex-end' },
                        React.createElement(Badge, { badgeContent: properties.size, color: 'success' },
                            React.createElement(Typography, { variant: 'button', sx: { pr: 1 } }, "Properties")),
                        React.createElement(IconButton, { size: 'small', onClick: handleAddProps },
                            React.createElement(Icon, { fontSize: 'inherit' }, "add_circle"))),
                    React.createElement(Stack, { spacing: 1 }, __spreadArray([], __read(properties.entries()), false).map(function (_a, index) {
                        var _b = __read(_a, 2), name = _b[0], data = _b[1];
                        return (React.createElement(TypedDefinition, { key: index, data: data, name: name, required: requiredProps.includes(name), onChangeRequired: handleChangeRequiredProps, onChange: handleChangeProps(name), isChild: true, onDelete: handleDeleteProps, onChangeIndex: handleChangeIndex, index: index, isLast: index === properties.size - 1, onChangeName: handleChangePropName }));
                    })))))));
});
export default ObjectDefinition;
