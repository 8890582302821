var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { combine, createStore, createEvent, createEffect, } from 'effector';
// export const $gridColumns = createStore(4);
// export const $gridRows = createStore(5);
export var $gridSize = createStore([4, 5], { name: '@cup/size' });
export var $gridCount = $gridSize.map(function (_a) {
    var _b = __read(_a, 2), rows = _b[0], columns = _b[1];
    return rows * columns;
});
var $grid = $gridSize.map(function (_a) {
    var _b = __read(_a, 2), cols = _b[0], rows = _b[1];
    var array = [];
    for (var i = 0; i < rows; i += 1) {
        array[i] = [];
        for (var j = 0; j < cols; j += 1) {
            array[i][j] = i * cols + j + 1;
        }
    }
    return array.reverse();
});
export var updateCountersFx = createEffect('@cup/counters/update-fx');
export var updateCounters = createEvent('@cup/counters/update');
export var $counters = createStore([], { name: '@cup/counters' });
export var $printed = createStore(0, { name: '@cup/printed' });
export var updatePrinted = createEvent('@cup/printed/update');
export var printedInc = createEvent('@cup/printed-increment');
export var resetPrinted = createEvent('@cup/reset-printed');
export var $verified = createStore(0, { name: '@cup/verified' });
export var updateVerified = createEvent('@cup/verified/update');
export var verifiedInc = createEvent('@cup/verified-increment');
export var resetVerified = createEvent('@cup/reset-verified');
export var addCounter = createEvent('@cup/add-counter');
export var setCounter = createEvent('@cup/set-counter');
// export const setCounterPatched = createEvent<CupCounterPatchedArgs>('@cup/set-counter-patched');
export var clearCounters = createEvent('@cup/clear-counters');
export var setSize = createEvent('@cup/set-size');
export var $cup = combine({
    grid: $grid,
    gridSize: $gridSize.map(function (size) { return ({ columns: size[0], rows: size[1] }); }),
    printed: $printed,
    verified: $verified,
    counters: $counters,
});
