import React from 'react';
import { IconButton, Icon, InputAdornment } from '@mui/material';
var ReturnAdornment = function (_a) {
    var _b = _a.visible, visible = _b === void 0 ? false : _b, onClick = _a.onClick, _c = _a.position, position = _c === void 0 ? 'start' : _c;
    if (visible) {
        return (React.createElement(InputAdornment, { position: position },
            React.createElement(IconButton, { size: 'small', edge: position, onClick: function () { return onClick(); } },
                React.createElement(Icon, null, "assignment_return"))));
    }
    return null;
};
export default ReturnAdornment;
