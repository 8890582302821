var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import isDate from 'date-fns/isDate';
var second = 1000;
var minute = 60 * second;
var hour = 60 * minute;
var day = 24 * hour;
var timeframe = { second: second, minute: minute, hour: hour, day: day };
var breakpoints = {
    second: 60,
    minute: 60,
    hour: 24,
    day: 30,
};
var toDate = function (timeStampString) { return new Date(timeStampString); };
var getDiff = function (timestamp, now) { return toDate(now).valueOf() - toDate(timestamp).valueOf(); };
var isUnderTime = function (diff, timeframe, time) { return diff / timeframe < time; };
var diffOverTimeframe = function (diff, timeframe) { return Math.floor(diff / timeframe); };
var printResult = function (result, timeframeName) {
    return "".concat(result, " ").concat(timeframeName + (result > 1 ? 's' : ''));
};
var checkDate = function (diff, timeframeName, underTime, timeframe) {
    return isUnderTime(diff, timeframe[timeframeName], underTime) ?
        printResult(diffOverTimeframe(diff, timeframe[timeframeName]), timeframeName) :
        null;
};
var printFullDate = function (dateTime) {
    return "".concat(dateTime.getMonth() + 1, "/").concat(dateTime.getDate(), "/").concat(dateTime.getFullYear());
};
var lessThanAMinute = function (timeString) {
    return timeString.match(/seconds/) ? 'less than a minute' : timeString + ' ago';
};
var _checkNext = function (result, callback) {
    return (result ? lessThanAMinute(result) : callback());
};
var checkNext = function (_a, timeframe, timestamp, now) {
    var _b = __read(_a), tfName = _b[0], rest = _b.slice(1);
    return _checkNext(checkDate(getDiff(timestamp, now), tfName, breakpoints[tfName], timeframe), function () {
        return howLongAgo(rest, timeframe, timestamp, now);
    });
};
var howLongAgo = function (remainingTimeframe, timeframe, timestamp, now) {
    return !remainingTimeframe.length ?
        printFullDate(toDate(timestamp)) :
        checkNext(remainingTimeframe, timeframe, timestamp, now);
};
export var ago = function (timestamp, now) {
    if (now === void 0) { now = new Date().toString(); }
    return howLongAgo(Object.keys(timeframe), timeframe, timestamp, now);
};
export var getISODateString = function () { return new Date().toISOString().split('T')[0]; };
export var formatDate = function (date, locale) {
    return date && isDate(new Date(date)) ? new Intl.DateTimeFormat(locale !== null && locale !== void 0 ? locale : navigator.language, { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(date)) : null;
};
