var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Icon, IconButton, Box, Stack, FormLabel, FormHelperText, FormControl, } from '@mui/material';
import { b64EncodeUnicode, b64toBlob, downloadFile, getBase64, } from '@alta-m1/helpers';
var ImageInput = React.memo(function (props) {
    var 
    // value,
    _a = props.input, value = _a.value, onChange = _a.onChange, 
    // errors,
    _b = props.options, label = _b.label, readonly = _b.readonly, disabled = _b.disabled, labelProps = props.labelProps, 
    // required,
    // loading,
    error = props.error;
    var inputRef = React.useRef(null);
    var handleClick = function () {
        var _a;
        if (!(disabled || readonly)) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
        }
    };
    var handleClear = function (event) {
        event.preventDefault();
        event.stopPropagation();
        onChange('');
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };
    var handleChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var files, value_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    files = e.currentTarget.files;
                    if (!files) return [3 /*break*/, 2];
                    return [4 /*yield*/, getBase64(files[0])];
                case 1:
                    value_1 = _a.sent();
                    onChange(value_1);
                    _a.label = 2;
                case 2:
                    if (inputRef.current) {
                        inputRef.current.value = '';
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDownload = React.useCallback(function (event) {
        if (event && event.preventDefault) {
            event.preventDefault();
            event.stopPropagation();
        }
        var v;
        try {
            JSON.parse(value);
            v = b64EncodeUnicode(value);
        }
        catch (_a) {
            v = value;
        }
        // console.log(v);
        var blob = b64toBlob(v, 'image/png');
        downloadFile('label-picture', blob);
    }, [value]);
    return (React.createElement(FormControl, { component: 'fieldset', variant: 'standard', fullWidth: true },
        React.createElement(FormLabel, __assign({}, labelProps, { 
            // shrink
            sx: __assign({}, labelProps.sx) }), label),
        React.createElement(Box, { sx: {
                position: 'relative',
                minHeight: 150,
                maxHeight: 150,
                maxWidth: 150,
                minWidth: 150,
                width: 'fit-content',
                border: 1,
                borderStyle: 'dashed',
                borderColor: 'divider',
                backgroundColor: 'rgba(0, 0, 0, .045)',
                cursor: value ? 'default' : 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transition: 'border .2s linear',
                '&:hover': {
                    borderColor: 'primary.main',
                    '& #add-icon': {
                        color: 'primary.main',
                    },
                },
            }, onClick: handleClick },
            value ? (React.createElement(React.Fragment, null,
                React.createElement(Stack, { sx: {
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: 'rgba(255, 255, 255, .6)',
                        borderRadius: '0 0 0 16px',
                        zIndex: 1,
                    } },
                    React.createElement(IconButton
                    // disableRipple
                    , { 
                        // disableRipple
                        disableFocusRipple: true, disableTouchRipple: true, size: 'small', disabled: disabled || readonly, onClick: handleClear, color: 'hulk' },
                        React.createElement(Icon, { style: { fontSize: 'inherit' } }, "remove_circle_outline")),
                    React.createElement(IconButton
                    // disableRipple
                    , { 
                        // disableRipple
                        disableFocusRipple: true, disableTouchRipple: true, size: 'small', onClick: handleDownload, color: 'hulk' },
                        React.createElement(Icon, { style: { fontSize: 'inherit' } }, "download"))),
                React.createElement("img", { src: "data:image/png;base64,".concat(value), alt: 'Preview', style: {
                        display: 'block',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                    } }))) : (React.createElement(Box, { id: 'add-icon', sx: {
                    transition: 'color .2s linear',
                    color: 'divider',
                } },
                React.createElement(Icon, { style: { color: 'inherit', fontSize: 32 } }, "add_circle_outline"))),
            React.createElement("input", { ref: inputRef, type: 'file', accept: 'image/png', style: {
                    visibility: 'hidden',
                    opacity: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 0,
                }, onChange: handleChange, defaultValue: '' })),
        React.createElement(FormHelperText, { error: Boolean(error) }, error)));
});
export default ImageInput;
