var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { b64EncodeUnicode } from './string';
import objectPath from 'object-path';
export var fetchWithTimeout = function (url, options, timeout) {
    if (options === void 0) { options = {}; }
    if (timeout === void 0) { timeout = 7000; }
    return __awaiter(void 0, void 0, void 0, function () {
        var controller, id, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    controller = new AbortController();
                    id = setTimeout(function () { return controller.abort(); }, timeout);
                    return [4 /*yield*/, fetch(url, __assign(__assign({}, options), { signal: controller.signal }))];
                case 1:
                    response = _a.sent();
                    clearTimeout(id);
                    return [2 /*return*/, response];
            }
        });
    });
};
var normalizeValue = function (value) { return String(value).includes('%') ? String(value) : "%".concat(value, "%"); };
var getOperation = function (op, value) {
    if (op)
        return op;
    if (!Array.isArray(value) && typeof value === 'object') {
        var keys = Object.keys(value);
        if (keys.length === 1) {
            return keys[0];
        }
        if (keys.length > 1) {
            return '$and';
        }
        return '$or';
    }
    if (Array.isArray(value) && value.every(function (v) { return typeof v === 'number'; })) {
        return '$in';
    }
    return typeof value === 'number' ? '$eq' : '$iLike';
};
var getValue = function (value) {
    if (typeof value === 'string')
        return normalizeValue(value);
    if (Array.isArray(value)) {
        if (value.every(function (v) { return typeof v === 'number'; })) {
            return value;
        }
        var field = value[0];
        if (field && typeof field === 'object') {
            return value.reduce(function (acc, _a) {
                var _b;
                var op = _a.op, value = _a.value;
                return (__assign(__assign({}, acc), (_b = {}, _b[getOperation(op, value)] = getValue(value), _b)));
            }, {});
        }
        return {
            $any: value.map(function (v) { return normalizeValue(v); }),
        };
    }
    if (value && typeof value === 'object') {
        return Object.entries(value).reduce(function (acc, _a) {
            var _b;
            var _c = __read(_a, 2), op = _c[0], value = _c[1];
            return (__assign(__assign({}, acc), (_b = {}, _b[getOperation(op, value)] = getValue(value), _b)));
        }, {});
    }
    return value;
};
export var encodeFilters = function (params) {
    if (Array.isArray(params)) {
        var count = params.length;
        var newFilters = count > 1 ?
            {
                $and: params.map(function (_a) {
                    var _b, _c;
                    var field = _a.field, value = _a.value, op = _a.op;
                    var path = "".concat(field, ".").concat(getOperation(op, value));
                    var newValue = getValue(value);
                    var param = {};
                    objectPath.set(param, path, newValue);
                    // return param;
                    return _b = {},
                        _b[field] = (_c = {},
                            _c[getOperation(op, value)] = getValue(value),
                            _c),
                        _b;
                }),
            } : count === 1 ?
            params.reduce(function (acc, _a) {
                var _b, _c;
                var field = _a.field, value = _a.value, op = _a.op;
                // const path = `${field}.${getOperation(op, value)}`;
                // const newValue = getValue(value);
                // objectPath.set(acc, path, newValue);
                // return acc;
                return __assign(__assign({}, acc), (_b = {}, _b[field] = (_c = {}, _c[getOperation(op, value)] = getValue(value), _c), _b));
            }, {}) : null;
        return newFilters ? b64EncodeUnicode(JSON.stringify(newFilters)) : null;
    }
    return null;
};
