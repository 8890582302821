var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { TextField } from '@mui/material';
import { PatternFormat } from 'react-number-format';
import { ErrorAdornment } from '@alta-m1/form';
var NumberFormatCustomBase = function (props) {
    var forwardedRef = props.forwardedRef, onChange = props.onChange, other = __rest(props, ["forwardedRef", "onChange"]);
    return (React.createElement(PatternFormat, __assign({}, other, { getInputRef: forwardedRef, onValueChange: function (values) {
            onChange("+7".concat(values.floatValue));
        }, format: "+7 (###) ###-##-##", allowEmptyFormatting: true, prefix: '+7', mask: "_", valueIsNumericString: true, displayType: "input" })));
};
var NumberFormatCustom = React.forwardRef(function (props, ref) { return React.createElement(NumberFormatCustomBase, __assign({}, props, { forwardedRef: ref })); });
var PhoneInput = function (props) {
    var _a;
    var 
    // value,
    _b = props.input, value = _b.value, inputs = __rest(_b, ["value"]), active = props.meta.active, 
    // errors,
    _c = props.options, label = _c.label, readonly = _c.readonly, disabled = _c.disabled, labelProps = props.labelProps, 
    // required,
    loading = props.loading, error = props.error;
    return (React.createElement(TextField, __assign({}, inputs, { value: (_a = value === null || value === void 0 ? void 0 : value.replace('+7', '')) !== null && _a !== void 0 ? _a : '', label: label, fullWidth: true, autoComplete: "off", type: "text", variant: 'standard', InputLabelProps: labelProps, InputProps: {
            readOnly: readonly,
            inputComponent: NumberFormatCustom,
            endAdornment: React.createElement(ErrorAdornment, { message: error, open: active }),
        }, error: !!error && !disabled, "aria-readonly": readonly || disabled || loading, disabled: disabled || loading })));
};
export default PhoneInput;
