import React from 'react';
import { AlertTitle, Alert, Grid } from '@mui/material';
var AlertInput = function (props) {
    var value = props.input.value, label = props.options.label;
    if (value && typeof value === 'string') {
        return (React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Alert, { severity: 'error' },
                React.createElement(AlertTitle, { sx: { fontSize: 'inherit' } }, label),
                value)));
    }
    return null;
};
export default AlertInput;
