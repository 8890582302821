import { registerInput } from '@alta-m1/form';
import FEACNInput from './feacn';
import PhoneInput from './phone';
import LegendInput from './legend';
import DictionaryInput from './dictionary';
import AutocompleteInput from './autocomplete';
import JSONInput from './json';
import UpDownInput from './up-down';
import Printers from './printers';
import FileInput from './file';
import ImageInput from './image';
export var registerInputs = function () {
    registerInput('feacn', FEACNInput);
    registerInput('phone', PhoneInput);
    registerInput('legend', LegendInput);
    registerInput('dictionary', DictionaryInput);
    registerInput('autocomplete', AutocompleteInput);
    registerInput('json', JSONInput);
    registerInput('up-down', UpDownInput);
    registerInput('printers', Printers);
    registerInput('file', FileInput);
    registerInput('image', ImageInput);
};
