import { createTheme as createMuiTheme, responsiveFontSizes, alpha as fade, } from '@mui/material/styles';
import { blue, grey } from '@mui/material/colors';
var getScrollbarStyles = function (theme) {
    if (theme === void 0) { theme = 'light'; }
    return function (i, invert) {
        if (i === void 0) { i = 1; }
        if (invert === void 0) { invert = false; }
        var type = invert ? (theme === 'light' ? 'dark' : 'light') : theme;
        return ({
            '&::-webkit-scrollbar': {
                width: "".concat(4 * i, "px"),
                height: "".concat(4 * i, "px"),
            },
            '&::-webkit-scrollbar:hover': {
                backgroundColor: type === 'dark' ? 'rgba(255, 255, 255, .2)' : 'rgba(0,0,0,.1)',
            },
            '&::-webkit-scrollbar-button': {
                height: '0',
                width: '0',
                display: 'none',
            },
            '&::-webkit-scrollbar-corner': {
                backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                // backgroundClip: 'padding-box',
                // border: '0 solid transparent',
                backgroundColor: type === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0, 0, 0, .2)',
                boxShadow: type === 'dark' ? 'inset 1px 1px 0 rgba(255,255,255,0.10), inset 0 -1px 0 rgba(255,255,255,0.07)' : 'inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07)',
                // minHeight: '28px',
                // minWidth: '28px',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: "".concat(type === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0, 0, 0, .2)', " transparent"),
            /*'&::-webkit-scrollbar-track': {
                border: '0 solid transparent',
                backgroundClip: 'padding-box',
                backgroundColor: '#eee',
            },*/
        });
    };
};
var setScrollbarStyles = function (theme) {
    var type = theme.palette.mode;
    return createMuiTheme(theme, {
        getScrollbarStyles: getScrollbarStyles(type),
    });
    /*theme.accents = {
        hulk: {
            description: '#969fa4',
            contrastText: '#ffffff',
            dark: '#19222b',
            main: '#272e3d',
            light: '#515864',
            border: '#404854',
        },
    };*/
};
var theme = createMuiTheme({
    props: {
        MuiTextField: {
            variant: 'standard',
        },
        MuiFormControl: {
            variant: 'standard',
        },
    },
    accents: {
        hulk: {
            description: '#969fa4',
            contrastText: '#ffffff',
            dark: '#19222b',
            main: '#272e3d',
            light: '#515864',
            border: '#404854',
        },
    },
    typography: {
        // fontFamily: '"IBM Plex Sans", sans-serif',
        // fontFamily: '"Segoe UI", sans-serif',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
    },
    palette: {
        text: {
            // @ts-ignore
            hint: 'rgba(0, 0, 0, 0.38)',
        },
        background: {
            default: '#fafafa',
        },
        primary: {
            main: '#337ab7',
        },
        secondary: {
            main: blue[400],
        },
        hulk: {
            dark: '#19222b',
            main: '#272e3d',
            light: '#515864',
            contrastText: '#ffffff',
        },
        default: {
            dark: '#424242',
            main: '#757575',
            light: '#a8a8a8',
            contrastText: '#ffffff',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    height: '100%',
                },
                body: {
                    color: '#29303b',
                    height: '100%',
                    margin: 0,
                    overflowX: 'hidden',
                    fontSize: '0.875rem',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    '&.Mui-disabled': {
                        color: 'inherit',
                    },
                },
                input: {
                    '&.Mui-disabled': {
                        WebkitTextFillColor: 'inherit',
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    '&.Mui-disabled': {
                        color: 'inherit',
                    },
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    // backdropFilter: 'blur(1.5px)',
                },
            },
        },
        // @ts-ignore
        MuiTreeItem: {
            styleOverrides: {
                root: {
                    '&$selected > $content $label': {
                        backgroundColor: fade('#337ab7', 0.3),
                    },
                    '&$selected > $content $label:hover, &$selected:focus > $content $label, &$selected > $content $label:focus, &$selected:focus > $content $label:focus': {
                        backgroundColor: fade('#337ab7', 0.32),
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    transition: 'box-shadow 0.15s ease-in-out',
                    '&:hover': {
                        boxShadow: "inset 0px -2px 0px ".concat(grey['700']),
                    },
                },
            },
        },
        MuiCollapse: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    zIndex: 'auto',
                },
                wrapper: {
                    position: 'relative',
                },
                wrapperInner: {
                    position: 'relative',
                },
                entered: {
                    position: 'relative',
                },
            },
        },
    },
});
theme.palette.primary = theme.palette.augmentColor({
    color: {
        main: '#337ab7',
    },
});
theme.palette.secondary = theme.palette.augmentColor({
    color: {
        main: blue[400],
    },
});
export var responsiveTheme = responsiveFontSizes(setScrollbarStyles(theme));
