import React from 'react';
import {
    createStore,
    createEvent,
    sample,
    combine
} from 'effector';
import {
    interval,
    condition,
} from 'patronum';
// import { debug } from 'patronum/debug';
import { chainRoute, redirect, RouteInstance } from 'atomic-router';
import {
    $appLoaded,
    $views
} from '@alta-m1/models';
// import { authenticate } from '@alta-m1/models/events';
import { commonRoutes } from '@alta-m1/router';
import View from './view';

// const { trigger } = authenticate;

export const chainedRoute = (name: PropertyKey, route: RouteInstance<any>) => {
    if (name === 'home') {
        return route;
    }
    const $name = createStore(name.toString());
    const beforeOpen = createEvent();
    const open = createEvent();
    const start = createEvent();
    const stop = createEvent();
    const { tick, isRunning } = interval({
        timeout: 500,
        start: start,
        stop: stop,
    });
    sample({
        clock: tick,
        target: beforeOpen,
    });
    sample({
        clock: open,
        filter: isRunning,
        target: stop,
    });
    condition({
        source: beforeOpen,
        if: $appLoaded,
        then: open,
        else: start,
    });

    const $view = combine($views, $name, (views, key) => views[key]);
    const onCancel = createEvent();
    const notFound = sample({
        clock: open,
        source: $view,
        filter: (view) => view == null,
    });
    const forbidden = sample({
        clock: open,
        source: $view,
        filter: (view) => view?.access === false,
    });
    const developing = sample({
        clock: open,
        source: $view,
        filter: (view) => view?.developing === true,
    });
    sample({
        clock: [notFound, developing, forbidden],
        target: onCancel,
    });
    // debug($name, $appLoaded, open);
    redirect({
        clock: notFound,
        route: commonRoutes.notFoundRoute,
        query: {},
        replace: true,
    });
    redirect({
        clock: forbidden,
        route: commonRoutes.forbiddenRoute,
        query: {},
        replace: true,
    });
    redirect({
        clock: developing,
        route: commonRoutes.developmentRoute,
        query: {},
        replace: true,
    });

    return chainRoute({
        route,
        // @ts-expect-error
        beforeOpen: beforeOpen,
        openOn: open,
        cancelOn: onCancel,
    });
};

export const makeRouteView = (name: PropertyKey, Component: React.ComponentType<Page.IFormProps | Page.IListProps | Page.IProps>, /* route: RouteInstance<any> */) => {
    if (name === 'home') {
        return (props: any) => React.createElement(Component, props);
    }
    return (props: any) => React.createElement(View, { name: name.toString(), component: Component, ...props });
};