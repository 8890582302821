var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createStore, createEvent, attach, combine } from 'effector';
import { fetchFx } from '../fetch';
import { $user } from '../user';
import { reshape } from 'patronum';
export var loadFx = attach({
    source: $user,
    effect: fetchFx,
    mapParams: function (_, _a) {
        var org = _a.org;
        var query = [
            'query($GUID: String) {',
            'query:OrgsList(GUID: $GUID) {',
            '...on OrgsResult {',
            'list {',
            'Name',
            'Inn',
            'Ogrn',
            'Kpp',
            'Postalcode',
            'Countrycode',
            'Countryname',
            'Region',
            'City',
            'Streethouse',
            'Phone',
            'Email',
            'Type',
            '}',
            '}',
            '...on Error {',
            'message',
            '}',
            '}',
            '}',
        ].join('\n');
        var variables = { GUID: org.guid };
        return { query: query, variables: variables };
    },
});
export var updateOrganization = createEvent('@@organization/update');
export var $store = createStore({}, { name: '@@organization' });
var organization = reshape({
    source: $store,
    shape: {
        Name: function (_a) {
            var Name = _a.Name;
            return Name !== null && Name !== void 0 ? Name : '';
        },
        Inn: function (_a) {
            var Inn = _a.Inn;
            return Inn !== null && Inn !== void 0 ? Inn : '';
        },
        Ogrn: function (_a) {
            var Ogrn = _a.Ogrn;
            return Ogrn !== null && Ogrn !== void 0 ? Ogrn : '';
        },
        Kpp: function (_a) {
            var Kpp = _a.Kpp;
            return Kpp !== null && Kpp !== void 0 ? Kpp : '';
        },
        Postalcode: function (_a) {
            var Postalcode = _a.Postalcode;
            return Postalcode !== null && Postalcode !== void 0 ? Postalcode : '';
        },
        Countrycode: function (_a) {
            var Countrycode = _a.Countrycode;
            return Countrycode !== null && Countrycode !== void 0 ? Countrycode : '';
        },
        Countryname: function (_a) {
            var Countryname = _a.Countryname;
            return Countryname !== null && Countryname !== void 0 ? Countryname : '';
        },
        Region: function (_a) {
            var Region = _a.Region;
            return Region !== null && Region !== void 0 ? Region : '';
        },
        City: function (_a) {
            var City = _a.City;
            return City !== null && City !== void 0 ? City : '';
        },
        Streethouse: function (_a) {
            var Streethouse = _a.Streethouse;
            return Streethouse !== null && Streethouse !== void 0 ? Streethouse : '';
        },
        Phone: function (_a) {
            var Phone = _a.Phone;
            return Phone !== null && Phone !== void 0 ? Phone : '';
        },
        Email: function (_a) {
            var Email = _a.Email;
            return Email !== null && Email !== void 0 ? Email : '';
        },
        Type: function (_a) {
            var Type = _a.Type;
            return Type !== null && Type !== void 0 ? Type : '';
        },
        Address: function (self) {
            return [self.Postalcode, self.Countrycode, self.Countryname, self.Region, self.City, self.Streethouse]
                .filter(function (value) { return !!value; })
                .join(', ');
        },
    },
});
export var $organization = combine(__assign(__assign({}, organization), { GUID: $user.map(function (_a) {
        var org = _a.org;
        return org.guid;
    }) }));
