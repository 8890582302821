var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
// import { TextValidator } from 'react-material-ui-form-validator'
var HiddenInput = function (_a) {
    var input = _a.input;
    return (React.createElement("div", null,
        React.createElement("input", __assign({}, input, { type: "hidden" }))));
};
export default HiddenInput;
