var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Typography, } from '@mui/material';
// import Label from './label';
var CheckboxInput = React.memo(function (props) {
    var _a;
    var 
    // value,
    _b = props.input, name = _b.name, checked = _b.checked, onChange = _b.onChange, _c = _b.type, type = _c === void 0 ? 'checkbox' : _c, inputs = __rest(_b, ["name", "checked", "onChange", "type"]), _d = props.meta, validError = _d.error, submitError = _d.submitError, dirtySinceLastSubmit = _d.dirtySinceLastSubmit, 
    // errors,
    _e = props.options, label = _e.label, 
    // readonly,
    disabled = _e.disabled, required = _e.required, options = _e.options, 
    // required,
    loading = props.loading;
    // console.log(this.props)
    var error = validError || (!dirtySinceLastSubmit && submitError);
    return (React.createElement(FormControl, { component: 'div', required: required, error: !!error && !disabled, disabled: disabled || loading, fullWidth: true, style: {
            justifyContent: 'center',
            height: '100%',
        } },
        React.createElement(FormGroup, { id: name },
            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, __assign({ checked: checked, 
                    // @ts-ignore
                    type: type, onChange: onChange }, inputs, { color: 'primary', size: (_a = options === null || options === void 0 ? void 0 : options.size) !== null && _a !== void 0 ? _a : 'medium' })), label: React.createElement(Typography, { variant: 'body2', noWrap: true }, label), labelPlacement: 'end', style: { height: '32px' } })),
        React.createElement(FormHelperText, null, error)));
});
CheckboxInput.displayName = 'CheckboxInput';
export default CheckboxInput;
