var stringFormat = [
    'date-time',
    'date',
    'time',
    'email',
    'hostname',
    'ipv4',
    'ipv6',
    'uri',
    'regex',
];
export var parameters = {
    string: {
        default: { type: 'text' },
        minLength: { type: 'number' },
        maxLength: { type: 'number' },
        pattern: { type: 'text' },
        format: { type: 'select', values: stringFormat },
    },
    number: {
        default: { type: 'number' },
        minimum: { type: 'number' },
        maximum: { type: 'number' },
    },
    integer: {
        default: { type: 'number' },
        minimum: { type: 'number' },
        maximum: { type: 'number' },
    },
    array: {},
    boolean: {},
    object: {},
    // eslint-disable-next-line
    'null': {},
};
export var schemaTypes = [
    'string',
    'number',
    'array',
    'object',
    'boolean',
    'integer',
];
export var makeProperties = function (values) {
    if (values === void 0) { values = {}; }
    return Object.entries(values);
};
export var isEmptyValue = function (value) { return value === null || value === undefined || value === '' || value === 0; };
export var defaultSchemaKeys = ['$schema', 'title', 'description', 'properties', 'required', 'type', '$id'];
export var defaultDefinitionKeys = ['title', 'description', 'type', '$id'];
export var stopPropagation = function (event) {
    if (event && 'stopPropagation' in event) {
        event.stopPropagation();
    }
};
