var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createStore, createEffect, createEvent, combine, restore } from 'effector';
import { $currentTranslate } from '../i18n';
import { $viewsTitle } from '../views';
import objectPath from 'object-path';
export var loadFx = createEffect('menu_load');
export var updateMenuStore = createEvent('menu_update');
export var setActive = createEvent('active/set');
export var clearActive = createEvent('active/clear');
export var toggleActive = createEvent('active/toggle');
export var $menuStore = createStore([], { name: '@menu_items' });
export var $active = createStore(null, {
    name: 'active',
    updateFilter: function (update, current) {
        return update !== undefined || current !== undefined;
    },
});
var $error = restore(loadFx.failData, null);
export var $items = combine($menuStore, $currentTranslate, $viewsTitle, function (items, translate, titles) {
    return items.map(function (item) {
        var title = objectPath.get(translate, "menu.".concat(item.name, ".title"), item.name);
        var description = item.type === 'folder'
            ? item.items.map(function (sub) { return sub.view ? titles[sub.view] : sub.name; }).join(', ')
            : objectPath.get(translate, "menu.".concat(item.name, ".description"), item.name);
        return __assign(__assign({}, item), { title: title, description: description });
    });
});
export var $menu = combine({
    loading: loadFx.pending,
    error: $error,
    active: $active,
    items: $items,
});
