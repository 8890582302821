export var getBase64 = function (file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        // reader.readAsText(file);
        reader.onload = function () {
            resolve(reader.result.split(',')[1]);
        };
        reader.onerror = function (error) { return reject(error); };
        reader.readAsDataURL(file);
    });
};
export var b64toBlob = function (b64Data, contentType, sliceSize) {
    if (contentType === void 0) { contentType = ''; }
    if (sliceSize === void 0) { sliceSize = 512; }
    sliceSize = sliceSize || 512;
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
};
export var downloadFile = function (fileName, data) {
    var downloadLink = document.createElement('a');
    downloadLink.download = fileName;
    var url = URL.createObjectURL(data);
    downloadLink.href = url;
    downloadLink.click();
    URL.revokeObjectURL(url);
    downloadLink.remove();
};
