var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import ObjectDefinition from './object-definition';
import Definition from './definition';
import { defaultDefinitionKeys } from '../utils';
export { default as ObjectDefinition } from './object-definition';
export var TypedDefinition = React.memo(function (props) {
    var _a = __read(React.useState(function () { return Array.isArray(props.data.type) ? props.data.type.filter(function (type) { return type !== 'null'; })[0] : props.data.type; }), 2), type = _a[0], setType = _a[1];
    var mounted = React.useRef(false);
    var handleChangeType = React.useCallback(function (newType) {
        var newData = __assign(__assign({}, Object.fromEntries(Object.entries(props.data).filter(function (_a) {
            var _b = __read(_a, 1), key = _b[0];
            return defaultDefinitionKeys.includes(key);
        }))), { type: newType });
        if (props.onChange) {
            props.onChange(newData);
        }
        setType(newType);
    }, [props]);
    React.useEffect(function () {
        mounted.current = true;
        return function () {
            mounted.current = false;
        };
    }, []);
    switch (type) {
        case 'object':
            return (React.createElement("div", null,
                React.createElement(ObjectDefinition, __assign({}, props, { onChangeType: handleChangeType }))));
        default:
            return (React.createElement(Definition, __assign({}, props, { type: !type || type === 'null' ? 'string' : type, onChangeType: handleChangeType })));
    }
});
