var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        borderRadius: 0,
        color: function (_a) {
            var palette = _a.palette;
            return !palette || palette === 'default' ? theme.palette.getContrastText(theme.palette.grey[400]) : theme.palette[palette].contrastText;
        },
        backgroundColor: function (_a) {
            var palette = _a.palette;
            return !palette || palette === 'default' ? theme.palette.grey[400] : theme.palette[palette][theme.palette.mode];
        },
    },
    label: {
        textTransform: 'lowercase',
        paddingLeft: 4,
        paddingRight: 4,
    },
    deleteIcon: {
        color: 'inherit',
    },
}); });
var StyledChip = function (_a) {
    var _b = _a.palette, palette = _b === void 0 ? 'info' : _b, props = __rest(_a, ["palette"]);
    var classes = useStyles({ palette: palette });
    return React.createElement(Chip, __assign({ classes: {
            root: classes.root,
            label: classes.label,
            deleteIcon: classes.deleteIcon,
        } }, props));
};
export default StyledChip;
