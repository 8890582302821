var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
// import { observer } from 'mobx-react';
import { Box, Grid, 
// Link as MuiLink,
Typography } from '@mui/material';
// import { useStores } from 'src/hooks';
import { useI18n, useSession } from '@alta-m1/models/hooks';
import { StyledLink } from '../styled-mui-components';
import intervalToDuration from 'date-fns/intervalToDuration';
/*const useStyles = makeStyles((theme) => ({
    link: {
        color: ({active}: {active: boolean}) => active ? theme.palette.success.light : theme.palette.error.light,
        textDecorationColor: ({active}: {active: boolean}) => active ? theme.palette.success.light : theme.palette.error.light,
        fontWeight: 'bold',
        fontSize: 13,
        textDecorationStyle: 'dashed',
        transition: 'all .3s linear',
        '&:hover': {
            textDecorationStyle: 'solid',
        },
    },
}));*/
export var SessionTimer = function () {
    var session = useSession();
    var _a = __read(useI18n(), 1), getTranslate = _a[0];
    var duration = React.useMemo(function () { return intervalToDuration({
        start: new Date(session.now),
        end: new Date(session.end),
    }); }, [session.end, session.now]);
    if (session.active) {
        return (React.createElement(Typography, { style: { color: '#969fa4', fontSize: 10 } },
            getTranslate('labels.timeLeft') + ': ',
            session.left / 1000 - 10 < 60 ? '< minute' : String('0' + duration.hours).slice(-2) + 'h ' + String('0' + duration.minutes).slice(-2) + 'm'));
    }
    return null;
};
var SessionStatus = function () {
    var active = useSession().active;
    // const active = integration.session.active;
    var _a = __read(useI18n(), 1), getTranslate = _a[0];
    // const classes = useStyles({active: integration.session.active});
    return (React.createElement(Box, { component: Grid, 
        // @ts-ignore
        item: true, xs: 12, padding: 2 },
        React.createElement(Grid
        // component={Grid}
        , { 
            // component={Grid}
            container: true, 
            // textAlign={'center'}
            justifyContent: 'center', direction: 'column', alignItems: 'center' },
            React.createElement(Grid, { item: true },
                React.createElement(StyledLink
                // component={Link}
                , { 
                    // component={Link}
                    name: 'session', underline: 'always', className: 'link', sx: {
                        '&.link': {
                            color: active ? 'success.light' : 'error.light',
                            textDecorationColor: active ? 'success.light' : 'error.light',
                            fontWeight: 'bold',
                            fontSize: 13,
                            textDecorationStyle: 'dashed',
                            transition: 'all .3s linear',
                            '&:hover': {
                                textDecorationStyle: 'solid',
                            },
                        },
                    } }, active ? getTranslate('labels.session.active') : getTranslate('labels.session.inactive'))),
            React.createElement(Grid, { item: true },
                React.createElement(SessionTimer, null)))));
};
export default SessionStatus;
