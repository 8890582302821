var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useStoreMap, useStore } from 'effector-react';
import { $catalog, $groupIds, getBalanceByIdFx, getBalanceFx } from '.';
export var useGroupId = function (id) {
    var _a = useStoreMap({
        store: $groupIds,
        keys: [id],
        fn: function (state, _a) {
            var _b = __read(_a, 1), productGroupId = _b[0];
            return state.find(function (item) { return item.productGroupId === productGroupId; });
        },
    }), _balance = _a._balance, group = __rest(_a, ["_balance"]);
    var loading = useStore(getBalanceByIdFx.pending);
    var balance = _balance ? new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 2,
        currencyDisplay: 'symbol',
    }).format(_balance * 0.01) : 'No data';
    var getBalance = function () {
        getBalanceByIdFx(id);
    };
    return __assign(__assign({}, group), { balance: balance, loading: loading, getBalance: getBalance });
};
export var useCatalog = function () {
    var _a = useStoreMap({
        store: $catalog,
        keys: [],
        fn: function (state) {
            return __assign(__assign({}, state), { codesFourDigits: state.codes.map(function (code) { return code.slice(0, 4); }) });
        },
    }), groups = _a.groups, groupIds = _a.groupIds, catalog = __rest(_a, ["groups", "groupIds"]);
    var getGroupByCode = function (code) {
        return groups.find(function (group) {
            var _a;
            var codes = (_a = group.codes) !== null && _a !== void 0 ? _a : [];
            if (codes.length === 0) {
                return false;
            }
            return codes.some(function (c) { return code.startsWith(c); });
        });
    };
    var getCodesFourDigitsByCode = function (code) {
        var group = getGroupByCode(code);
        return (group === null || group === void 0 ? void 0 : group.codes) ? __spreadArray([], __read(new Set(group.codes.map(function (code) { return code.slice(0, 4); }))), false) : undefined;
    };
    var getGroupsByAlias = function (alias) {
        return __spreadArray([], __read(groups), false).filter(function (group) { return group.alias === alias; });
    };
    var getGroupByName = function (name) {
        return groupIds.find(function (group) { return group.name === name; });
    };
    var getGroupsCodesByAlias = function (alias) {
        var groups = getGroupsByAlias(alias);
        return groups.map(function (group) { var _a; return (_a = group.codes) !== null && _a !== void 0 ? _a : []; }).flat(1);
    };
    var getBalance = function () {
        getBalanceFx();
    };
    return __assign(__assign({}, catalog), { getGroupByCode: getGroupByCode, getCodesFourDigitsByCode: getCodesFourDigitsByCode, getGroupsByAlias: getGroupsByAlias, getGroupByName: getGroupByName, getGroupsCodesByAlias: getGroupsCodesByAlias, getBalance: getBalance });
};
