import React from 'react';
import { Outlet } from 'react-router';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import clsx from 'clsx';
import ErrorBoundary from '@alta-m1/components/error-boundary';
import { useAppMenu, SubMenu } from '@alta-m1/components/app-menu';
var MyBox = styled(Box, {
    shouldForwardProp: function (prop) { return prop !== 'sectionsWidth'; },
})(function (_a) {
    var _b;
    var theme = _a.theme, sectionsWidth = _a.sectionsWidth;
    return ({
        position: 'relative',
        flexGrow: 1,
        transition: 'margin 250ms ease-out',
        width: '100%',
        height: '100%',
        marginLeft: 0,
        '&.shift': (_b = {},
            _b[theme.breakpoints.up('md')] = {
                width: "calc(100% - ".concat(sectionsWidth, "px)"),
            },
            _b.transition = 'margin 350ms ease',
            _b.marginLeft = sectionsWidth,
            _b),
    });
});
var BodyLayout = function () {
    var menu = useAppMenu();
    // const granted = forms.map(({name}) => name);
    return (React.createElement(MyBox, { sectionsWidth: menu.width, className: clsx({
            shift: menu.shift,
        }), sx: {
            px: 2,
            py: 2,
        } },
        React.createElement(ErrorBoundary, null,
            React.createElement(DndProvider, { backend: HTML5Backend },
                React.createElement(SubMenu, null),
                React.createElement(Outlet, null)))));
};
export default BodyLayout;
