var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import useIsMounted from './use-is-mounted';
import { fetchFx } from '@alta-m1/models';
import { encodeFilters, isEmptyValue } from '@alta-m1/helpers';
import { useCustomization } from './index';
import unionBy from 'lodash/unionBy';
var ActionKind;
(function (ActionKind) {
    ActionKind[ActionKind["Loading"] = 0] = "Loading";
    ActionKind[ActionKind["StartLoading"] = 1] = "StartLoading";
    ActionKind[ActionKind["EndLoading"] = 2] = "EndLoading";
    ActionKind[ActionKind["Request"] = 3] = "Request";
    ActionKind[ActionKind["PageIncrement"] = 4] = "PageIncrement";
    ActionKind[ActionKind["Data"] = 5] = "Data";
    ActionKind[ActionKind["Clear"] = 6] = "Clear";
    ActionKind[ActionKind["Selected"] = 7] = "Selected";
    ActionKind[ActionKind["Filters"] = 8] = "Filters";
    ActionKind[ActionKind["Sort"] = 9] = "Sort";
    ActionKind[ActionKind["Params"] = 10] = "Params";
    ActionKind[ActionKind["Offset"] = 11] = "Offset";
    ActionKind[ActionKind["Error"] = 12] = "Error";
})(ActionKind || (ActionKind = {}));
var reducer = function (state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    switch (action.type) {
        case ActionKind.Loading:
            return __assign(__assign({}, state), { loading: (_a = action.payload.loading) !== null && _a !== void 0 ? _a : false });
        case ActionKind.StartLoading:
            return __assign(__assign({}, state), { load: false, loading: true, 
                // data: state.reset ? [] : state.data,
                // page: state.reset ? 1 : state.page,
                error: '' });
        case ActionKind.EndLoading:
            return __assign(__assign({}, state), { loading: false, load: false, reset: false });
        case ActionKind.Request:
            return __assign(__assign({}, state), { load: true, reset: (_b = action.payload.reset) !== null && _b !== void 0 ? _b : false, page: action.payload.reset ? 1 : state.page });
        case ActionKind.PageIncrement:
            return __assign(__assign(__assign({}, state), action.payload), { page: state.page + 1 });
        case ActionKind.Data:
            console.log('Array.isArray(action.payload.data)', Array.isArray(action.payload.data));
            console.log('state.reset || action.payload.reset', state.reset || action.payload.reset);
            return __assign(__assign({}, state), { data: Array.isArray(action.payload.data) ? (state.reset || action.payload.reset ? action.payload.data : __spreadArray([], __read(action.payload.data), false)) : state.data, 
                // data: Array.isArray(action.payload.data) ? (state.reset || action.payload.reset ? action.payload.data : [...state.data, ...action.payload.data]) : state.data,
                count: (_c = action.payload.count) !== null && _c !== void 0 ? _c : 0, page: (_d = action.payload.page) !== null && _d !== void 0 ? _d : 1, reset: false, load: false });
        case ActionKind.Clear:
            return __assign(__assign({}, state), { data: [], count: 0, page: 1, error: '' });
        case ActionKind.Selected:
            return __assign(__assign({}, state), { selected: (_e = action.payload.selected) !== null && _e !== void 0 ? _e : [] });
        case ActionKind.Filters:
            return __assign(__assign({}, state), { filters: action.payload.filters || null });
        case ActionKind.Sort:
            return __assign(__assign({}, state), { sort: (_f = action.payload.sort) !== null && _f !== void 0 ? _f : [] });
        case ActionKind.Params:
            return __assign(__assign({}, state), { params: (_g = action.payload.params) !== null && _g !== void 0 ? _g : [] });
        case ActionKind.Offset:
            return __assign(__assign({}, state), { offset: (_h = action.payload.offset) !== null && _h !== void 0 ? _h : 0 });
        case ActionKind.Error:
            return __assign(__assign({}, state), { error: (_j = action.payload.error) !== null && _j !== void 0 ? _j : 'Unknown error!', load: false });
        default:
            return state;
    }
};
var init = function (initialState) { return (__assign({}, initialState)); };
var initialState = {
    page: 1,
    filters: null,
    params: [],
    sort: [],
    offset: 0,
    count: 0,
    error: '',
    loading: false,
    reset: false,
    load: false,
};
var mapFiltersToParams = function (filters) { return Object.entries(filters).map(function (_a) {
    var _b = __read(_a, 2), field = _b[0], value = _b[1];
    return ({
        field: field,
        value: value,
    });
}).filter(function (_a) {
    var value = _a.value;
    return !isEmptyValue(value);
}); };
var useGridState = function (options) {
    var _a = options.requestOnMount, requestOnMount = _a === void 0 ? false : _a, query = options.query, _b = options.variables, variables = _b === void 0 ? {} : _b, _c = options.name, name = _c === void 0 ? '' : _c, _d = options.data, data = _d === void 0 ? [] : _d, _e = options.selected, selected = _e === void 0 ? [] : _e, postProcessing = options.postProcessing, _f = options.useFilters, useFilters = _f === void 0 ? false : _f, _g = options.useSettings, useSettings = _g === void 0 ? true : _g, _h = options.filters, propsFilters = _h === void 0 ? {} : _h;
    if (!name) {
        throw new Error('The required parameter "name" is not set');
    }
    var _j = __read(useCustomization({ name: name, use: useSettings }), 2), customValues = _j[0], customSet = _j[1];
    var sort = React.useMemo(function () { var _a, _b; return unionBy((_a = variables.sort) !== null && _a !== void 0 ? _a : [], (_b = customValues.sort) !== null && _b !== void 0 ? _b : [], 'attribute'); }, [customValues.sort, variables.sort]);
    var _k = __read(React.useReducer(reducer, __assign(__assign({}, initialState), { data: data, selected: selected, params: mapFiltersToParams(Object.assign({}, customValues.filters, propsFilters)), filters: encodeFilters(mapFiltersToParams(Object.assign({}, customValues.filters, propsFilters))), sort: sort, load: requestOnMount, loading: requestOnMount && !!query }), init), 2), state = _k[0], dispatch = _k[1];
    // const {query: client} = useApolloClient();
    var isMounted = useIsMounted();
    var firstFetch = React.useRef(true);
    var refetch = React.useCallback(function (reset) {
        if (reset === void 0) { reset = false; }
        dispatch({ type: ActionKind.Selected, payload: {} });
        dispatch({ type: ActionKind.Request, payload: { reset: reset } });
    }, []);
    var toggleLoading = React.useCallback(function (loading) {
        if (loading === void 0) { loading = false; }
        dispatch({ type: ActionKind.Loading, payload: { loading: loading } });
    }, []);
    var setStageLoading = React.useCallback(function (stage) {
        if (stage === void 0) { stage = false; }
        if (stage) {
            dispatch({ type: ActionKind.StartLoading });
        }
        else {
            dispatch({ type: ActionKind.EndLoading });
        }
    }, []);
    var onSelectedRow = React.useCallback(function (rows) {
        dispatch({ type: ActionKind.Selected, payload: { selected: rows } });
    }, []);
    var onScrollBottom = React.useCallback(function () {
        if (state.data.length < state.count) {
            if (isMounted()) {
                dispatch({ type: ActionKind.PageIncrement, payload: { load: true } });
            }
        }
    }, [isMounted, state.count, state.data.length]);
    var onFiltersChange = React.useCallback(function (newFilters) {
        var filters = __assign(__assign({}, newFilters), propsFilters);
        customSet('filters', filters);
        var params = mapFiltersToParams(filters);
        var encodedFilters = encodeFilters(params);
        dispatch({ type: ActionKind.Params, payload: { params: params } });
        dispatch({ type: ActionKind.Filters, payload: { filters: encodedFilters } });
        dispatch({ type: ActionKind.Request, payload: { reset: true } });
    }, [customSet, propsFilters]);
    var onSortChange = React.useCallback(function (newSort) {
        if (newSort === void 0) { newSort = []; }
        var sort = newSort === null || newSort === void 0 ? void 0 : newSort.filter(function (_a) {
            var sort = _a.sort;
            return sort !== 'NONE';
        });
        customSet('sort', sort);
        dispatch({ type: ActionKind.Sort, payload: { sort: sort } });
        dispatch({ type: ActionKind.Request, payload: { reset: true } });
    }, [customSet]);
    var onHiddenChange = React.useCallback(function (newHidden) {
        customSet('hidden', newHidden);
    }, [customSet]);
    var onOrderChange = React.useCallback(function (order) {
        customSet('order', order);
    }, [customSet]);
    var onWidthsChange = React.useCallback(function (widths) {
        customSet('widths', widths);
    }, [customSet]);
    var setError = React.useCallback(function (error) {
        if (error === void 0) { error = ''; }
        dispatch({ type: ActionKind.Error, payload: { error: error } });
    }, []);
    var setData = React.useCallback(function (payload) {
        console.log('SETDATA HOOKS PAYLOAD', payload);
        payload && dispatch({ type: ActionKind.Data, payload: payload });
    }, []);
    var clearData = React.useCallback(function () {
        dispatch({ type: ActionKind.Clear });
    }, []);
    var changeOffset = React.useCallback(function (offsetY) {
        dispatch({ type: ActionKind.Offset, payload: { offset: offsetY } });
    }, []);
    var handleQuery = React.useCallback(function (newVariables, clear) {
        if (newVariables === void 0) { newVariables = {}; }
        if (clear === void 0) { clear = false; }
        if (query) {
            if (isMounted()) {
                if (clear) {
                    dispatch({ type: ActionKind.Clear });
                }
                if (!firstFetch.current) {
                    dispatch({ type: ActionKind.StartLoading });
                }
            }
            var queries = Array.isArray(query) ? query : [query];
            var promises = queries.map(function (query) {
                var _a, _b;
                return fetchFx({
                    query: query,
                    variables: __assign(__assign(__assign({ page: state.page, filters: useFilters ? state.filters : undefined }, variables), newVariables), { sort: unionBy((_a = state.sort) !== null && _a !== void 0 ? _a : [], (_b = newVariables.sort) !== null && _b !== void 0 ? _b : [], 'attribute') }),
                })
                    .then(function (_a) {
                    var _b;
                    var query = _a.data.query;
                    var list = query.list, _c = query.pageInfo, pageInfo = _c === void 0 ? {} : _c, message = query.message;
                    if (message) {
                        return Promise.reject(message);
                    }
                    var _d = pageInfo.count, count = _d === void 0 ? list.length : _d, _e = pageInfo.page, page = _e === void 0 ? 1 : _e;
                    console.log('USEGRIDSTATE', list);
                    var data = (_b = postProcessing === null || postProcessing === void 0 ? void 0 : postProcessing(list)) !== null && _b !== void 0 ? _b : __spreadArray([], __read(list), false);
                    /*if (isMounted()) {
                        dispatch({
                            type: ActionKind.Data,
                            payload: {
                                data,
                                page,
                                count,
                            },
                        });
                    }*/
                    return Promise.resolve({
                        data: data,
                        page: page,
                        count: count,
                    });
                });
                /*.catch((error) => isMounted() && dispatch({
                        type: ActionKind.Error,
                        payload: {
                            error: error.message || String(error),
                        },
                    }));*/
            });
            Promise.allSettled(promises)
                .then(function (responses) {
                var _a;
                var error = (_a = responses.filter(function (response) { return !!response.reason; })[0]) === null || _a === void 0 ? void 0 : _a.reason;
                if (error) {
                    isMounted() && dispatch({
                        type: ActionKind.Error,
                        payload: {
                            error: error.message || String(error),
                        },
                    });
                }
                var payload = responses.reduce(function (acc, _a) {
                    var value = _a.value;
                    if (value) {
                        return {
                            data: acc.data.concat(value.data),
                            page: value.page,
                            count: acc.count + value.count,
                        };
                    }
                    return acc;
                }, { data: [], count: 0, page: 1 });
                console.log('PAYLOAD HOOK USEGRID', payload);
                console.log('STATE HOOK USEGRID', state);
                if (isMounted()) {
                    dispatch({
                        type: ActionKind.Data,
                        payload: payload,
                    });
                }
            })
                .finally(function () {
                if (isMounted()) {
                    dispatch({ type: ActionKind.EndLoading });
                }
            });
        }
    }, [query, isMounted, state.page, state.filters, state.sort, useFilters, variables, postProcessing]);
    React.useEffect(function () {
        if (requestOnMount) {
            if (isMounted() && firstFetch.current) {
                firstFetch.current = false;
                // dispatch({type: ActionKind.Request, payload: {}});
            }
        }
    }, [isMounted, requestOnMount]);
    React.useEffect(function () {
        if (isMounted() && query) {
            if (state.load) {
                handleQuery();
            }
        }
    }, [handleQuery, isMounted, query, state.load]);
    console.log('state hooks', state);
    var tableActions = React.useMemo(function () { return ({
        onSelectedRow: onSelectedRow,
        onScrollBottom: onScrollBottom,
        onFiltersChange: onFiltersChange,
        onSortChange: onSortChange,
        onHiddenChange: onHiddenChange,
        onOrderChange: onOrderChange,
        onWidthsChange: onWidthsChange,
    }); }, [onFiltersChange, onScrollBottom, onSelectedRow, onSortChange, onHiddenChange, onOrderChange, onWidthsChange]);
    var actions = React.useMemo(function () { return ({
        refetch: refetch,
        onSelectedRow: onSelectedRow,
        onScrollBottom: onScrollBottom,
        onFiltersChange: onFiltersChange,
        toggleLoading: toggleLoading,
        setStageLoading: setStageLoading,
        setError: setError,
        setData: setData,
        changeOffset: changeOffset,
        onSortChange: onSortChange,
        onHiddenChange: onHiddenChange,
        onOrderChange: onOrderChange,
        onWidthsChange: onWidthsChange,
        tableActions: tableActions,
        clearData: clearData,
    }); }, [changeOffset, clearData, onFiltersChange, onScrollBottom, onSelectedRow, onSortChange, onHiddenChange, onOrderChange, onWidthsChange, refetch, setData, setError, setStageLoading, tableActions, toggleLoading]);
    return [
        __assign(__assign({}, state), { settings: __assign(__assign({}, customValues), { sort: sort }) }),
        actions,
        handleQuery,
    ];
};
export default useGridState;
