import { useStore } from 'effector-react';
import { $token } from '.';
export var useAuthenticate = function () {
    var token = useStore($token);
    var authorized = !!token;
    return {
        token: token,
        authorized: authorized,
    };
};
