var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Link as AtomicLink } from 'atomic-router-react';
import { Link as MuiLink } from '@mui/material';
import { getRouteByName } from '@alta-m1/router';
var makeTo = function (name) {
    var route = getRouteByName(name);
    if (route) {
        if (Array.isArray(route)) {
            return route[0];
        }
        return route;
    }
    return '/' + name;
};
export var Link = function (_a) {
    var name = _a.name, _b = _a.underline, underline = _b === void 0 ? 'none' : _b, children = _a.children, props = __rest(_a, ["name", "underline", "children"]);
    return (React.createElement(MuiLink, __assign({ component: AtomicLink, to: makeTo(name), underline: underline }, props), children));
};
export default Link;
