var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/// <reference types="google.visualization" />
import { useEffect, useState, useRef } from 'react';
var useGoogleCharts = function (locale, packages) {
    var _a = __read(useState(null), 2), google = _a[0], setGoogle = _a[1];
    var _b = __read(useState(true), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(function () { return locale !== null && locale !== void 0 ? locale : navigator.language.split('-')[0]; }), 2), language = _c[0], setLanguage = _c[1];
    var mounted = useRef(false);
    useEffect(function () {
        mounted.current = true;
        return function () {
            var _a;
            (_a = document.getElementById('googleChartsScript')) === null || _a === void 0 ? void 0 : _a.remove();
            mounted.current = false;
        };
    }, []);
    useEffect(function () {
        var _a;
        if (mounted.current && language !== locale && locale) {
            setLanguage(locale);
            setLoading(true);
            setGoogle(null);
            (_a = document.getElementById('googleChartsScript')) === null || _a === void 0 ? void 0 : _a.remove();
        }
    }, [language, locale]);
    useEffect(function () {
        if (!google) {
            var head = document.head;
            var script = document.getElementById('googleChartsScript');
            if (!script) {
                script = document.createElement('script');
                script.src = 'https://www.gstatic.com/charts/loader.js';
                script.id = 'googleChartsScript';
                script.onload = function () {
                    if (window.google && window.google.charts) {
                        window.google.charts.load('current', {
                            packages: packages !== null && packages !== void 0 ? packages : ['corechart'],
                            language: language,
                        }).then();
                        window.google.charts.setOnLoadCallback(function () {
                            if (mounted.current) {
                                setGoogle(window.google);
                                setLoading(false);
                            }
                        });
                    }
                };
                head.appendChild(script);
            }
            else if (window.google && window.google.charts && window.google.visualization) {
                setGoogle(window.google);
            }
        }
    }, [google, language, packages]);
    return { google: google, loading: loading, setLanguage: setLanguage };
};
export default useGoogleCharts;
