
// const pages = {
//     ...cem,
// };

// export default pages;

import React from 'react';
import { createRoute } from 'atomic-router';
import { makeRoutes } from './routes';
import { route as loginRoute, route } from './login-page/index';
import  LoginPage from './login-page/index';
//была ленивая загрузка
// import IndexPage from './index-page';
import * as cem from './CEM';
import { useStore } from 'effector-react';

// const IndexPage = React.lazy(() => import('./index-page'));
import IndexPage from './index-page';

const pages = {
    // home: IndexPage,
    // login: LoginPage,
    ...cem,
};


export const routes = makeRoutes(pages, [
    {
        name: 'home',
        view: IndexPage,
        route: createRoute(),
        path: '/',
    },
    // {
    //     name: 'login',
    //     view: LoginPage,
    //     route: loginRoute,
    //     path: `/login`,
    // }
]);

export default pages;
