var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            error: new Error('Initialize error'),
            hasError: false,
        };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log({ error: error, errorInfo: errorInfo });
    };
    ErrorBoundary.prototype.render = function () {
        var _a = this.state, hasError = _a.hasError, error = _a.error;
        if (hasError) {
            return (React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 1, alignItems: 'center' },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: 'subtitle2' }, "There was an error in loading this page.")),
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { variant: 'outlined', size: 'small', color: 'secondary', onClick: function () { return window.location.reload(); } }, "Try again"))),
                React.createElement(Grid, { container: true, item: true, xs: 12 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: 'body2', color: 'error' }, error.message))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement("details", null,
                        React.createElement("summary", null, "Click for error details"),
                        error.stack))));
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
