import React from 'react';
// import { useStore } from 'effector-react';
import {
    createRoute,
    UnmappedRouteObject, RouteParams, RouteInstance
} from 'atomic-router';
import { createRouteView } from 'atomic-router-react';
import {
    chainedRoute,
    makeRouteView,
} from './methods';
// import ViewWrapper from './wrapper';

type Route<Params extends RouteParams = {}> = UnmappedRouteObject<Params> & {
    view: React.ComponentType;
    name: string;
};

export const mapedRoutes = {} as Record<PropertyKey, RouteInstance<any>>;

export const makeRoutes = (pages: Record<PropertyKey, React.ComponentType<any>>, includes?: Route[]): Route[] => {
    includes ??= [];
    return [...Object.entries(pages).map(([key, page]) => {
        const route = createRoute();
        const chainRoute = chainedRoute(key, route);
        mapedRoutes[key] = chainRoute;
        const view = makeRouteView(key, page);

        return {
            name: key.toString(),
            path: '/' + key.replace('home', ''),
            route: route,
            view: createRouteView({ route: chainRoute, view }),
        } as Route;
    }), ...includes];
};
