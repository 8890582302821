var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createStore, createEvent, createEffect, combine } from 'effector';
// import { pending } from 'patronum';
var sounds = {
    success: new Audio('/wav/success.wav'),
    error: new Audio('/wav/error.wav'),
};
var wsURL = import.meta.env.MODE === 'production' ? window.location.host : import.meta.env.VITE_API_TARGET;
export var socket;
export function cleanSocket() {
    socket.onopen = null;
    socket.onclose = null;
    socket.onerror = null;
    socket.onmessage = null;
    socket = undefined;
}
export var playSound = createEvent('@sound/play');
export var playSoundFx = createEffect('sound', {
    handler: function (type) {
        return __awaiter(this, void 0, void 0, function () {
            var sound;
            return __generator(this, function (_a) {
                sound = sounds[type];
                if (sound) {
                    return [2 /*return*/, sound.play()];
                }
                return [2 /*return*/, Promise.resolve()];
            });
        });
    },
});
export var open = createEvent('@socket/status-open');
export var closed = createEvent('@socket/status-closed');
export var error = createEvent('@socket/status-error');
export var $authorized = createStore(false, { name: '@socket/authorized' });
export var changeAuthorized = createEvent('@socket/change-authorized');
export var onAuthorized = createEvent('@socket/on-authorized');
export var $messages = createStore([], {
    name: '@socket/messages',
});
export var addMessageBase = createEvent('@socket/add-message');
export var addMessage = addMessageBase.prepend(function (message) {
    var timestamp = Date.now();
    return typeof message === 'string' ? { message: message, timestamp: timestamp } : __assign(__assign({}, message), { timestamp: timestamp });
});
export var addSuccessMessage = addMessage.prepend(function (message) { return ({
    type: 'success',
    message: message,
}); });
export var addErrorMessage = addMessage.prepend(function (message) { return ({
    type: 'error',
    message: message,
}); });
export var clearMessages = createEvent('@socket/clear-messages');
export var onMessage = createEvent('@socket/on-message');
export var onMessageResolve = createEvent('@socket/on-message-resolve');
export var onMessageReject = createEvent('@socket/on-message-reject');
export var send = createEvent('@socket/send');
export var sendFx = createEffect('@socket/sendFx');
export function disconnect() {
    socket === null || socket === void 0 ? void 0 : socket.close();
}
export function connect(params) {
    try {
        var search = new URLSearchParams(params).toString();
        var protocols = search ? [] : ['chat'];
        var url = new URL("wss://".concat(wsURL, "/ws/?").concat(search));
        socket = new WebSocket(url, protocols[0]);
        socket.onopen = function (event) { return open(event); };
        socket.onclose = function (event) { return closed(event); };
        socket.onerror = function (err) { return error(err); };
        socket.onmessage = function (_a) {
            var data = _a.data;
            var operation = JSON.parse(data);
            onMessage(operation);
        };
    }
    catch (e) {
        addErrorMessage(e.message);
    }
}
export var setPrinter = createEvent('@socket/set-printer');
export var print = createEvent('@socket/print');
export var printFx = createEffect('@socket/print-fx');
export var $printer = createStore(null, {
    name: '@socket/printer',
});
export var $status = createStore('closed', { name: '@socket/status' });
export var sendDataToSerializer = createEvent('@socket/send-serializer'); // событие для запроса данных для сериализатора у сервера
export var sendDataToSerializerFx = createEffect('@socket/send-serializer-fx'); // эффект для запроса данных для сериализатора у сервера
export var printSerializer = createEvent('@socket/print-serializer');
export var printSerializerFx = createEffect('@socket/print-serializer-fx');
export var $state = combine({
    messages: $messages,
    receiving: sendFx.pending,
    authorized: $authorized,
    printing: printFx.pending,
    printer: $printer,
    status: $status,
});
