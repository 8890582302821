/* eslint-disable effector/no-getState */
import React from 'react';
import { Backdrop, Box, Grid, Icon, IconButton, Paper, Typography } from '@mui/material';
import { SvgOrMaterialIcon } from '../styled-mui-components';
import { menu } from '@alta-m1/models/events';
import { useView, useMenuActive } from '@alta-m1/models/hooks';
import { useNavigate } from '@alta-m1/router';
import { useAppMenu } from '.';
var clearActive = menu.clearActive;
var SubMenuItem = function (_a) {
    var item = _a.item;
    var _b = useAppMenu(), toggle = _b.toggle, isMobile = _b.isMobile;
    var navigate = useNavigate();
    var view = useView(item.view);
    var _c = view.name, name = _c === void 0 ? '' : _c, _d = view.developing, developing = _d === void 0 ? true : _d, _e = view.access, access = _e === void 0 ? true : _e, _f = view.label, label = _f === void 0 ? '' : _f, _g = view.description, description = _g === void 0 ? '' : _g, _h = view.title, title = _h === void 0 ? item.name : _h;
    var disabled = developing || !access;
    var handleClick = function (event) {
        if (event && 'preventDefault' in event) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (!disabled && name) {
            navigate(name);
            clearActive();
            isMobile && toggle(false);
        }
    };
    return (React.createElement(Grid, { item: true, xs: 12, md: 6, lg: 4 },
        React.createElement(Paper, { square: true, elevation: 0, sx: {
                display: 'flex',
                padding: '0 10px',
                // marginBottom: 20,
                backgroundColor: 'transparent',
                minHeight: 60,
                maxHeight: 90,
                cursor: !disabled ? 'pointer' : undefined,
                ':hover > div': {
                    color: !disabled ? 'primary.light' : undefined,
                    // boxShadow: ({disabled}) => !disabled ? theme.shadows[1] : undefined,
                },
            }, onClick: handleClick },
            React.createElement(Grid, { container: true, sx: {
                    transition: 'all .1s linear',
                    // backgroundColor: theme.palette.background.paper,
                    height: '100%',
                    color: disabled ? '#969fa4' : 'primary.contrastText',
                    p: '5px',
                    position: 'relative',
                }, alignItems: 'flex-start', wrap: 'nowrap' },
                React.createElement(Box, { sx: {
                        minWidth: 35,
                        minHeight: 35,
                        maxWidth: 35,
                        maxHeight: 35,
                        borderRadius: '4px',
                        backgroundColor: 'hulk.light',
                        mr: 2,
                        color: 'hulk.contrastText',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // alignSelf: 'flex-start',
                    } },
                    React.createElement(SvgOrMaterialIcon, { icon: item.icon })),
                React.createElement(Grid, { item: true, container: true },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: 'body2', color: 'inherit' }, title)),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Typography, { variant: 'caption', sx: {
                                color: function (theme) { return theme.accents.hulk.description; },
                            } }, label || description || 'Description')))))));
};
var SubMenu = function () {
    var active = useMenuActive();
    var _a = useAppMenu(), width = _a.width, isMobile = _a.isMobile;
    if (active != null) {
        return (React.createElement(Backdrop, { open: active.items.length > 0, sx: {
                zIndex: function (theme) { return isMobile ? theme.zIndex.drawer + 1 : theme.zIndex.snackbar + 1; },
                // position: 'absolute',
                width: isMobile ? 1 : "calc(100% - ".concat(width, "px)"),
                height: 1,
                right: 0,
                ml: 'auto',
                p: '10px 0 5px',
                pt: 0,
                flexWrap: 'wrap',
            } },
            React.createElement(Paper, { square: true, sx: {
                    width: '100%',
                    mt: '48px',
                    height: 'calc(100% - 5px)',
                    p: '1.25rem',
                    overflow: 'hidden',
                    backgroundColor: 'hulk.dark',
                    color: 'hulk.contrastText',
                } },
                React.createElement(Grid, { container: true, justifyContent: 'space-between', alignItems: 'center' },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: 'h6' }, active.title)),
                    React.createElement(Grid, { item: true },
                        React.createElement(IconButton, { color: 'inherit', size: 'small', onClick: function () { return clearActive(); } },
                            React.createElement(Icon, null, "close")))),
                React.createElement(Box, { sx: {
                        width: 1,
                        height: '100%',
                        '&': function (theme) { return theme.getScrollbarStyles(1); },
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        p: '40px 10px 10px 10px',
                    } },
                    React.createElement(Grid, { container: true, rowSpacing: 2, wrap: 'wrap', alignItems: 'space-between' }, active.items.map(function (sub) { return (React.createElement(SubMenuItem, { key: sub.name, item: sub })); }))))));
    }
    return null;
};
export default SubMenu;
