import React from 'react';
import { Box } from '@mui/material';
var HighlightedText = function (_a) {
    var highlight = _a.highlight, text = _a.text;
    if (highlight) {
        return (React.createElement(Box, { component: 'span', sx: {
                display: 'inline-block',
                fontWeight: 'bold',
                // backgroundColor: yellow[300],
            } }, String(text)));
    }
    return React.createElement(React.Fragment, null, String(text));
};
export default HighlightedText;
