import diacritic from "diacritic";
// ucs-2 string to base64 encoded ascii
// eslint-disable-next-line
export var utoa = function (str) {
    return window.btoa(unescape(encodeURIComponent(str)));
};
// base64 encoded ascii to ucs-2 string
// eslint-disable-next-line
export var atou = function (str) {
    return decodeURIComponent(escape(window.atob(str)));
};
/** Encode string to Base64 string
 * @param {string} str String
 * @return {string} string Return encoded string
 * */
export var b64EncodeUnicode = function (str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function (_, p1) {
        var char = parseInt("0x" + p1, 16);
        return String.fromCharCode(char);
    }));
};
/** Decode base64 string to string
 * @param {string} str Base64 string
 * @return {string} string Return decoded string
 * */
export var b64DecodeUnicode = function (str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(window
        .atob(str)
        .split("")
        .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(""));
};
export var toCapitalize = function (str) {
    return str ? "".concat(str.charAt(0).toUpperCase()).concat(str.substring(1).toLowerCase()) : str;
};
export var isNumeric = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
};
var removeDiacritics = diacritic.clean;
// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
var specialCharsRegex = /[.*+?^${}()|[\]\\]/g;
// http://www.ecma-international.org/ecma-262/5.1/#sec-15.10.2.6
// const wordCharacterRegex = /[a-z0-9_]/i;
var whitespacesRegex = /\s+/;
function escapeRegexCharacters(str) {
    return str.replace(specialCharsRegex, "");
}
export var match = function (value, search) {
    var text = removeDiacritics(value);
    var query = removeDiacritics(search);
    return (query
        .trim()
        .split(whitespacesRegex)
        // If query is blank, we'll get empty string here, so let's filter it out.
        .filter(function (word) {
        return word.length > 0;
    })
        .reduce(function (result, word) {
        var wordLen = word.length;
        var escapedWord = escapeRegexCharacters(word);
        // const regex = new RegExp(escapeRegexCharacters(word), 'i');
        var index = text
            .toLowerCase()
            .indexOf(escapedWord.toLowerCase());
        if (index > -1) {
            result.push([index, index + wordLen]);
            // Replace what we just found with spaces so we don't find it again.
            text =
                text.slice(0, index) +
                    new Array(wordLen + 1).join(" ") +
                    text.slice(index + wordLen);
        }
        return result;
    }, [])
        .sort(function (match1, match2) {
        return match1[0] - match2[0];
    }));
};
export var base64ToArrayBuffer = function (base64) {
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    for (var i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
};
export var win1251ToUtf8 = function (raw) {
    var decoder = new TextDecoder("windows-1251");
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    for (var i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return decoder.decode(array);
};
export var autoKeyboardLang = function (str) {
    var s = [
        'й', 'ц', 'у', 'к', 'е', 'н', 'г', 'ш', 'щ', 'з', 'х', 'ъ',
        'ф', 'ы', 'в', 'а', 'п', 'р', 'о', 'л', 'д', ';', ':', 'ж', '"', 'э',
        'я', 'ч', 'с', 'м', 'и', 'т', 'ь', '\\?', ',', 'б', '\\/', '\\.', 'ю', '№',
    ];
    var r = [
        ['q', 'Q'], ['w', 'W'], ['e', 'E'], ['r', 'R'], ['t', 'T'], ['y', 'Y'], ['u', 'U'], ['i', 'I'], ['o', 'O'], ['p', 'P'], ['[', '{'], [']', '}'],
        ['a', 'A'], ['s', 'S'], ['d', 'D'], ['f', 'F'], ['g', 'G'], ['h', 'H'], ['j', 'J'], ['k', 'K'], ['l', 'L'], ['$'], ['^'], [';', ':'], ['@'], ['\'', '"'],
        ['z', 'Z'], ['x', 'X'], ['c', 'C'], ['v', 'V'], ['b', 'B'], ['n', 'N'], ['m', 'M'], ['&', '&'], ['?'], [',', '<'], ['|'], ['/'], ['.', '>'], ['#'],
    ];
    if (/[а-яА-Я]/.test(str)) {
        s.forEach(function (key, i) {
            var reg = new RegExp(key, 'mig');
            str = str.replace(reg, function (a) {
                return a === a.toLowerCase() ? r[i][0] : r[i][1];
            });
        });
    }
    return str;
};
