var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Icon, IconButton, InputAdornment, TextField } from '@mui/material';
import { ErrorAdornment } from '../components';
var PasswordInput = function (props) {
    var _a;
    var inputs = __rest(props.input, []), active = props.meta.active, 
    // errors,
    _b = props.options, label = _b.label, readonly = _b.readonly, disabled = _b.disabled, options = _b.options, labelProps = props.labelProps, 
    // required,
    error = props.error, loading = props.loading;
    var _c = __read(useState(false), 2), showPassword = _c[0], setShowPswd = _c[1];
    var handleMouseDownPassword = function (event) {
        event.preventDefault();
    };
    var handleClickShowPassword = function () {
        setShowPswd(!showPassword);
    };
    var variant = (_a = options === null || options === void 0 ? void 0 : options.variant) !== null && _a !== void 0 ? _a : 'standard';
    // console.log(meta);
    return (React.createElement(TextField, __assign({}, inputs, { label: label, fullWidth: true, autoComplete: "off", type: showPassword ? 'text' : 'password', InputLabelProps: labelProps, InputProps: {
            readOnly: readonly,
            endAdornment: (React.createElement(React.Fragment, null,
                React.createElement(InputAdornment, { position: "end" },
                    React.createElement(IconButton, { "aria-label": "Toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword, size: "small", tabIndex: -1 },
                        React.createElement(Icon, null, showPassword ? 'visibility_off' : 'visibility'))),
                React.createElement(ErrorAdornment, { message: error, open: active }))),
        }, 
        // variant={'filled'}
        error: !!error && !disabled, "aria-readonly": readonly || disabled || loading, disabled: disabled || loading, variant: variant })));
};
export default PasswordInput;
