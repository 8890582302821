var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Typography, Box } from '@mui/material';
import ReactResizeDetector from 'react-resize-detector';
var Title = function (_a) {
    var text = _a.text, onResize = _a.onResize;
    var handleResize = React.useCallback(function (_, height) {
        onResize === null || onResize === void 0 ? void 0 : onResize(height ? height + 10 : 0);
    }, [onResize]);
    return (React.createElement(Box, { sx: {
            color: 'grey.700',
            width: 1,
            // marginTop: -10,
            pb: 1.25,
        } },
        React.createElement(ReactResizeDetector, { handleWidth: false, onResize: handleResize }, function (_a) {
            var targetRef = _a.targetRef;
            return React.createElement(Typography, { ref: targetRef, variant: 'h6', color: 'inherit', style: { lineHeight: 1.2 } }, text);
        })));
};
var WithTitle = function (_a) {
    var title = _a.title, children = _a.children;
    var _b = __read(React.useState(0), 2), offset = _b[0], setOffset = _b[1];
    var handleChangeOffset = React.useCallback(function (height) {
        setOffset(height);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, { onResize: handleChangeOffset, text: title }),
        React.createElement("div", { style: { height: "calc(100% - ".concat(offset, "px)") } }, children)));
};
export default WithTitle;
