var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { FormControlLabel, Icon, TextField as MuiTextField, Checkbox as MuiCheckbox, MenuItem } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import React from 'react';
import { stopPropagation } from './utils';
export var Accordion = styled(function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(MuiAccordion, __assign({ disableGutters: true, elevation: 0, square: true }, props), children));
})(function (_a) {
    var theme = _a.theme;
    return ({
        border: "1px solid ".concat(theme.palette.divider),
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    });
});
export var AccordionSummary = styled(function (props) { return (React.createElement(MuiAccordionSummary, __assign({ expandIcon: React.createElement(Icon, { sx: { fontSize: '0.9rem !important' } }, "arrow_forward_ios") }, props))); })(function (_a) {
    var theme = _a.theme;
    return ({
        minHeight: 40,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            margin: '8px 0',
            marginLeft: theme.spacing(2),
        },
    });
});
export var AccordionDetails = styled(MuiAccordionDetails)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    });
});
export var TextField = function (_a) {
    var name = _a.name, label = _a.label, value = _a.value, onChange = _a.onChange;
    var handleChange = React.useCallback(function (event) {
        var value = event.target.value;
        onChange(value);
    }, [onChange]);
    return (React.createElement(MuiTextField, { name: name, label: label !== null && label !== void 0 ? label : '', value: value, size: 'small', margin: 'none', sx: { maxWidth: 150, width: 150 }, onChange: handleChange, onClick: stopPropagation, autoComplete: 'off' }));
};
export var SelectField = function (_a) {
    var name = _a.name, label = _a.label, value = _a.value, onChange = _a.onChange, values = _a.values;
    return (React.createElement(MuiTextField, { name: name, label: label !== null && label !== void 0 ? label : '', value: value, size: 'small', margin: 'none', select: true, sx: { maxWidth: 80, width: 80 }, onChange: function (event) { return onChange(event.target.value); }, onClick: function (event) { return event.stopPropagation(); } }, values.map(function (value, i) { return (React.createElement(MenuItem, { key: i, value: value }, value)); })));
};
export var Checkbox = function (_a) {
    var label = _a.label, checked = _a.checked, _b = _a.disabled, disabled = _b === void 0 ? false : _b, onChange = _a.onChange;
    var handleChange = React.useCallback(function (event) {
        onChange(event.target.checked);
    }, [onChange]);
    return (React.createElement(FormControlLabel, { sx: {
            '& > span': {
                typography: 'body2',
            },
        }, disabled: disabled, control: React.createElement(MuiCheckbox, { sx: { py: 0 }, size: 'small', checked: checked, onChange: handleChange, onClick: function (event) { return event.stopPropagation(); } }), label: label, onClick: function (event) { return event.stopPropagation(); } }));
};
