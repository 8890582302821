var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
var useScanner = function (_a) {
    var onEnter = _a.onEnter;
    var _b = __read(React.useState(''), 2), inputValue = _b[0], setInputValue = _b[1];
    var scannedValue = React.useRef('');
    var isScanner = React.useRef(false);
    var firstTime = React.useRef(0);
    var lastTime = React.useRef(0);
    var timeoutId = React.useRef(null);
    var reset = React.useCallback(function () {
        scannedValue.current = '';
        isScanner.current = false;
        firstTime.current = 0;
        lastTime.current = 0;
        setInputValue('');
    }, []);
    var handleChange = React.useCallback(function (event, newValue) {
        var now = Date.now();
        var value = event ? (event === null || event === void 0 ? void 0 : event.target).value : newValue;
        if (!value) {
            reset();
            return;
        }
        if (timeoutId.current)
            clearTimeout(timeoutId.current);
        if (!scannedValue.current && !firstTime.current && !lastTime.current && value.length === 1) {
            scannedValue.current = value;
            firstTime.current = now;
            timeoutId.current = setTimeout(function () {
                setInputValue(function () { return value; });
            }, 50);
        }
        else {
            if ((now - lastTime.current) < 100 && value.length === 1 && scannedValue.current) {
                isScanner.current = true;
                scannedValue.current += value;
                // setInputValue('');
            }
            else {
                isScanner.current = false;
                setInputValue(function () { return value; });
            }
        }
        lastTime.current = now;
    }, [reset]);
    var handleKeyDown = React.useCallback(function (event) {
        // timestamp.current = Date.now();
        if (event.code === 'Enter') {
            event.preventDefault();
            var value = isScanner.current ? scannedValue.current : inputValue;
            onEnter === null || onEnter === void 0 ? void 0 : onEnter(value);
            reset();
        }
    }, [inputValue, reset, onEnter]);
    return React.useMemo(function () { return ({
        events: {
            onChange: handleChange,
            onKeyDown: handleKeyDown,
        },
        reset: reset,
        value: inputValue,
    }); }, [handleChange, handleKeyDown, inputValue, reset]);
};
export default useScanner;
