var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { fetchFx, } from '@alta-m1/models';
import React from 'react';
var useLazyFetch = function (params) {
    var _a = __read(React.useState(false), 2), loading = _a[0], setLoading = _a[1];
    var _b = __read(React.useState(null), 2), data = _b[0], setData = _b[1];
    var _c = __read(React.useState(null), 2), error = _c[0], setError = _c[1];
    var getData = React.useCallback(function (variables) {
        setLoading(true);
        var newVariables = Object.assign({}, params.variables, variables);
        fetchFx(__assign(__assign({}, params), { variables: newVariables }))
            .then(function (_a) {
            var data = _a.data;
            return setData(data);
        })
            .catch(setError)
            .finally(function () { return setLoading(false); });
    }, [params]);
    var query = React.useMemo(function () { return ({
        loading: loading,
        error: error,
        data: data,
    }); }, [data, error, loading]);
    return [getData, query];
};
export default useLazyFetch;
