var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid, Skeleton, } from '@mui/material';
import { Button } from '@alta-m1/components/buttons';
import { Field,
// useField,
// useFormState,
 } from 'react-final-form';
import { getIn } from 'final-form';
import elements from './inputs';
import DividerInput from './elements/divider';
import { FieldArray } from 'react-final-form-arrays';
import { Label } from './components';
import clsx from 'clsx';
import { useDictionary } from '../dictionary';
import objectPath from 'object-path';
import { useI18n, useOrganization, useUser } from '@alta-m1/models/hooks';
import { useResizeDetector } from 'react-resize-detector';
export var registerInput = function (type, component) {
    // eslint-disable-next-line
    if (!elements[type]) {
        elements[type] = component;
    }
};
var ArrayFieldRow = React.memo(function (_a) {
    var _b;
    var prefix = _a.prefix, fields = _a.fields, element = _a.element, props = __rest(_a, ["prefix", "fields", "element"]);
    var match = prefix.match(/(\S+)\[(\d+)]/);
    if (match) {
        var _c = __read(match, 3), name_1 = _c[1], index_1 = _c[2];
        return (React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 1 },
            React.createElement(Grid, { item: true, xs: true },
                React.createElement(Grid, { container: true, spacing: 2, flexWrap: 'nowrap' },
                    React.createElement(FieldPrefix, { prefix: prefix }, (_b = element.fields) === null || _b === void 0 ? void 0 : _b.map(function (el, i) {
                        var _a;
                        var n = (_a = el.name) !== null && _a !== void 0 ? _a : name_1 + i;
                        var key = "".concat(i, "-").concat(n);
                        /*if (children) {
                            return children({key, name: n, options: el});
                        }*/
                        return (React.createElement(PrefixedField, __assign({ key: key, name: n, options: el }, props)));
                    })))),
            React.createElement(Grid, { item: true, style: { alignSelf: 'center' } },
                React.createElement(Button, { type: 'icon', item: 'delete', 
                    // @ts-ignore
                    size: 'small', action: function () { return fields.remove(Number(index_1)); } }))));
    }
    return null;
});
ArrayFieldRow.displayName = 'ArrayFieldRow';
export var ArrayField = React.memo(function (_a) {
    var _b, _c;
    var name = _a.name, element = _a.element, elementProps = _a.elementProps, disabled = _a.disabled;
    var defaultValue = React.useMemo(function () { var _a, _b; return Object.fromEntries((_b = (_a = element.fields) === null || _a === void 0 ? void 0 : _a.slice(0).filter(function (_a) {
        var name = _a.name;
        return !name;
    }).map(function (_a) {
        var name = _a.name;
        return ([name, undefined]);
    })) !== null && _b !== void 0 ? _b : []); }, [element.fields]);
    var maxLength = (_c = (_b = element.options) === null || _b === void 0 ? void 0 : _b.max) !== null && _c !== void 0 ? _c : 0;
    // const defaultValue = [] as any[];
    return (React.createElement(FieldArray, { name: name, 
        // defaultValue={defaultValue}
        // validateFields={[]}
        render: function (_a) {
            var _b;
            var fields = _a.fields;
            // const {element, elementProps, disabled} = props as unknown as Pick<IArrayFieldProps, 'elementProps' | 'element' | 'disabled'>;
            var length = (_b = fields.length) !== null && _b !== void 0 ? _b : 0;
            return (React.createElement(Grid, { item: true, container: true, xs: 12, spacing: 1 },
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { type: 'icon', item: 'add', 
                        // @ts-ignore
                        size: 'small', action: function () { return fields.push(defaultValue); }, disabled: disabled || (maxLength > 0 && maxLength === length) })),
                fields.map(function (fName) {
                    return (React.createElement(ArrayFieldRow, __assign({ key: fName, fields: fields, prefix: fName, element: element }, elementProps)));
                })));
        } }));
});
ArrayField.displayName = 'ArrayField';
var Condition = React.memo(function (_a) {
    var when = _a.when, is = _a.is, _b = _a.eq, eq = _b === void 0 ? true : _b, children = _a.children;
    return (React.createElement(Field, { name: when, subscription: { value: true }, render: function (_a) {
            var value = _a.input.value;
            var equal = Array.isArray(is) ? is.includes(value) : value === is;
            var result = eq ? equal : !equal;
            return result ? children : React.createElement("div", { style: { display: 'none' } }, children);
        } }));
});
Condition.displayName = 'MemoCondition';
/************ IMPORTANT CODE STARTS HERE **************/
var FieldPrefixContext = React.createContext('');
export var FieldPrefix = function (_a) {
    var prefix = _a.prefix, children = _a.children, dictionary = _a.dictionary;
    var actions = useDictionary().actions;
    React.useEffect(function () {
        if (dictionary) {
            actions.addDictionary(dictionary, prefix);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (React.createElement(FieldPrefixContext.Provider, { value: prefix }, children));
};
export var PrefixedField = React.memo(function (_a) {
    var name = _a.name, _b = _a.component, component = _b === void 0 ? 'element' : _b, props = __rest(_a, ["name", "component"]);
    var FieldComponent = component === 'element' ? Element : Field;
    var prefix = React.useContext(FieldPrefixContext);
    var prefixedName = "".concat(prefix, ".").concat(name);
    return (React.createElement(FieldComponent, __assign({ name: prefixedName }, props)));
});
PrefixedField.displayName = 'PrefixedField';
/************* IMPORTANT CODE ENDS HERE ***************/
export function getComponent(type) {
    return elements[type];
}
var isField = function (type) {
    switch (type) {
        case 'divider':
            return false;
        default:
            return true;
    }
};
var isGrid = function (type) {
    switch (type) {
        case 'hidden':
        case 'divider':
        case 'object':
        case 'alert':
            return false;
        default:
            return true;
    }
};
var MyFieldAdapter = /** @class */ (function (_super) {
    __extends(MyFieldAdapter, _super);
    function MyFieldAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyFieldAdapter.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.meta.dirty && this.props.onChange && this.props.input.value !== prevProps.input.value) {
            this.props.onChange(this.props.input.name, this.props.input.value);
        }
    };
    MyFieldAdapter.prototype.render = function () {
        return this.props.children;
    };
    return MyFieldAdapter;
}(React.PureComponent));
var WithGrid = function (props) {
    var hidden = props.hidden, type = props.type, size = props.size, children = props.children, rest = __rest(props, ["hidden", "type", "size", "children"]);
    var breakpoints = React.useMemo(function () { return size ? getBreakpoints(size, defBreakpoints) : defBreakpoints; }, [size]);
    var mounted = React.useRef(false);
    // const ref = React.useRef<any>();
    React.useEffect(function () {
        mounted.current = true;
        return function () {
            mounted.current = false;
        };
    }, []);
    var _a = useResizeDetector(), height = _a.height, ref = _a.ref;
    var className = clsx({ hidden: hidden || (!height && mounted.current) });
    var useGrid = isGrid(type);
    if (useGrid) {
        return (React.createElement(Grid, __assign({ className: className, item: true }, breakpoints, rest, { sx: {
                visibility: 'visible',
                opacity: 1,
                position: 'relative',
                // transition: 'opacity .65s linear',
                '&.hidden': {
                    visibility: 'hidden',
                    opacity: 0,
                    height: 0,
                    maxHeight: 0,
                    // display: 'none',
                    padding: '0px !important',
                },
            } }),
            React.createElement("div", { ref: ref, style: { width: '100%' } }, children)));
    }
    return React.createElement(React.Fragment, null, children);
};
// WithGrid.displayName = 'MemoWithGrid';
var formatters = {
    object: function (value) {
        if (value) {
            if (typeof value === 'string') {
                try {
                    return JSON.parse(value);
                }
                catch (_a) {
                    return {};
                }
            }
        }
        return {};
    },
    number: function (value) {
        if (value && Number.isFinite(value)) {
            return parseFloat(value);
        }
        return 0;
    },
    integer: function (value) {
        if (value && Number.isFinite(value)) {
            return parseInt(value);
        }
        return 0;
    },
    /*date: (value) => {
        return value === null ? '' : value;
    },*/
};
// @ts-ignore
var getFormatter = function (type) { return formatters[type]; };
var operations = {
    $current: function () {
        var date = new Date();
        return date.toISOString().split('T')[0];
    },
    $user: function (context, path) {
        if (!path) {
            return context['user']['GUID'];
        }
        return context['user'][path];
    },
    $inn: function (context) {
        return context['organization']['Inn'];
    },
    $organization: function (context, path) {
        return objectPath.get(context, "organization.".concat(path)) || '';
    },
    empty: function () { return ''; },
    $UnitRegExp: function () { return '/^(01)\\d{14}(21)\\S{13}(91|240)\\S*(==)$/ig'; },
};
var getOperation = function (operation) { return operations[operation] || operations['empty']; };
var useConvertingValue = function (value) {
    var user = useUser();
    var organization = useOrganization();
    var context = React.useMemo(function () { return ({
        user: user,
        organization: organization,
    }); }, [organization, user]);
    return React.useMemo(function () {
        if (value !== null && String(value).match(/^\$/gi)) {
            var type = String(value).split('.');
            var operation = getOperation(type[0]);
            return operation(context, type[1]);
        }
        return value;
    }, [context, value]);
};
var MyField = function (_a) {
    var name = _a.name, type = _a.type, initialValues = _a.initialValues, props = __rest(_a, ["name", "type", "initialValues"]);
    var _b = props.options, _c = _b.format, format = _c === void 0 ? false : _c, value = _b.value, _d = _b.required, required = _d === void 0 ? false : _d, _e = _b.prefix, prefix = _e === void 0 ? true : _e, copy = _b.copy, _f = _b.variable, variable = _f === void 0 ? false : _f;
    var _g = props.actions, getPrefix = _g.getPrefix, registerVariable = _g.registerVariable;
    var prefixedName = React.useMemo(function () { return "".concat(prefix ? getPrefix() : '').concat(name); }, [getPrefix, name, prefix]);
    // const defaultValue = React.useMemo(() => value && String(value).match(/^\$/gi) ? convertingValue(value) : value, [value]);
    var defaultValue = useConvertingValue(value);
    var formatter = React.useMemo(function () { return format ? getFormatter(type) : undefined; }, [format, type]);
    // const {initialValues} = useFormState({subscription: {initialValues: true}});
    var labelProps = React.useMemo(function () { return ({
        error: false,
        required: required,
        sx: {
            '& .asterisk': {
                color: 'error.light',
            },
        },
        classes: {
            asterisk: 'asterisk',
        },
        // shrink: true,
        // @ts-ignore
        component: Label,
    }); }, [required]);
    var Component = React.useMemo(function () { return getComponent(type === 'array' || type === '' ? 'text' : type); }, [type]);
    var defValue = React.useMemo(function () { var _a; return copy ? (_a = getIn(initialValues !== null && initialValues !== void 0 ? initialValues : {}, copy)) !== null && _a !== void 0 ? _a : defaultValue : defaultValue; }, [copy, defaultValue, initialValues]);
    // const subscription = React.useMemo(() => variable ? { value: true } : undefined, [variable]);
    React.useEffect(function () {
        if (variable) {
            registerVariable(prefixedName);
        }
    }, [variable, prefixedName, registerVariable]);
    // const isEqual = React.useMemo(() => variable ? () => true : undefined, [variable]);
    /*const {
        error: validError,
        submitError,
        dirtySinceLastSubmit,
        touched,
        dirty,
    } = useField(
        prefixedName,
        {
            // type: ['checkbox', 'switch'].includes(type) ? 'checkbox' : 'text',
            subscription: {
                error: true,
                submitError: true,
                dirty: true,
                dirtySinceLastSubmit: true,
                touched: true,
            },
        },
    );
    const error = ((touched || dirty) && validError) || (!dirtySinceLastSubmit && submitError);
    return (
        <Field
            {...props}
            name={prefixedName}
            type={['checkbox', 'switch'].includes(type) ? 'checkbox' : 'text'}
            format={formatter}
            parse={formatter}
            defaultValue={defValue ?? defaultValue}
            // initialValue={initial ?? defaultValue}
            component={component}
            // value={defaultValue}
            labelProps={labelProps}
            error={error}
        />
    );*/
    return (React.createElement(Field, { name: prefixedName, 
        /*subscription={{
            error: true,
            submitError: true,
            dirtySinceLastSubmit: true,
            touched: true,
            dirty: true,
        }}*/
        type: ['checkbox', 'switch'].includes(type) ? 'checkbox' : 'text', format: formatter, parse: formatter, defaultValue: defValue, 
        // initialValue={defaultValue} из-этой строчки не подставлялись value указанное в view 
        // subscription={subscription}
        // isEqual={isEqual}
        // silent={variable}
        render: function (field) {
            var _a = field.meta, validError = _a.error, submitError = _a.submitError, dirtySinceLastSubmit = _a.dirtySinceLastSubmit, touched = _a.touched, dirty = _a.dirty;
            var error = ((touched || dirty) && validError) || (!dirtySinceLastSubmit && submitError);
            /*return (
                <Field
                    {...props}
                    name={prefixedName}
                    type={['checkbox', 'switch'].includes(type) ? 'checkbox' : 'text'}
                    format={formatter}
                    parse={formatter}
                    defaultValue={defValue}
                    // initialValue={initial ?? defaultValue}
                    component={component}
                    // value={defaultValue}
                    labelProps={labelProps}
                    error={error}
                />
            );*/
            return (React.createElement(Component, __assign({}, field, props, { labelProps: labelProps, error: error })));
        } }));
};
// MyField.displayName = 'MemoizedField';
var WithField = React.memo(function (_a) {
    var name = _a.name, rest = __rest(_a, ["name"]);
    var _b = rest.actions, rsWatch = _b.rsWatch, isWatch = _b.isWatch;
    var condition = rest.options.condition;
    // console.log('WITH FIELD rest', rest)
    // console.log(defaultValue, name);
    var watch = isWatch(name);
    var component = React.useMemo(function () {
        if (watch) {
            return (React.createElement(Field, { name: name, subscription: { value: true, dirty: true }, render: function (_a) {
                    var props = __rest(_a, []);
                    return (React.createElement(MyFieldAdapter, __assign({ onChange: rsWatch }, props),
                        React.createElement(MyField, __assign({ name: name }, rest))));
                } }));
        }
        return React.createElement(MyField, __assign({ name: name }, rest));
    }, [watch, name, rest, rsWatch]);
    if (condition) {
        return (React.createElement(Condition, __assign({}, condition), component));
    }
    return component;
});
export var defBreakpoints = {
    xs: 12,
    sm: 4,
    md: 3,
    lg: 2,
    xl: 2,
};
export var getBreakpoints = function (size, breakpoints) {
    if (breakpoints === void 0) { breakpoints = defBreakpoints; }
    return Object.fromEntries(Object.entries(breakpoints).map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        if (typeof value === 'number') {
            switch (size) {
                case 'small':
                    return [key, Math.ceil(value / 2)];
                case 'medium':
                    return [key, Math.min(Math.trunc(value * 1.5), 12)];
                case 'large':
                    return [key, Math.min(value * 2, 12)];
                case 'extra':
                    return [key, Math.min(value * 3, 12)];
                case 'full':
                    return [key, 12];
                default:
                    return [key, 'auto'];
            }
        }
        return [key, 'auto'];
    }));
};
var ElementComponent = function (_a) {
    var type = _a.type, props = __rest(_a, ["type"]);
    var _b = __read(useI18n(), 1), getTranslate = _b[0];
    var hidden = props.hidden, // eslint-disable-line
    formLoading = props.formLoading, // eslint-disable-line
    propsOptions = props.options, actions = props.actions, rest = __rest(props, ["hidden", "formLoading", "options", "actions"]);
    var options = __assign(__assign({}, propsOptions), { label: propsOptions.title || getTranslate(propsOptions.alias || rest.name, 'fields') || rest.name, disabled: actions.getPosted() || propsOptions.disabled || propsOptions.variable });
    // const props = withOutProps({...this.props}, ['formLoading', 'intl', 'local', 'hidden', 'options']);
    if (type === 'divider') {
        return React.createElement(DividerInput, __assign({}, rest, { options: options }));
    }
    else if (options.type !== 'divider') {
        return (React.createElement(WithField, __assign({ type: type }, rest, { actions: actions, options: options })));
    }
    else {
        return null;
    }
};
/*interface IElementState {
    breakpoints: IBreakpoints;
    withGrid: boolean;
    withField: boolean;
}*/
var Element = function (_a) {
    var _b = _a.initialValues, initialValues = _b === void 0 ? {} : _b, ignored = _a.children, props = __rest(_a, ["initialValues", "children"]);
    // const {initialValues} = useFormState({subscription: {initialValues: true}});
    // const {breakpoints, withGrid} = state;
    var mounted = React.useRef(false);
    var type = props.options.type;
    var actions = useDictionary().actions;
    /*const wrappers = React.useMemo(() => {
        // const type = this.props.hidden && !this.props.options.required && !this.props.formLoading ? 'hidden' : this.props.options.type;
        const withGrid = isGrid(type);
        const withField = isField(type);
        return {withGrid, withField};
    }, [type]);*/
    React.useEffect(function () {
        mounted.current = true;
        var options = props.options, _a = props.actions, setAliasField = _a.setAliasField, getPrefix = _a.getPrefix, name = props.name;
        if (options.type !== 'divider') {
            if ('dictionary' in options && options.dictionary) {
                actions.addDictionary(options.dictionary, options.prefix === false ? name : getPrefix() + name);
            }
            if ('recordset' in options && options['recordset']) {
                setAliasField(options.name, options['recordset']);
            }
        }
        return function () {
            mounted.current = false;
        };
    }, []); // eslint-disable-line
    var prefix = 'prefix' in props.options ? props.options.prefix : true;
    var prefixedName = React.useMemo(function () { return "".concat(prefix ? props.actions.getPrefix() : '').concat(props.name); }, [props.actions, props.name, prefix]);
    var hidden = React.useMemo(function () { return Boolean(props.hidden) && !(props.options.required || !!getIn(initialValues, prefixedName) || props.options.visible) && type !== 'divider'; }, [initialValues, prefixedName, props.hidden, props.options.required, props.options.visible, type]);
    var formLoading = props.formLoading;
    var loading = formLoading && isField(type) && type !== 'hidden' && !hidden && type !== 'alert';
    // if (mounted.current) {
    return (React.createElement(WithGrid, { hidden: hidden, type: type, size: 'size' in props.options ? props.options.size : null }, loading ? (React.createElement(Skeleton, { variant: "rectangular", animation: 'wave', height: 45, width: '100%' })) : (React.createElement(ElementComponent, __assign({}, props, { type: type, initialValues: initialValues })))));
    // }
    /*return (
        <Grid item {...breakpoints}>
            <Skeleton variant="rectangular" animation={'wave'} height={45} width={'100%'} />
        </Grid>
    );*/
};
export default Element;
