var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { TextField } from '@mui/material';
import { ErrorAdornment } from '../components';
var TextAreaInput = function (props) {
    var _a, _b, _c;
    var inputs = __rest(props.input, []), active = props.meta.active, 
    // errors,
    _d = props.options, label = _d.label, readonly = _d.readonly, disabled = _d.disabled, options = _d.options, labelProps = props.labelProps, 
    // required,
    loading = props.loading, error = props.error;
    // console.log(this.props)
    var rows = (_a = options === null || options === void 0 ? void 0 : options.rows) !== null && _a !== void 0 ? _a : 1;
    var rowsMax = (_b = options === null || options === void 0 ? void 0 : options.rowsMax) !== null && _b !== void 0 ? _b : 4;
    var variant = (_c = options === null || options === void 0 ? void 0 : options.variant) !== null && _c !== void 0 ? _c : 'standard';
    return (React.createElement(TextField, __assign({}, inputs, { label: label, fullWidth: true, multiline: true, minRows: rows, maxRows: rowsMax, autoComplete: "off", type: "text", InputLabelProps: labelProps, InputProps: {
            readOnly: readonly,
            endAdornment: React.createElement(ErrorAdornment, { message: error, open: active }),
        }, error: !!error && !disabled, disabled: disabled || loading, variant: variant })));
};
export default TextAreaInput;
