var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Chip, Icon, IconButton, InputAdornment, TextField, } from '@mui/material';
import { b64EncodeUnicode, b64toBlob, downloadFile, getBase64, } from '@alta-m1/helpers';
import { ErrorAdornment } from '@alta-m1/form';
var acceptList = {
    json: 'application/json',
    pdf: 'application/pdf',
    png: 'image/png',
};
var getAccept = function (type) {
    var accept = type ? acceptList[type] : acceptList['json'];
    return Array.isArray(accept) ? accept.join(',') : accept;
};
var FileInput = React.memo(function (props) {
    var 
    // value,
    _a = props.input, name = _a.name, value = _a.value, onChange = _a.onChange, active = props.meta.active, 
    // errors,
    _b = props.options, label = _b.label, readonly = _b.readonly, disabled = _b.disabled, ext = _b.ext, labelProps = props.labelProps, 
    // required,
    loading = props.loading, error = props.error;
    var inputRef = React.useRef(null);
    var handleClick = function () {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    var handleChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var files, fileName_1, fileExt, file, value_1, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    files = e.currentTarget.files;
                    if (!files) return [3 /*break*/, 4];
                    fileName_1 = files[0]['name'];
                    fileExt = fileName_1.substring(fileName_1.lastIndexOf('.') + 1);
                    file = ext === fileExt ? files[0] : null;
                    if (!file) return [3 /*break*/, 2];
                    return [4 /*yield*/, getBase64(file)];
                case 1:
                    _a = _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    _a = '';
                    _b.label = 3;
                case 3:
                    value_1 = _a;
                    onChange(value_1);
                    _b.label = 4;
                case 4:
                    if (inputRef.current) {
                        inputRef.current.value = '';
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var accept = React.useMemo(function () { return getAccept(ext); }, [ext]);
    var fileName = React.useMemo(function () {
        if (value) {
            return "".concat(name, ".").concat(ext);
        }
        return '';
    }, [ext, name, value]);
    var handleDownload = React.useCallback(function (event) {
        if (event && event.preventDefault) {
            event.preventDefault();
            event.stopPropagation();
        }
        var v;
        try {
            var temp = JSON.stringify(JSON.parse(value));
            v = b64EncodeUnicode(temp);
        }
        catch (_a) {
            v = value;
        }
        // console.log(v);
        var blob = b64toBlob(v, accept);
        downloadFile(fileName, blob);
    }, [accept, fileName, value]);
    return (React.createElement(TextField, { name: name, label: label, 
        // value={fileName}
        // variant="outlined"
        autoComplete: "off", onClick: handleClick, InputLabelProps: labelProps, InputProps: {
            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                React.createElement(Chip, { size: 'small', label: fileName || ext }))),
            endAdornment: (React.createElement(React.Fragment, null,
                React.createElement(InputAdornment, { position: "end" },
                    React.createElement(IconButton, { size: 'small', disabled: !value, onClick: handleDownload },
                        React.createElement(Icon, null, "download"))),
                React.createElement(ErrorAdornment, { message: error, open: active }))),
            inputRef: inputRef,
            inputProps: {
                style: {
                    visibility: 'hidden',
                    opacity: 0,
                    //display: 'none',
                },
                accept: accept,
                defaultValue: '',
                onChange: handleChange,
            },
            style: {
                cursor: 'pointer',
            },
            readOnly: readonly,
        }, type: "file", 
        // margin="normal"
        fullWidth: true, error: Boolean(error), helperText: error, disabled: disabled || loading }));
});
export default FileInput;
