var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable indent */
import { attach, createEvent, createStore, combine, restore, createEffect } from 'effector';
import { reshape, interval } from 'patronum';
import { fetchFx } from '../fetch';
import { settingsFields } from './defaults';
var OMS_DURATION = (import.meta.env.VITE_OMS_SESSION_DURATION || 36000) * 1000;
export var loadLogsFx = attach({
    effect: fetchFx,
    mapParams: function () {
        var query = 'query {logs:GISMTLogs {...on StringBox {value} ...on Error {name message} } }';
        return { query: query };
    },
    name: '@@logs/load',
});
export var $logsError = restore(loadLogsFx.failData, null);
export var fetchLogs = createEvent('@@logs/fetch');
export var updateLogs = createEvent('@@logs/update');
export var $logsStore = createStore([], { name: '@@logs' });
export var $logs = combine({
    data: $logsStore,
    loading: loadLogsFx.pending,
    error: $logsError,
});
export var loadFx = attach({
    effect: fetchFx,
    mapParams: function () {
        var query = 'query { query:SettingsList { ...on SettingsResult { list { GUID Domain Name Value Description } } ...on Error { name message } } }';
        return { query: query };
    },
    name: '@@integration/load',
});
export var update = loadFx.prepend(function (f) { return f; });
export var $store = createStore({}, { name: '@@integration' });
export var updateSettingsFields = createEvent();
export var getSettingsFieldsFx = createEffect('@@integration-settings-fields/get');
export var $settingsFields = createStore(settingsFields, { name: '@@integration-settings-fields' });
export var updateSettings = createEvent('@@integration-settings/update');
export var $settings = createStore([], {
    name: '@@integration-settings',
});
export var saveFx = attach({
    effect: fetchFx,
    source: $settings,
    mapParams: function (_a, settings) {
        var name = _a.name, value = _a.value;
        var _b = __read(name.split('.'), 2), Domain = _b[0], Name = _b[1];
        var field = settings.find(function (item) { return item.Domain === Domain && item.Name === Name; });
        var data = field
            ? __assign(__assign({}, field), { Value: value }) : {
            Name: Name,
            Domain: Domain,
            Description: '',
            Value: value,
        };
        var query = 'mutation($data: SettingsInput) { mutation:SettingsMutation { Upsert(data: $data) { ...on Settings { GUID Domain Name Value Description } ...on Errors { errors { name message } } } } }';
        return { query: query, variables: { data: data } };
    },
    name: '@integration-settings/save',
});
export var $settingsStore = combine({
    values: $settings,
    fields: $settingsFields,
});
export var fetchSessionFx = attach({
    effect: fetchFx,
    mapParams: function () {
        var query = 'query { query:OMSAuthList { ...on OMSAuthList { list { user:UserGUID type:Type start:updatedAt token:Token OMSConnectionID } } ... on Error { name message } } }';
        return { query: query };
    },
    name: '@@session/fetch',
});
export var pingFx = attach({
    effect: fetchFx,
    mapParams: function (extension) {
        var query = 'query($extension: String) { query:OMSPingRequest(extension: $extension) { ...on BooleanBox { value } ... on Error { name message } } }';
        return { query: query, variables: { extension: extension } };
    },
});
export var getProvidersFx = attach({
    effect: fetchFx,
    mapParams: function () {
        var query = 'query { query:OMSProvidersRequest (extension:"lp") { ...on ProvidersList { list {ServiceProviderId Role ProductGroups Country Address ContactPerson Email Name ProviderName TaxIdentificationNumber} } ... on Error { name message } } }';
        return { query: query };
    },
});
export var resetSession = createEvent('@@session/reset');
export var updateSession = createEvent('@@session/update');
export var setConnectionId = createEvent('@@session/set-connectionId');
export var startTick = createEvent('@@session/tick/start');
export var stopTick = createEvent('@@session/tick/stop');
export var sessionTick = interval({
    timeout: 10000,
    start: startTick,
    stop: stopTick,
});
export var $connectionID = createStore('', {
    name: '@@session/connectionID',
});
export var $sessionStore = createStore({
    start: '',
    token: '',
    now: Date.now(),
    duration: OMS_DURATION,
    providers: [],
    // connectionID: '',
}, { name: '@@session' });
export var session = reshape({
    source: $sessionStore,
    shape: {
        active: function (session) {
            if (session.start) {
                var start = new Date(session.start).getTime();
                return session.now - start < session.duration;
            }
            return false;
        },
        left: function (session) {
            if (session.start) {
                var start = new Date(session.start).getTime();
                return session.duration - (session.now - start);
            }
            return 0;
        },
    },
});
export var $session = combine($sessionStore, $connectionID, combine(session), function (store, connectionID, values) { return (__assign(__assign(__assign({}, store), values), { connectionID: connectionID })); });
export var $sessionStatuses = combine({
    fetch: fetchSessionFx.pending,
    ping: pingFx.pending,
    providers: getProvidersFx.pending,
});
