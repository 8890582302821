import camelCase from "camelcase";
import { encodeFilters, toCapitalize } from "@alta-m1/helpers";
export var wrapQueryString = function (str) { return "".concat(str); };
var fragments = {
    error: "\n        fragment error on Error {\n            name\n            message\n        }\n    ",
    validError: "\n        fragment ValidError on ValidationError {\n            errors {\n                error\n                message\n            }\n        }\n    ",
    errors: "\n        fragment errors on Errors {\n            errors {\n                error\n                message\n            }\n        }\n    ",
};
export var createCustomDictionaryQuery = function (_a) {
    var _b = _a === void 0 ? {
        name: "",
        filters: [],
        fields: [],
        info: true,
        useFilters: false,
    } : _a, _c = _b.name, name = _c === void 0 ? "" : _c, _d = _b.filters, filters = _d === void 0 ? [] : _d, _e = _b.fields, fields = _e === void 0 ? [] : _e, _f = _b.useFilters, useFilters = _f === void 0 ? false : _f, variables = _b.variables;
    /* options: name,entries,limit,page,sorters,filters */
    var queryParams = [
        "$page: Int = 1",
        "$limit: Int = 99999",
        "$sort: [Sort]",
    ];
    var params = ["page: $page", "limit: $limit", "sort: $sort"];
    /*if (page) {
        params.push();
    }*/
    if (Array.isArray(filters)) {
        if (useFilters) {
            var encodedFilters = encodeFilters(filters);
            params.push("filters: ".concat(JSON.stringify(encodedFilters)));
        }
        else {
            filters.forEach(function (_a) {
                var field = _a.field, value = _a.value;
                params.push("".concat(field, ":\"").concat(value, "\""));
            });
        }
    }
    if (variables) {
        variables.forEach(function (_a) {
            var name = _a.name, type = _a.type;
            queryParams.push("$".concat(name, ": ").concat(type));
            params.push("".concat(name, ": $").concat(name));
        });
    }
    var normalizedName = camelCase(name, {
        preserveConsecutiveUppercase: true,
        pascalCase: true,
    });
    return "query (".concat(queryParams.join(", "), ") {\n            custom:").concat(normalizedName, "List(").concat(params.join(", "), ") {\n                ...on ").concat(normalizedName, "Result {\n                    list {\n                        ").concat(Array.isArray(fields) ? fields.join("\n") : fields, "\n                    }\n                }\n                ...on Error {\n                    name\n                    message\n                }\n            }\n        }\n    ");
};
export var createMutation = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.name, name = _c === void 0 ? "" : _c, _d = _b.entries, entries = _d === void 0 ? [] : _d, _e = _b.method, method = _e === void 0 ? "delete" : _e, _f = _b.key, key = _f === void 0 ? "GUID" : _f;
    var czName = toCapitalize(name);
    switch (method) {
        case "add":
        case "edit":
            return "\n                    mutation($data : ".concat(czName, "ModelInput) {\n                        mutation:").concat(czName, "Mutation {\n                            ").concat(method, "(data: $data)  {\n                                ...on ").concat(czName, " {\n                                    ").concat(entries.join(), "\n                                }\n                                ...ValidError\n                            }\n                        }\n                    }\n                    ").concat(fragments.validError, "\n                ");
        case "delete":
        default:
            return "\n                    mutation($".concat(key, ": String!) {\n                        mutation:").concat(czName, "Mutation {\n                            ").concat(method, "(").concat(key, " : $").concat(key, ") {\n                                result\n                                message\n                            }\n                        }\n                    }\n                ");
    }
};
export var createSystemDictionaryQuery = function (name, options) {
    if (name === void 0) { name = ""; }
    if (options === void 0) { options = {}; }
    name = toCapitalize(name);
    var _a = options.params, params = _a === void 0 ? [] : _a;
    var queryTypes = [
        '$SearchParam: String = "%"',
        "$limit: Int = 99999",
        "$Alias: String!",
    ];
    var variables = [
        "SearchParam: $SearchParam",
        "limit: $limit",
        "Alias: $Alias",
    ];
    params.forEach(function (_a) {
        var name = _a.name, type = _a.type;
        if (name && type) {
            queryTypes.push("$".concat(name, ": ").concat(type));
            variables.push("".concat(name, ": $").concat(name));
        }
    });
    return "\n        query dictionary".concat(name, " (").concat(queryTypes.join(","), ") {\n            system:RefbooksSearchList(").concat(variables.join(","), ") {\n                ...on RefbooksSearchResult {\n                    list\n                    pageInfo {\n                        page:curPage\n                        last:last_page\n                        count:totalCount\n                    }\n                }\n                ...error\n            }\n        }\n        ").concat(fragments.error, "\n    ");
};
export var createMutationRequest = function (options) {
    var _a;
    /* options: parent,params */
    // const name = options.name;
    var arr = [];
    var _b = (_a = options.params) !== null && _a !== void 0 ? _a : {}, field = _b.field, value = _b.value;
    if (field && value) {
        arr.push("".concat(field, ": \"").concat(value, "\""));
    }
    var name = camelCase(options.name, {
        pascalCase: true,
        preserveConsecutiveUppercase: true,
    });
    return "\n        mutation ($data: ".concat(name, "Input) {\n            mutation:").concat(name, "Mutation").concat(arr.length ? "(".concat(arr.join(","), ")") : "", " {\n                Upsert(data: $data) {\n                    ...on Errors {\n                        errors {\n                            name\n                            message\n                        }\n                    }\n                    ...on ").concat(name, " {\n                        GUID\n                    }\n                }\n            }\n        }\n    ");
};
var defaultQueryProps = {
    name: "",
    params: [],
    fields: [],
    suffixResult: "",
    useFilters: false,
    excludeSort: false,
    variables: [],
};
export var createQueryRequest = function (options) {
    if (options === void 0) { options = defaultQueryProps; }
    /* options: name,entries,limit,page,sorters,filters */
    var arr = ["limit: $limit", "page: $page"];
    var queryTypes = ["$limit: Int = 99999", "$page: Int = 1"];
    var name = options.name, _a = options.params, params = _a === void 0 ? [] : _a, fields = options.fields, _b = options.suffixResult, suffixResult = _b === void 0 ? "" : _b, _c = options.useFilters, useFilters = _c === void 0 ? false : _c, _d = options.excludeSort, excludeSort = _d === void 0 ? false : _d, _e = options.variables, variables = _e === void 0 ? [] : _e;
    if (!excludeSort) {
        queryTypes.push("$sort: [Sort]");
        arr.push("sort: $sort");
    }
    if (useFilters) {
        var encodedFilters = encodeFilters(params);
        arr.push("filters: ".concat(JSON.stringify(encodedFilters)));
    }
    else {
        params.forEach(function (_a) {
            var field = _a.field, value = _a.value;
            arr.push("".concat(field, ": ").concat(JSON.stringify(value)));
        });
        variables.forEach(function (_a) {
            var name = _a.name, type = _a.type;
            queryTypes.push("$".concat(name, ": ").concat(camelCase(type, {
                preserveConsecutiveUppercase: true,
                pascalCase: true,
            })));
            arr.push("".concat(name, ": $").concat(name));
        });
    }
    var capitalizeName = camelCase(name, {
        preserveConsecutiveUppercase: true,
        pascalCase: true,
    });
    return "\n        query ".concat(capitalizeName, "(").concat(queryTypes.join(","), ") {\n            query:").concat(capitalizeName, "List(").concat(arr.join(","), ") {\n                ...on ").concat(capitalizeName).concat(camelCase(suffixResult, {
        pascalCase: true,
    }), "Result {\n                    list {\n                        ").concat(Array.isArray(fields) ? fields.join("\n") : fields, "\n                    }\n                    pageInfo {\n                        count:totalCount\n                        page:curPage\n                        last:last_page\n                    }\n                }\n                ...on Error {\n                    name\n                    message\n                }\n            }\n        }\n    ");
};
export var createTreeRequest = function (options) {
    if (options === void 0) { options = {}; }
    /* options: name,entries,limit,page,sorters,filters */
    var arr = [];
    var _a = options.filters, filters = _a === void 0 ? [] : _a;
    if (filters.length) {
        filters.forEach(function (_a) {
            var field = _a.field, value = _a.value;
            arr.push("".concat(field, ":\"").concat(value, "\""));
        });
    }
    return "\n        query {\n            DataTree".concat(arr.length ? "(".concat(arr.join(","), ")") : "", " {\n                ...on TreeResult {\n                    tree\n                }\n                ...on Error {\n                    error\n                    message\n                }\n            }\n        }\n    ");
};
export var createFixMutate = function (name, unFix) {
    if (name === void 0) { name = ""; }
    if (unFix === void 0) { unFix = false; }
    return "\n        mutation($GUID: String) {\n            mutation:".concat(name, "Mutation {\n                ").concat(unFix ? "Un" : "", "Fix(GUID: $GUID) {\n                    ...on ").concat(name, " {\n                        FixTime\n                    }\n                    ...on Errors {\n                        errors {\n                            message\n                        }\n                    }\n                }\n            }\n        }\n    ");
};
export var parseErrors = function (errors, options) {
    if (errors === void 0) { errors = []; }
    if (options === void 0) { options = {}; }
    var _a = options.noCategory, noCategory = _a === void 0 ? false : _a, _b = options.shuffle, shuffle = _b === void 0 ? false : _b;
    var key = shuffle ? "name" : "message";
    var output = errors.map(function (error) {
        return "".concat(noCategory
            ? ""
            : toCapitalize("category" in error && error.category ? error.category : "error") + ": ").concat(error[key].replace(/\s+/g, " "));
    });
    return "".concat(output.join("\n"));
};
export var getQueryReport = function (params) {
    if (params === void 0) { params = []; }
    var parameters = ["Alias: $alias"];
    params.forEach(function (_a) {
        var field = _a.field, value = _a.value;
        parameters.push("".concat(field, ": ").concat(JSON.stringify(value)));
    });
    return "\n        query($alias: String) {\n            query:Reports (".concat(parameters.join(","), ") {\n                ...on ReportData {\n                    report:Data\n                }\n                ...on Error {\n                    name\n                    message\n                }\n            }\n        }\n    ");
};
