var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { sample } from 'effector';
import { updateViews, updateView, $viewsStore, loadFx } from '.';
import { triggerFx, logout } from '../authenticate';
import keyBy from 'lodash/keyBy';
import defaults from 'lodash/defaults';
// const { createView } = createFactoryView();
/* const normalize = (value: ViewData): View => {
    return {
        name: value.name,
        type: value.type ?? 'system',
        developing: value.developing ?? false,
        alias: value.alias ?? value.name,
        mode: value.mode ?? 'page',
    };
}; */
var mapNormalize = function (data) {
    return data.map(function (view) {
        return defaults(view, {
            name: view.name,
            type: 'system',
            developing: false,
            alias: view.name,
            mode: 'page',
        });
    });
};
loadFx.use(function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, data, normalized, error_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 5]);
                return [4 /*yield*/, fetch('/data/views.json')];
            case 1:
                response = _b.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _b.sent();
                normalized = mapNormalize(data);
                return [2 /*return*/, keyBy(normalized, 'name')];
            case 3: return [2 /*return*/, Promise.reject(new Error(response.statusText))];
            case 4:
                error_1 = _b.sent();
                return [2 /*return*/, Promise.reject(new Error((_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.message) !== null && _a !== void 0 ? _a : String(error_1)))];
            case 5: return [2 /*return*/];
        }
    });
}); });
$viewsStore
    .on(updateViews, function (_, payload) { return payload; })
    .on(updateView, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), (_a = {}, _a[payload.name] = payload, _a));
})
    .reset(logout);
sample({
    clock: loadFx.doneData,
    /* fn(payload) {
        return new Map(Object.entries(payload));
    }, */
    target: updateViews,
});
/* forward({
    from: loadFx.doneData,
    to: updateViews,
}); */
sample({
    clock: triggerFx.doneData,
    filter: function (result) {
        return result;
    },
    target: loadFx,
});
