var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { TextField } from '@mui/material';
import { ErrorAdornment, ReturnAdornment } from '../components';
var TextInput = React.memo(function (props) {
    var _a;
    var inputs = __rest(props.input, []), active = props.meta.active, 
    // errors,
    _b = props.options, label = _b.label, readonly = _b.readonly, disabled = _b.disabled, options = _b.options, defaultValue = _b.defaultValue, labelProps = props.labelProps, 
    // required,
    loading = props.loading, error = props.error;
    var variant = (_a = options === null || options === void 0 ? void 0 : options.variant) !== null && _a !== void 0 ? _a : 'standard';
    var hasDefault = defaultValue != null;
    var visibleReturn = hasDefault && defaultValue !== inputs.value;
    var handleReturn = function () {
        inputs.onChange(defaultValue);
    };
    // console.log(meta);
    return (React.createElement(TextField, __assign({}, inputs, { 
        /* onChange={(event) => {
            const value = event.target.value || undefined;
            onChange(value);
        }} */
        label: label, fullWidth: true, autoComplete: "off", type: "text", InputLabelProps: labelProps, InputProps: {
            readOnly: readonly,
            endAdornment: (React.createElement(React.Fragment, null,
                React.createElement(ReturnAdornment, { onClick: handleReturn, position: 'end', visible: visibleReturn }),
                React.createElement(ErrorAdornment, { message: error, open: active }))),
        }, 
        // variant={'filled'}
        error: !!error && !disabled, "aria-readonly": readonly || disabled || loading, disabled: disabled || loading, variant: variant })));
});
export default TextInput;
