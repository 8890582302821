import React from 'react';
import { router, getRouteByName } from '..';
var useNavigate = function () {
    var navigate = React.useCallback(function (path, options) {
        var _a = options !== null && options !== void 0 ? options : {}, _b = _a.replace, replace = _b === void 0 ? false : _b, _c = _a.query, query = _c === void 0 ? {} : _c, _d = _a.params, params = _d === void 0 ? {} : _d;
        var route = getRouteByName(path.replaceAll('/', ''));
        var method = replace ? 'replace' : 'push';
        if (route != null && !Array.isArray(route)) {
            route.navigate({
                params: params,
                query: query,
                replace: replace,
            });
        }
        else {
            router.push({ method: method, path: path, query: query, params: params });
        }
    }, []);
    return navigate;
};
export default useNavigate;
