import { createStore } from 'effector';
import { logout } from './authenticate';
import { pending } from 'patronum';
import { loadFx as user } from './user';
import { loadFx as views } from './views';
import { loadFx as menu } from './menu';
import { loadFx as settings } from './settings';
import { loadFx as organization } from './organization';
import { loadFx as catalog } from './catalog';
export var $appLoading = pending({
    effects: [user, views, menu, settings, organization, catalog],
    of: 'some',
});
export var $appLoaded = createStore(false, { name: 'app-loaded' })
    .on($appLoading, function (_, payload) { return !payload; })
    .reset(logout);
