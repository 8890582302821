var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { createCustomDictionaryQuery, } from '@alta-m1/client';
import { useLazyFetch } from '@alta-m1/hooks';
import { CircularProgress, InputAdornment, TextField, Autocomplete, ListItem, } from '@mui/material';
import { ErrorAdornment } from '@alta-m1/form';
import { sorter } from '@alta-m1/helpers';
var AutocompleteInput = function (props) {
    var _a = props.input, name = _a.name, value = _a.value, onChange = _a.onChange, input = __rest(_a, ["name", "value", "onChange"]), active = props.meta.active, 
    // errors,
    // required,
    _b = props.options, label = _b.label, disabled = _b.disabled, readonly = _b.readonly, advanced = _b.advanced, labelProps = props.labelProps, error = props.error;
    var _c = advanced !== null && advanced !== void 0 ? advanced : {}, queryName = _c.name, fields = _c.fields, queryVariables = _c.variables, _d = _c.key, labelKey = _d === void 0 ? '' : _d;
    /*const [{value: inputValue}, setInputValue] = React.useReducer((state, value) => ({
        ...state,
        value,
    }), {value: ''}, (state) => ({...state}));*/
    // const inputValue = React.useRef('');
    // const {enqueueSnackbar: notify} = useSnackbarPatched();
    var query = React.useMemo(function () { return createCustomDictionaryQuery({
        name: queryName,
        info: false,
        fields: fields,
        variables: queryVariables === null || queryVariables === void 0 ? void 0 : queryVariables.params,
    }); }, [fields, queryName, queryVariables === null || queryVariables === void 0 ? void 0 : queryVariables.params]);
    var variables = React.useMemo(function () { var _a; return ((_a = queryVariables === null || queryVariables === void 0 ? void 0 : queryVariables.values) !== null && _a !== void 0 ? _a : {}); }, [queryVariables === null || queryVariables === void 0 ? void 0 : queryVariables.values]);
    var _e = __read(useLazyFetch({ query: query }), 2), getData = _e[0], _f = _e[1], data = _f.data, loading = _f.loading, loadError = _f.error;
    /*if (loadError) {
        notify(loadError.message, 'error');
    }*/
    var opts = React.useMemo(function () {
        var _a;
        if (data) {
            return ((_a = data['custom'].list) !== null && _a !== void 0 ? _a : []).reduce(function (acc, option) {
                var index = acc.findIndex(function (opt) { return opt[labelKey] === option[labelKey]; });
                return index === -1 ? __spreadArray(__spreadArray([], __read(acc), false), [option], false) : acc;
            }, []);
        }
        return [];
    }, [data, labelKey]);
    var initialValue = React.useMemo(function () {
        var _a;
        var _b;
        return (_b = opts.find(function (opt) { return opt[labelKey] === value; })) !== null && _b !== void 0 ? _b : (_a = {}, _a[labelKey] = value, _a);
    }, [labelKey, opts, value]);
    var mounted = React.useRef(false);
    var filterOptions = React.useCallback(function (options, _a) {
        var _b;
        var inputValue = _a.inputValue;
        var filtered = sorter(options, inputValue, {
            keys: [labelKey],
            // threshold: matchSorter.rankings.CONTAINS,
        });
        var isExisting = options.some(function (option) { return inputValue === option[labelKey]; });
        if (inputValue !== '' && !isExisting) {
            filtered.push((_b = {
                    inputValue: inputValue
                },
                _b[labelKey] = "Add \"".concat(inputValue, "\""),
                _b));
        }
        return filtered;
    }, [labelKey]);
    React.useEffect(function () {
        mounted.current = true;
        getData(variables);
        return function () {
            mounted.current = false;
        };
    }, []); // eslint-disable-line
    var handleChange = React.useCallback(function (newValue) {
        if (typeof newValue === 'string') {
            onChange(newValue);
        }
        else if (typeof newValue === 'object' && (newValue === null || newValue === void 0 ? void 0 : newValue.inputValue)) {
            // Create a new value from the user input
            onChange(newValue === null || newValue === void 0 ? void 0 : newValue.inputValue);
        }
        else if (typeof newValue === 'object' && (newValue === null || newValue === void 0 ? void 0 : newValue[labelKey])) {
            onChange(newValue === null || newValue === void 0 ? void 0 : newValue[labelKey]);
        }
        else {
            onChange(newValue);
        }
    }, [labelKey, onChange]);
    var getOptionSelected = React.useCallback(function (option, value) { return option[labelKey] === value[labelKey]; }, [labelKey]);
    var getOptionLabel = React.useCallback(function (option) {
        if (typeof option === 'string') {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }
        // Regular option
        return option[labelKey];
    }, [labelKey]);
    var renderOption = React.useCallback(function (props, option) {
        var value = option[labelKey] ? option[labelKey].toString() : '';
        // const matches = match(value, inputValue);
        // const parts = parse(value, matches);
        return (React.createElement(ListItem, __assign({}, props, { dense: true, sx: {
                fontSize: function (theme) { return theme.typography.pxToRem(13); },
            } }), value));
    }, [labelKey]);
    var renderInput = React.useCallback(function (params) {
        return (React.createElement(TextField, __assign({}, params, { name: name, label: label, fullWidth: true, 
            // variant={'standard'}
            InputLabelProps: labelProps, error: !!error && !disabled, 
            // size={'small'}
            // inputRef={inputRef}
            InputProps: __assign(__assign({}, params.InputProps), { 
                // readOnly: readonly,
                endAdornment: (React.createElement(React.Fragment, null,
                    loading ? (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(CircularProgress, { color: "inherit", size: 20, disableShrink: true }))) : params.InputProps.endAdornment,
                    React.createElement(ErrorAdornment, { message: error || (loadError === null || loadError === void 0 ? void 0 : loadError.message), open: active }))) }) }, input)));
    }, [active, disabled, error, input, label, labelProps, loadError === null || loadError === void 0 ? void 0 : loadError.message, loading, name]);
    var hChange = React.useCallback(function (_, newValue) {
        handleChange(newValue);
    }, [handleChange]);
    return (React.createElement(Autocomplete, { id: name, filterOptions: filterOptions, disableListWrap: true, readOnly: readonly, 
        // autoHighlight
        // autoSelect
        loadingText: '', 
        // defaultValue={{[dictKey]: value}}
        value: initialValue, onChange: hChange, isOptionEqualToValue: getOptionSelected, getOptionLabel: getOptionLabel, 
        // disablePortal
        renderOption: renderOption, options: opts, loading: loading, disabled: disabled, freeSolo: true, renderInput: renderInput }));
};
export default AutocompleteInput;
