var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createEvent, createStore, combine, attach } from 'effector';
import { reshape, format, every } from 'patronum';
import { fetchFx } from '../fetch';
import { $token } from '../authenticate';
import connectLocalStorage from 'effector-localstorage';
export var loadFx = attach({
    effect: fetchFx,
    source: $token,
    mapParams: function (_, token) {
        var query = 'query($token: String) { query:UserResources (jwt : $token) { ...on StringBox { value } ...on Error { name message } } }';
        var variables = { token: token };
        return { query: query, variables: variables };
    },
});
export var setUser = createEvent('@user/update');
export var changeUser = createEvent('@user/change');
export var setUserResources = createEvent('@user/resources/update');
export var setUserInfo = createEvent('@user/info/update');
export var userLocalStorage = connectLocalStorage('user');
export var $userStore = createStore(userLocalStorage.init({ info: {}, resources: [], loginTime: 0 }), {
    name: '@user',
});
export var $userInfo = (_a = reshape({
    source: $userStore,
    shape: {
        $userInfo: function (store) { return store.info; },
        $granted: function (store) { return store.resources.filter(function (_a) {
            var Type = _a.Type;
            return Type === 'Form';
        }).map(function (_a) {
            var Data = _a.Data;
            return Data;
        }); },
        $loginTime: function (store) { return store.loginTime; },
        $isAdmin: function (store) { var _a; return ((_a = store.info.roles) === null || _a === void 0 ? void 0 : _a.includes('sa')) || false; },
    },
}), _a.$userInfo), $granted = _a.$granted, $loginTime = _a.$loginTime, $isAdmin = _a.$isAdmin;
// export const $userInfo = $userStore.map(({ info }) => info);
// export const $userResourcesForms = $userStore.map(({ resources }) => resources.filter(({ Type }) => Type === 'Form'));
// export const $userInfo = createStore<Partial<UserInfo>>({}, { name: '@user/info' });
// export const $userResources = createStore<Resource[]>([], { name: '@user/resources' });
var _b = reshape({
    source: $userInfo,
    shape: {
        name: function (info) { var _a; return (_a = info.name) !== null && _a !== void 0 ? _a : ''; },
        middlename: function (info) { var _a; return (_a = info.middlename) !== null && _a !== void 0 ? _a : ''; },
        surname: function (info) { var _a; return (_a = info.surname) !== null && _a !== void 0 ? _a : ''; },
        $expiredTime: function (info) { return Number(info['valid_to']) * 1000; },
        guid: function (info) { var _a; return (_a = info.GUID) !== null && _a !== void 0 ? _a : ''; },
        org: function (info) { var _a, _b; return ({ guid: (_a = info.org) !== null && _a !== void 0 ? _a : '', inn: (_b = info.orginn) !== null && _b !== void 0 ? _b : '' }); },
        thumbprint: function (info) { return info.thumbprint; },
        login: function (info) { return info.login; },
    },
}), name = _b.name, middlename = _b.middlename, surname = _b.surname, $expiredTime = _b.$expiredTime, user = __rest(_b, ["name", "middlename", "surname", "$expiredTime"]);
var $fullName = format(templateObject_1 || (templateObject_1 = __makeTemplateObject(["", " ", ""], ["", " ", ""])), name, surname);
var $shortName = combine({ name: name, surname: surname, middlename: middlename }, function (_a) {
    var name = _a.name, surname = _a.surname, middlename = _a.middlename;
    return "".concat(surname, " ").concat([name[0], middlename[0]].filter(function (char) { return char; }).join('. '), ".");
});
// const $guid = $userInfo.map(({ GUID }) => GUID ?? '');
// export const $granted = $userResourcesForms.map((resources) => resources.map(({ Data }) => Data));
var _c = reshape({
    source: $granted,
    shape: {
        $forms: function (list) {
            return list.map(function (Data) { return ({
                // title,
                name: Data.split('/').reverse()[0],
                folder: Data.split('/').reverse()[1] || undefined,
            }); });
        },
        $folders: function (list) {
            return list.reduce(function (acc, Data) {
                var value = Data.split('/').reverse()[1];
                if (value) {
                    return __spreadArray(__spreadArray([], __read(acc), false), [value], false);
                }
                return acc;
            }, []);
        },
    },
}), $forms = _c.$forms, $folders = _c.$folders;
var $userForms = $forms.map(function (forms) {
    var output = new Map();
    forms.forEach(function (item) {
        if (item.folder) {
            if (output.has(item.folder)) {
                var folder = output.get(item.folder);
                folder.children.push(item);
                output.set(item.folder, folder);
            }
            else {
                output.set(item.folder, { type: 'folder', name: item.folder, children: [item] });
            }
        }
        else {
            output.set(item.name, __assign(__assign({}, item), { type: 'item' }));
        }
    });
    return __spreadArray([], __read(output.values()), false);
});
export var $expiredSession = combine($loginTime, $expiredTime, function (loginTime, expiredTime) {
    var now = new Date().getTime();
    return now - loginTime > expiredTime - loginTime;
});
export var $hasAuthorization = every({
    predicate: function (value) { return value > 0; },
    stores: [$expiredTime, $loginTime],
});
export var $user = combine(__assign({ forms: $userForms, granted: $granted, folders: $folders, 
    // expiredSession: $expiredSession,
    fullName: $fullName, shortName: $shortName, admin: $isAdmin }, user));
var templateObject_1;
