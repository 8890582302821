import { createStore, createEvent, createEffect } from 'effector';
import connectLocalStorage from 'effector-localstorage/sync';
export var triggerFx = createEffect('@@authorize/triggerFx');
export var setToken = createEvent('@@authorize/set-token');
export var logout = createEvent('@@authorize/logout');
export var tokenLocalStorage = connectLocalStorage('token').onChange(setToken);
export var $token = createStore(tokenLocalStorage.init(''), { name: '@@authorize' });
// export const $token = createStore<string | null>(secureLocalStorage.getItem('token') as string | null);
export var $isAuthorized = $token.map(function (token) { return !!token; });
export var trigger = createEvent('@@authorize/trigger');
// eslint-disable-next-line effector/enforce-effect-naming-convention
/* export const trigger = attach({
    source: $isAuthorized,
    effect: triggerFx,
    name: '@@authorize/trigger',
}); */
