// import {gql} from '@apollo/client';

export const REPORT = `
    query($alias: String) {
        query:Reports (Alias: $alias) {
            ...on ReportData {
                report:Data
            }
            ...on Error {
                name
                message
            }
        }
    }
`;
