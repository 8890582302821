var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { getIn } from 'final-form';
import createDecorator from 'final-form-calculate';
import Formula from 'fparser';
/**
 * @param {string[]} keys Массив свойств объекта, которые необходимо преобразовать в строку
 * @param {object} values Основные данные
 * @param {IOptions} options См описание интерфейса
 * @return {object} Возвращает преобразованный объект
 * */
export var prepareVariables = function (keys, values, options) {
    if (keys === void 0) { keys = []; }
    if (values === void 0) { values = {}; }
    if (options === void 0) { options = {}; }
    var _a = options.convert, convert = _a === void 0 ? {} : _a;
    if (!keys.length)
        return values;
    return Object.fromEntries(Object.entries(values).map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        if (keys.includes(key)) {
            var objectItems_1 = convert[key];
            if (Array.isArray(objectItems_1) && objectItems_1.length > 1 && Array.isArray(value)) {
                var object = Object.fromEntries(value.map(function (item) { return [item[objectItems_1[0]], item[objectItems_1[1]]]; }));
                return [key, JSON.stringify(object)];
            }
            return [key, JSON.stringify(value)];
        }
        return [key, value];
    }));
};
/**
 * @param {string[]} keys Массив свойств объекта, которые необходимо преобразовать из строки
 * @param {object} values Основные данные
 * @param {object} options См описание интерфейса
 * @return {object} object Возвращает преобразованный объект
 * */
export var prepareValues = function (keys, values, options) {
    if (keys === void 0) { keys = []; }
    if (values === void 0) { values = {}; }
    if (options === void 0) { options = {}; }
    if (!keys.length)
        return values;
    return Object.fromEntries(Object.entries(values).map(function (_a) {
        var _b, _c, _d, _e, _f, _g, _h;
        var _j = __read(_a, 2), key = _j[0], value = _j[1];
        if (keys.includes(key)) {
            value !== null && value !== void 0 ? value : (value = (_b = options.defaultValues) === null || _b === void 0 ? void 0 : _b[key]);
            if (typeof value === 'string') {
                try {
                    var v = JSON.parse(value);
                    if (v != null) {
                        var arrayItems_1 = (_d = (_c = options.convert) === null || _c === void 0 ? void 0 : _c[key]) !== null && _d !== void 0 ? _d : [];
                        if (arrayItems_1.length > 1 && v) {
                            var temp = Array.isArray(v)
                                ? v
                                : v && typeof v === 'object'
                                    ? Object.entries(v)
                                    : [];
                            var array = temp.map(function (entries) {
                                var _a;
                                return (_a = {},
                                    _a[arrayItems_1[0]] = entries[0],
                                    _a[arrayItems_1[1]] = entries[1],
                                    _a);
                            });
                            return [key, array];
                        }
                        return [key, v];
                    }
                    return [key, (_f = (_e = options.defaultValues) === null || _e === void 0 ? void 0 : _e[key]) !== null && _f !== void 0 ? _f : null];
                }
                catch (_k) {
                    return [key, (_h = (_g = options.defaultValues) === null || _g === void 0 ? void 0 : _g[key]) !== null && _h !== void 0 ? _h : null];
                }
            }
            return [key, value];
        }
        return [key, value];
    }));
};
export var isEqual = function (a, b) {
    if (a === b) {
        return true;
    }
    if (!a || !b) {
        return false;
    }
    if (typeof b !== 'object' || !b) {
        if (!a) {
            return false;
        }
        else if (typeof a === 'object') {
            return true;
        }
        return false;
    }
    var keysA = Object.keys(a);
    var keysB = Object.keys(b);
    if (keysA.length && !keysB.length) {
        return true;
    }
    return JSON.stringify(a) === JSON.stringify(b);
};
export var checkIsFieldHidden = function (hidden, options, value) {
    var _a, _b;
    if (options.type === 'object') {
        return (!!hidden &&
            !(options.required ||
                ((_a = options.fields) === null || _a === void 0 ? void 0 : _a.some(function (field) { return field.required || !!getIn(value || {}, "".concat(field.name)); }))));
    }
    if (options.type === 'array') {
        return (!!hidden &&
            !(options.required ||
                ((_b = options.fields) === null || _b === void 0 ? void 0 : _b.some(function (field) {
                    var _a;
                    return field.required ||
                        !!((_a = value) !== null && _a !== void 0 ? _a : []).find(function (item) { var _a; return item && typeof item === 'object' ? item[(_a = field.name) !== null && _a !== void 0 ? _a : ''] : undefined; });
                }))));
    }
    return options.type !== 'divider' ? !!hidden && !(options.required || !!value) : true;
};
var getFieldsWithFormula = function (fields, prefix) {
    if (prefix === void 0) { prefix = ''; }
    return fields
        .map(function (field) {
        var _a;
        return (__assign(__assign({}, field), { name: prefix ? "".concat(prefix, ".").concat(field.name) : (_a = field.name) !== null && _a !== void 0 ? _a : '' }));
    })
        .filter(function (field) {
        return !(field.type === 'divider' || field.type === 'array') && 'formula' in field && field.formula;
    })
        .map(function (field) {
        return field.type === 'object' && Array.isArray(field.fields)
            ? getFieldsWithFormula(field.fields, field.name)
            : field;
    })
        .flat(1);
};
export var createCalculateDecorator = function (fields) {
    var filteredFields = getFieldsWithFormula(fields);
    var decorators = filteredFields.reduce(function (acc, field) {
        var _a;
        var fObj = new Formula((_a = field.formula) !== null && _a !== void 0 ? _a : '');
        var fieldsNames = fObj.getVariables();
        if (!fieldsNames.length) {
            return acc;
        }
        return __spreadArray(__spreadArray([], __read(acc), false), __read(fieldsNames.map(function (fieldName) { return ({
            field: fieldName,
            updates: function (value, field1, allValues) {
                var _a, _b;
                if (allValues === void 0) { allValues = {}; }
                var values = __assign(__assign({}, fieldsNames.reduce(function (acc, name) {
                    var _a;
                    var _b;
                    if (name === field1) {
                        return acc;
                    }
                    return __assign(__assign({}, acc), (_a = {}, _a[name] = (_b = getIn(allValues, name)) !== null && _b !== void 0 ? _b : 0, _a));
                }, {})), (_a = {}, _a[field1] = value, _a));
                return _b = {}, _b[field.name] = fObj.evaluate(values), _b;
            },
        }); })), false);
    }, []);
    return createDecorator.apply(void 0, __spreadArray([], __read(decorators), false));
};
// Функция для конвертирования JSON схемы в простой View (справочники)
export var convertJSONSchemaToBaseView = function (name, schema) {
    var excludeType = ['null', 'array', 'object', 'boolean'];
    var _a = schema.required, required = _a === void 0 ? [] : _a, properties = schema.properties;
    return {
        name: name,
        fields: properties
            ? Object.entries(properties).reduce(function (acc, _a) {
                var _b;
                var _c = __read(_a, 2), name = _c[0], options = _c[1];
                if (typeof options === 'boolean') {
                    return acc;
                }
                var type = (_b = (Array.isArray(options.type) ? options.type.filter(function (t) { return t !== 'null'; }) : [options.type])[0]) !== null && _b !== void 0 ? _b : 'string';
                if (excludeType.includes(type)) {
                    return acc;
                }
                switch (type) {
                    case 'integer':
                    case 'number':
                        return __spreadArray(__spreadArray([], __read(acc), false), [
                            {
                                name: name,
                                type: type,
                                size: 'full',
                                required: required.includes(name),
                            },
                        ], false);
                    default:
                        return __spreadArray(__spreadArray([], __read(acc), false), [
                            {
                                name: name,
                                type: 'text',
                                size: 'full',
                                required: required.includes(name),
                            },
                        ], false);
                }
            }, [])
            : [],
    };
};
export var isValidDate = function (value) { return !isNaN(new Date(value).getTime()); };
var isObjectSchema = function (schema) { return 'fields' in schema; };
var isNumberOrStringSchema = function (schema) {
    return schema.type === 'string' || schema.type === 'number';
};
function deepPartial(schema) {
    var e_1, _a;
    if (isObjectSchema(schema)) {
        var partial = {};
        try {
            for (var _b = __values(Object.entries(schema.fields)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), key = _d[0], fieldSchema = _d[1];
                partial[key] = deepPartial(fieldSchema);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return schema.shape(partial);
    }
    if (schema.type === 'array') {
        var nextArray = schema.optional();
        if (nextArray.innerType)
            nextArray.innerType = deepPartial(nextArray.innerType);
        return nextArray;
    }
    if (schema.type === 'tuple') {
        return schema.optional().clone({ types: schema.spec.types.map(deepPartial) });
    }
    if ('optional' in schema) {
        return schema.optional();
    }
    return schema;
}
export var withMutation = function (schema) {
    var e_2, _a;
    var shape = new Map();
    try {
        for (var _b = __values(Object.entries(schema.fields)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), key = _d[0], item = _d[1];
            var field = item;
            // const field = (item as Schema).clone();
            if (isNumberOrStringSchema(field)) {
                var required = !field.isType(null);
                if (required) {
                    // @ts-expect-error
                    field.exclusiveTests = __assign(__assign({}, field.exclusiveTests), { required: false });
                    field.spec = __assign(__assign({}, field.spec), { nullable: true, default: null });
                    shape.set(key, deepPartial(field.clone({ nullable: true, default: null }))
                        .nullable()
                        .default(null));
                    // .clone()
                    // .withMutation((next) => next.nullable().default(null));
                }
            }
            if (isObjectSchema(field)) {
                shape.set(key, withMutation(field.clone()));
            }
            shape.set(key, field);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_2) throw e_2.error; }
    }
    console.log(shape);
    return schema.shape(Object.fromEntries(__spreadArray([], __read(shape.entries()), false)));
};
