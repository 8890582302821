var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _a;
import { createStore, createEvent, attach } from 'effector';
import { snapshot, combineEvents, reshape } from 'patronum';
import connectLocalStorage from 'effector-localstorage';
import { fetchFx } from '../fetch';
import { createMutationRequest, createQueryRequest } from '@alta-m1/client';
import { logout } from '../authenticate';
//эффект для запрашивания настроек пользователя с сервера
export var loadFx = attach({
    effect: fetchFx,
    mapParams: function () {
        var query = createQueryRequest({
            name: 'user-settings',
            params: [{ field: 'Domain', value: window.location.origin }],
            fields: ['ParamName', 'ParamValue'],
        });
        return { query: query };
    },
    name: '@@settings/load',
});
//сохраняет настройки пользователя на сервере
export var saveFx = attach({
    effect: fetchFx,
    mapParams: function (variables) {
        var query = createMutationRequest({ name: 'user-settings' });
        return { query: query, variables: variables };
    },
    name: '@@settings/save',
});
export var updateSettings = createEvent('@@settings/update');
// export const updateSnapshots = createEvent('@@settings/update-snapshots');
export var changeSettings = createEvent('@@settings/change');
export var settingsLocalStorage = connectLocalStorage('user-settings'); // .onChange(updateSettings);
export var $settings = createStore(settingsLocalStorage.init({ printers: {}, print: {}, customization: {}, certificate: {}, serializers: {} }), {
    name: '@@settings',
});
//совмещает для события: как только загрузились настройки с сервера, то обновляем хранилице $settings
var updateSnapshots = combineEvents({
    events: {
        first: loadFx.done,
        second: $settings.updates,
    },
    reset: logout,
});
export var $customization = snapshot({
    clock: updateSnapshots,
    source: $settings,
    fn: function (_a) {
        var customization = _a.customization;
        if (customization != null) {
            return customization;
        }
        return {};
    },
});
export var updateCustomization = createEvent('@@customization/update');
export var updateCustomizationItem = createEvent('@@customization/item-update');
export var $certificate = snapshot({
    clock: updateSnapshots,
    source: $settings,
    fn: function (_a) {
        var certificate = _a.certificate;
        if (certificate === null || certificate === void 0 ? void 0 : certificate.mode) {
            return certificate;
        }
        return { mode: 'user', authorize: 'hash' };
    },
});
export var updateCertificateSettings = createEvent('@@certificate-settings/update');
//достаем принтеры из $settings. Будет срабатывать после updateSnapshots
export var $printers = snapshot({
    clock: updateSnapshots,
    source: $settings,
    fn: function (_a) {
        var printers = _a.printers;
        if (printers != null && 'list' in printers) {
            return printers;
        }
        return {
            list: {
                'default-local': {
                    id: 'default-local',
                    name: 'Default local printer',
                    type: 'local',
                    description: 'Принтер, который выбран по-умолчанию в сервисе управления принтерами',
                },
            },
            default: 'default-local',
        };
    },
});
var getAddress = function (self) {
    var _a, _b;
    if (self.type === 'local') {
        var address = 'http://127.0.0.1:9999';
        if (self.id === 'default-local') {
            return address;
        }
        try {
            return encodeURI("".concat(address, "/").concat(self.name));
        }
        catch (_c) {
            return '';
        }
    }
    var match = (_a = self.ip) === null || _a === void 0 ? void 0 : _a.match(/^(?<proto>http(s)?:\/\/)?(?<ip>(\d{1,3}.\d{1,3}.\d{1,3}.\d{1,3})|localhost):?(?<port>\d+)?$/);
    if (match) {
        var _d = match.groups, _e = _d.proto, proto = _e === void 0 ? 'http://' : _e, ip = _d.ip, _f = _d.port, port = _f === void 0 ? '9100' : _f;
        return proto + ip + ':' + port;
    }
    return (_b = self.ip) !== null && _b !== void 0 ? _b : '';
};
var getHeaders = function (self) {
    if (self.type === 'local') {
        var headers = new Headers({
            'Content-Type': 'x-application/zpl',
        });
        if (self.id !== 'default-local') {
            // eslint-disable-next-line no-control-regex
            headers.append('Device', self.name.replace(/[^\x00-\x7F]/g, ''));
        }
        return headers;
    }
    return undefined;
};
export var $printersList = (_a = reshape({
    source: $printers,
    shape: {
        $printersList: function (_a) {
            var list = _a.list;
            return new Map(Object.entries(list).map(function (_a) {
                var _b, _c, _d;
                var _e = __read(_a, 2), key = _e[0], value = _e[1];
                var printer = __assign(__assign({}, value), { timeout: (_b = value.timeout) !== null && _b !== void 0 ? _b : 500, description: (_c = value.description) !== null && _c !== void 0 ? _c : '-', ip: (_d = value.ip) !== null && _d !== void 0 ? _d : '', address: getAddress(value), headers: getHeaders(value) });
                return [key, printer];
            }));
        },
        $printersLabels: function (_a) {
            var list = _a.list;
            return Object.values(list).map(function (_a) {
                var id = _a.id, name = _a.name;
                return ({ label: name, value: id });
            });
        },
    },
}), _a.$printersList), $printersLabels = _a.$printersLabels;
export var updatePrinter = createEvent('@@printers/update');
export var deletePrinter = createEvent('@@printers/delete');
export var setDefaultPrinter = createEvent('@@printers/set-default');
export var $defaultPrinter = reshape({
    source: $printers,
    shape: {
        $defaultPrinter: function (printers) {
            var _a, _b, _c;
            var value = printers.list[printers.default || ''];
            if (value) {
                return __assign(__assign({}, value), { timeout: (_a = value.timeout) !== null && _a !== void 0 ? _a : 500, description: (_b = value.description) !== null && _b !== void 0 ? _b : '-', ip: (_c = value.ip) !== null && _c !== void 0 ? _c : '', address: getAddress(value), headers: getHeaders(value) });
            }
            return null;
        },
    },
}).$defaultPrinter;
var makePrintSettings = function (value) {
    return {
        regExp: value['regExp'] || '^\\d{13}$',
        dmRegExp: value['dmRegExp'] || '^(01)\\d{14}(21)\\S{13}(91|240)\\S*(==)$',
        parseDmRegExp: value['parseDmRegExp'] || '^01(?<gtin>\\d{14})21(?<isn>\\S{13})(91|240)\\S*$',
        ssccExp: value['ssccExp'] || '^00(\\d{18})$',
    };
};
export var $print = snapshot({
    clock: updateSnapshots,
    source: $settings,
    fn: function (_a) {
        var print = _a.print;
        if (print != null && Object.keys(print).length > 0) {
            return makePrintSettings(print);
        }
        return makePrintSettings({});
    },
});
export var updateSerializer = createEvent('@@serializer/update');
export var deleteSerializer = createEvent('@@serializer/delete');
export var setDefaultSerializer = createEvent('@@serializer/set-default');
export var $serializers = snapshot({
    clock: updateSnapshots,
    source: $settings,
    fn: function (_a) {
        var serializers = _a.serializers;
        if (serializers != null && 'list' in serializers) {
            return serializers;
        }
        return {
            list: {
                'default-serializer': {
                    id: 'default-serializer',
                    name: 'Default local serializer',
                    description: 'serializer, который выбран по-умолчанию в сервисе управления принтерами',
                },
            },
            default: 'default-local',
        };
        // console.log('serializer MODELS!!!!', serializers);
        return serializers;
    },
});
export var $serializersList = reshape({
    source: $serializers,
    shape: {
        $serializersList: function (_a) {
            var list = _a.list;
            if (list == null)
                return new Map();
            return new Map(Object.entries(list).map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return [key, value];
            }));
        },
    }
}).$serializersList;
