import { createStore, createEvent, createEffect, combine, } from 'effector';
import { pending } from 'patronum';
import connectLocalStorage from 'effector-localstorage/sync';
// type TranslateKeys = typeof translateKeys[number];
export var addTranslates = createEvent('@@i18n/translates/add');
export var setLanguage = createEvent('@@i18n/language/set');
export var languageLocalStorage = connectLocalStorage('language')
    .onChange(setLanguage);
var initLanguage = languageLocalStorage.init('ru');
export var $language = createStore(initLanguage, { name: '@@i18n/language' });
export var $translates = createStore({}, { name: '@@i18n/translates' });
export var $i18n = combine({ language: $language, translates: $translates });
export var $currentTranslate = $i18n.map(function (_a) {
    var language = _a.language, translates = _a.translates;
    return translates[language];
});
export var setInitLanguage = setLanguage.prepend(function () { return initLanguage; });
export var loadTranslatesFx = createEffect('@@i18n/translates/load');
export var $loadingTranslate = pending({ effects: [loadTranslatesFx] });
