import React from 'react';
import {
    WithTitle,
    AtomicFormWrapper as FormWrapper,
} from '@alta-m1/pages';

const ViewWrapper = ({ mode, title, children }: React.PropsWithChildren<{ mode: string | undefined; title: string | undefined; }>) => {
    if (mode === 'list' && title) {
        return (
            <WithTitle title={title}>
                {children}
            </WithTitle>
        );
    }
    if (mode === 'form' || mode === 'page') {
        return (
            <FormWrapper>
                {children}
            </FormWrapper>
        );
    }
    return <>{children}</>;
};

export default ViewWrapper;