var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createHistoryRouter, createRouterControls, 
// RouteInstance,
// chainRoute,
redirect, } from 'atomic-router';
import { sample, createStore } from 'effector';
import { createBrowserHistory } from 'history';
import { notFoundRoute, forbiddenRoute, lockedRoute, developmentRoute, whoopsRoute } from './common/routes';
export var routes;
var defaultRoutes = [
    { path: '/403', route: forbiddenRoute },
    { path: '/423', route: lockedRoute },
    { path: '/000', route: developmentRoute },
    { path: '/404', route: notFoundRoute },
];
export * as commonRoutes from './common/routes';
export var setRoutes = function (newRoutes) { return (routes = newRoutes); };
export var getRoutes = function () { return (routes === null || routes === void 0 ? void 0 : routes.map(function (_a) {
    var path = _a.path, route = _a.route;
    return ({ path: path, route: route });
}).concat(defaultRoutes)) || defaultRoutes; };
export var getRouteByName = function (name) { var _a; return (_a = routes === null || routes === void 0 ? void 0 : routes.find(function (route) { return route.name === name; })) === null || _a === void 0 ? void 0 : _a.route; };
export var controls = createRouterControls();
// const routes = getRoutes();
export var router;
var history = createBrowserHistory();
var setRedirects = function (options) {
    var redirectOnLogout = options.redirectOnLogout;
    if (redirectOnLogout) {
        var route = redirectOnLogout.route, event_1 = redirectOnLogout.event, path = redirectOnLogout.path;
        var instance = typeof route === 'string' ? getRouteByName(route) : route;
        if (!instance)
            return;
        var $name = createStore(path.replaceAll('/', ''));
        redirect({
            clock: sample({
                clock: event_1,
                source: { path: router.$path, query: router.$query, name: $name },
                fn: function (_a) {
                    var path = _a.path, query = _a.query, name = _a.name;
                    var from = path.replace(name, '').replaceAll(/^\/?[[404]|[403]|[000]|[423]]?/gi, '');
                    return from ? __assign({ from: from }, query) : query;
                },
            }),
            route: instance,
            query: function (query) { return query; },
            replace: true,
        });
    }
};
export var createRouter = function (routes, options) {
    setRoutes(routes);
    router = createHistoryRouter({
        routes: routes.concat(defaultRoutes),
        controls: controls,
        notFoundRoute: whoopsRoute,
    });
    router.setHistory(history);
    if (options)
        setRedirects(options);
    return router;
};
export var getRouter = function () { return router; };
/* export const router = createHistoryRouter({
    routes,
    controls,
    notFoundRoute,
}); */
// const history = createBrowserHistory();
// router.setHistory(history);
/* export const chainPermissioned = <Params extends AnyObject = AnyObject, T = string>(route: RouteInstance<Params>, name: T) => {
    return chainRoute({
        route,
        beforeOpen: {
            effect: checkPermissionFx,
            mapParams: () => name,
        },
        openOn: checkPermissionFx.doneData,
    });
}; */
export function forbidden(event) {
    sample({
        clock: event,
        target: redirect({
            // clock: checkPermissionFx.failData,
            route: forbiddenRoute,
            replace: true,
            query: {},
        }),
    });
}
export function locked(event) {
    sample({
        clock: event,
        target: redirect({
            // clock: checkPermissionFx.failData,
            route: lockedRoute,
            replace: true,
            query: {},
        }),
    });
}
export * from './hooks';
