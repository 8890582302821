import { 
// useStoreMap,
useStore, } from 'effector-react';
import objectPath from 'object-path';
import { 
// $i18n,
$currentTranslate, } from '.';
export var useI18n = function () {
    var translate = useStore($currentTranslate);
    /* const translate = useStoreMap<AddTranslates, Translates, any[]>({
        store: $i18n,
        keys: [],
        fn({language, translates}) {
            return translates[language];
        },
        defaultValue: {},
    }); */
    var getTranslate = function (path, type) {
        if (type === void 0) { type = 'interface'; }
        if (typeof path === 'string') {
            path = path.replace(/\[\d+]/g, '');
        }
        return objectPath.get(translate[type], path) || '';
    };
    return [getTranslate];
};
