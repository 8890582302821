var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { sample } from 'effector';
import { loadFx, $store as $organization, updateOrganization } from '.';
import { triggerFx, logout } from '../authenticate';
import { $loadingModels } from '../config';
$organization
    .on(updateOrganization, function (state, payload) { return (__assign(__assign({}, state), payload)); })
    .reset(logout);
sample({
    clock: triggerFx.done,
    source: $loadingModels,
    filter: function (models, _a) {
        var result = _a.result;
        return models.includes('organization') && result;
    },
    target: loadFx,
});
sample({
    clock: loadFx.doneData,
    filter: function (_a) {
        var data = _a.data;
        return data.query.message == null;
    },
    fn: function (_a) {
        var _b;
        var data = _a.data;
        var _c = data.query.list, list = _c === void 0 ? [] : _c;
        var payload = (_b = list[0]) !== null && _b !== void 0 ? _b : {};
        return payload;
    },
    target: updateOrganization,
});
