var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { createCustomDictionaryQuery, createSystemDictionaryQuery, } from '@alta-m1/client';
import parse from 'autosuggest-highlight/parse';
import { Chip, CircularProgress, Grid, InputAdornment, ListSubheader, TextField, Autocomplete, ListItem, Icon, } from '@mui/material';
import { ErrorAdornment, useDictionary, } from '@alta-m1/form';
import { HighlightedText } from '@alta-m1/components/styled-mui-components';
import { 
// useCombinedRefs,
useScanner, useWhenValueChanges, useLazyFetch, } from '@alta-m1/hooks';
import { match, sorter } from '@alta-m1/helpers';
import { makeStyles } from '@mui/styles';
import { useI18n } from '@alta-m1/models/hooks';
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        option: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            // 'fontSize': 15,
            '& > div': {
                // marginRight: 10,
                fontSize: theme.typography.pxToRem(13),
            },
        },
        paper: (_a = {
                // width: '100%',
                borderRadius: 0,
                boxShadow: theme.shadows[3],
                // minWidth: 300,
                width: function (_a) {
                    var count = _a.count;
                    return count > 3 ? 600 : (count === 3 ? 450 : '100%');
                },
                maxWidth: '100%'
            },
            /*[theme.breakpoints.down('xl')]: {
                maxWidth: '100%',
            },*/
            _a[theme.breakpoints.up('md')] = {
                maxWidth: 700,
            },
            _a),
        popper: {
            width: '100%',
        },
        listbox: __assign({ width: '100%', paddingTop: function (_a) {
                var useTitles = _a.useTitles;
                return useTitles ? 0 : undefined;
            }, paddingBottom: function (_a) {
                var useTitles = _a.useTitles;
                return useTitles ? 0 : undefined;
            }, 
            /*'&': ({useTitles}) => useTitles ? {
                paddingTop: 0,
                paddingBottom: 0,
            } : undefined,*/
            // minWidth: 300,
            // maxWidth: 700,
            /*[theme.breakpoints.down('sm')]: {
                maxWidth: 400,
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: 700,
            },*/
            overflow: 'auto' }, theme.getScrollbarStyles(1.5)),
        inputRoot: {
            flexWrap: 'nowrap',
        },
    });
});
var Titles = function (_a) {
    var group = _a.group;
    /*const {ref} = useResizeDetector({
        // handleWidth: false,
    });*/
    var titles = group.split('|');
    var count = titles.length;
    var width = count > 3 ? ((600 / count) - 4) : (count === 3 ? 140 : "calc(100% / ".concat(count, " - 10)"));
    return (React.createElement(ListSubheader
    // ref={ref}
    , { 
        // ref={ref}
        component: "div", sx: {
            '&': {
                // position: 'fixed',
                position: 'sticky',
                top: 0,
                pt: '5px',
                pb: '5px',
                display: 'flex',
                lineHeight: 'inherit',
                alignItems: 'center',
                // alignContent: 'center',
                // justifyContent: 'space-between',
                width: 1,
                flexBasis: '100%',
                // top: 0,
                // padding: '6px 16px',
                bgcolor: 'background.paper',
                // paddingLeft: 5,
                // paddingRight: 5,
                // wordWrap: 'word-break',
                wordBreak: 'break-word',
            },
        } }, titles.map(function (option, i) { return (React.createElement(Grid, { item: true, title: option, 
        // xs
        key: i, sx: {
            // textAlign: 'center',
            width: width,
            minWidth: width,
            py: 0,
            px: .5,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            // padding: '0 5px',
            // maxWidth: width,
            // overflowWrap: 'anywhere',
            // wordWrap: 'word-break',
            // wordBreak: 'break-word',
        } }, option)); })));
};
var RenderGroup = function (props) {
    return [
        React.createElement(Titles, { key: props.key, group: props.group }),
        props.children,
    ];
};
var Dictionary = function (props) {
    var 
    // intl,
    ref = props.forwardedRef, 
    /*actions: {
        setDictValues,
    },*/
    _a = props.input, name = _a.name, value = _a.value, onChange = _a.onChange, input = __rest(_a, ["name", "value", "onChange"]), active = props.meta.active, 
    // errors,
    // required,
    _b = props.options, label = _b.label, disabled = _b.disabled, readonly = _b.readonly, _c = _b.dictionary, dictionary = _c === void 0 ? {} : _c, _d = _b.events, events = _d === void 0 ? {} : _d, _e = _b.focus, focus = _e === void 0 ? false : _e, _f = _b.useEnterKey, useEnterKey = _f === void 0 ? false : _f, labelProps = props.labelProps, error = props.error;
    var _g = dictionary.key, dictKey = _g === void 0 ? '' : _g, _h = dictionary.name, dictName = _h === void 0 ? '' : _h, _j = dictionary.view, dictView = _j === void 0 ? 'default' : _j, dictAlias = dictionary.alias, _k = dictionary.type, dictType = _k === void 0 ? 'custom' : _k, _l = dictionary.fields, dictFields = _l === void 0 ? ['GUID'] : _l, dictLabel = dictionary.label, dictAttach = dictionary.attach, dictFilters = dictionary.filters, dictVariables = dictionary.variables, _m = dictionary.modes, modes = _m === void 0 ? [] : _m, extract = dictionary.extract;
    var _o = __read(useI18n(), 1), getTranslate = _o[0];
    var _p = useDictionary(), actions = _p.actions, views = _p.views;
    var setDictValues = actions === null || actions === void 0 ? void 0 : actions.setDictValues;
    var useFilters = modes.includes('filters') || !!dictionary.useFilters;
    var useTitles = modes.includes('titles') || !!dictionary.useTitles;
    var multiple = modes.includes('multiple') || !!dictionary.multiple;
    var excludeKeyFromFields = modes.includes('excludeKey') || !!dictionary.excludeKeyFromFields;
    var useMissing = modes.includes('missing');
    // const [views, setViews] = React.useState<AnyObject>({});
    // const views = dictionaries.getDictionaryViewByName(dictName);
    // const [inputValue, setInputValue] = React.useState<string>('');
    /*const [{value: inputValue}, setInputValue] = React.useReducer((state, value) => ({
        ...state,
        value,
    }), {value: ''}, (state) => ({...state}));*/
    // const inputValue = React.useRef('');
    // const {enqueueSnackbar: notify} = useSnackbarPatched();
    var fields = React.useMemo(function () {
        var fields = new Set(excludeKeyFromFields ? dictFields.filter(function (name) { return name !== dictKey; }) : __spreadArray([dictKey], __read(dictFields), false));
        return dictAttach ? __spreadArray(__spreadArray([dictAttach + ' {'], __read(__spreadArray([], __read(fields.values()), false)), false), ['}'], false) : __spreadArray([], __read(fields.values()), false);
    }, [dictAttach, dictFields, dictKey, excludeKeyFromFields]);
    var query = React.useMemo(function () { return dictType === 'system' ? (createSystemDictionaryQuery(dictName)) : (createCustomDictionaryQuery({
        name: dictName,
        info: false,
        fields: fields,
        filters: dictFilters,
        variables: dictVariables === null || dictVariables === void 0 ? void 0 : dictVariables.params,
        useFilters: useFilters,
    })); }, [dictFilters, dictName, dictType, dictVariables === null || dictVariables === void 0 ? void 0 : dictVariables.params, fields, useFilters]);
    var variables = React.useMemo(function () {
        var _a;
        return (__assign(__assign({}, (dictType === 'system' ? {
            SearchParam: '%',
            limit: 99999,
            Alias: dictAlias || dictName,
        } : {})), ((_a = dictVariables === null || dictVariables === void 0 ? void 0 : dictVariables.values) !== null && _a !== void 0 ? _a : {})));
    }, [dictAlias, dictName, dictType, dictVariables === null || dictVariables === void 0 ? void 0 : dictVariables.values]);
    // const [data, setData] = React.useState<TData<typeof dictFields>>();
    var _q = __read(useLazyFetch({ query: query, variables: variables }), 2), getData = _q[0], _r = _q[1], data = _r.data, loading = _r.loading, loadError = _r.error;
    /*if (loadError) {
        notify(loadError.message, 'error');
    }*/
    var opts = React.useMemo(function () {
        var _a;
        if (data) {
            var temp = (_a = data[dictType].list) !== null && _a !== void 0 ? _a : [];
            var list = dictAttach && temp[0] ? temp[0][dictAttach] : temp;
            var output = typeof list === 'string' ? JSON.parse(list) : (Array.isArray(list) ? list : []);
            return extract ? output.map(function (item) {
                var data;
                try {
                    data = JSON.parse(item[extract]);
                }
                catch (_a) {
                    data = {};
                }
                return __assign(__assign({}, item), data);
            }) : output;
        }
        return [];
    }, [data, dictAttach, dictType, extract]);
    var initialValue = React.useMemo(function () {
        var _a;
        var _b;
        return multiple ? (opts.filter(function (opt) { return (value !== null && value !== void 0 ? value : []).includes(opt[dictKey]); })) : ((_b = opts.find(function (opt) { return opt[dictKey] === value; })) !== null && _b !== void 0 ? _b : (_a = {}, _a[dictKey] = value, _a));
    }, [dictKey, multiple, opts, value]);
    var mounted = React.useRef(false);
    var inputRef = React.useRef(null);
    var isSelect = React.useRef(false);
    var change = React.useRef(false);
    var changeRequired = React.useRef(false);
    var view = React.useMemo(function () { var _a, _b; return (_b = (_a = views[dictName]) === null || _a === void 0 ? void 0 : _a[dictView]) !== null && _b !== void 0 ? _b : []; }, [dictName, dictView, views]);
    var count = React.useMemo(function () { return view.length || 1; }, [view.length]);
    var classes = useStyles({ count: count, useTitles: useTitles });
    var filterOptions = React.useCallback(function (options, _a) {
        var _b;
        var inputValue = _a.inputValue;
        var filtered = sorter(options, inputValue, {
            keys: view,
            // threshold: matchSorter.rankings.CONTAINS,
        });
        var isExisting = filtered.length > 0; // options.some((option: AnyObject) => inputValue === option[dictKey]);
        if (inputValue !== '' && !isExisting && useMissing) {
            filtered.push((_b = {
                    inputValue: inputValue
                },
                _b[dictKey] = inputValue,
                _b));
        }
        return filtered;
    }, [dictKey, useMissing, view]);
    /*const handleLoad = React.useCallback(() => {
        /!*dictionaries.getDictionaryViewByName(dictName).then((view) => {
            if (mounted.current) {
                setViews((prev) => ({...prev, ...view}));
            }
            getData({variables}).then();
        });*!/
        getData({variables}).then();
    }, [getData, variables]);*/
    React.useEffect(function () {
        mounted.current = true;
        getData();
        changeRequired.current = true;
        return function () {
            mounted.current = false;
        };
    }, []); // eslint-disable-line
    useWhenValueChanges(variables, function (variables) {
        if (!loading) {
            getData(variables);
            changeRequired.current = true;
        }
    });
    useWhenValueChanges(loading, function () {
        if (!loading) {
            if (changeRequired.current) {
                changeRequired.current = false;
                if (modes.includes('initialize')) {
                    var option = value ? opts.find(function (option) { return option[dictKey] === value; }) : undefined;
                    if (option) {
                        setDictValues === null || setDictValues === void 0 ? void 0 : setDictValues(name, option, dictionary);
                    }
                }
            }
        }
    });
    var handleChange = React.useCallback(function (option, reason) {
        if (reason === 'clear' && (value === null || value === undefined || value === ''))
            return;
        isSelect.current = reason === 'select-option';
        if (multiple) {
            option !== null && option !== void 0 ? option : (option = []);
        }
        else {
            option !== null && option !== void 0 ? option : (option = {});
        }
        var newValue = multiple ? option.map(function (item) { return item[dictKey]; }) : option[dictKey];
        onChange(newValue !== null && newValue !== void 0 ? newValue : '');
        // this.setState({value});
        if (setDictValues && !(multiple || option.inputValue))
            setDictValues(name, option, dictionary);
        change.current = true;
        // inputValue.current = '';
        // if (mounted.current) setInputValue('');
        /*if (inputRef.current) {
            inputRef.current.blur();
            inputRef.current.focus();
        }*/
        // setSelect(false);
    }, [dictKey, dictionary, multiple, name, onChange, setDictValues, value]);
    var handleClick = React.useCallback(function () {
        var _a, _b;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
    }, []);
    var onEnter = React.useCallback(function (value) {
        var _a;
        if (!isSelect.current && value && useEnterKey) {
            handleChange((_a = {}, _a[dictKey] = value, _a), 'enter-key');
            handleClick();
            // onEnterDown(inputValue);
            // setInputValue('');
        }
    }, [dictKey, handleChange, useEnterKey, handleClick]);
    var _s = useScanner({ onEnter: onEnter }), scannerEvents = _s.events, inputValue = _s.value, reset = _s.reset;
    React.useImperativeHandle(ref, function () { return ({
        update: function () {
            getData();
        },
        clear: function () {
            reset();
            // inputValue.current = '';
        },
        focus: function () {
            var _a;
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        },
        click: handleClick,
    }); });
    React.useEffect(function () {
        if (change.current) {
            change.current = false;
            isSelect.current = false;
        }
    });
    var groupBy = function () {
        return view.map(function (option) { return getTranslate(option, 'fields'); }).join('|');
    };
    var width = React.useMemo(function () { return count > 3 ? ((600 / count) - 4) : (count === 3 ? 140 : "calc(100% / ".concat(count, " - 10)")); }, [count]);
    var getOptionSelected = React.useCallback(function (option, value) { return option[dictKey] === value[dictKey]; }, [dictKey]);
    var getOptionLabel = React.useCallback(function (option) {
        var _a;
        if (option && typeof option === 'object') {
            if (option.inputValue) {
                return option.inputValue;
            }
            return (_a = option[dictLabel || dictKey]) !== null && _a !== void 0 ? _a : '';
        }
        return option;
    }, [dictKey, dictLabel]);
    var renderOption = React.useCallback(function (props, option, _a) {
        var _b;
        var inputValue = _a.inputValue;
        return (React.createElement(ListItem, __assign({}, props, { key: (_b = option['GUID']) !== null && _b !== void 0 ? _b : option[dictKey] }), !option.inputValue ? view.map(function (optionName, i) {
            var value = option[optionName] ? option[optionName].toString() : '';
            var matches = match(value, inputValue);
            var parts = parse(value, matches);
            var key = optionName + '_' + i;
            return (React.createElement(Grid, { item: true, xs: true, key: key, sx: {
                    py: 0,
                    px: .5,
                    overflowWrap: 'anywhere',
                    // wordWrap: 'word-break',
                    wordBreak: 'break-word',
                    width: width,
                    minWidth: width,
                } }, parts.map(function (part, index) { return (React.createElement(HighlightedText, { key: index, text: part.text, highlight: part.highlight })); })));
        }) : (React.createElement(Grid, { item: true, xs: 12, sx: {
                py: 0,
                pr: .5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
            } },
            React.createElement(Icon, { style: { fontSize: 20, marginRight: 4 } }, "add_circle_outline"),
            option[dictKey]))));
    }, [dictKey, view, width]);
    var renderInput = React.useCallback(function (params) {
        return (React.createElement(TextField, __assign({}, params, { name: name, label: label }, events, { fullWidth: true, 
            // variant={'standard'}
            InputLabelProps: labelProps, error: !!error && !disabled, 
            // size={'small'}
            inputRef: inputRef, inputProps: __assign({}, params.inputProps), InputProps: __assign(__assign({}, params.InputProps), { 
                // ref: useCombinedRefs(inputRef, params.InputProps.ref),
                endAdornment: loading ? (React.createElement(InputAdornment, { position: "end" },
                    React.createElement(CircularProgress, { color: "inherit", size: 20, disableShrink: true }))) : (React.createElement(React.Fragment, null,
                    params.InputProps.endAdornment,
                    React.createElement(ErrorAdornment, { message: error || (loadError && loadError.message), open: active }))) }), autoFocus: focus }, input)));
    }, [active, disabled, error, events, focus, input, label, labelProps, loadError, loading, name]);
    var onInputChange = React.useCallback(function (_, value) {
        // inputValue.current = value;
        // mounted.current && setInputValue(value);
        scannerEvents.onChange(undefined, value);
    }, [scannerEvents]);
    var hChange = React.useCallback(function (_, newValue, reason) {
        handleChange(newValue, reason);
    }, [handleChange]);
    var renderTags = React.useCallback(function (tagValue, getTagProps) {
        return tagValue.map(function (option, index) {
            var _a;
            return (React.createElement(Chip, __assign({ label: (_a = option[dictLabel || dictKey]) !== null && _a !== void 0 ? _a : '' }, getTagProps({ index: index }), { size: 'small', color: 'hulk' })));
        });
    }, [dictKey, dictLabel]);
    return (React.createElement(Autocomplete, { classes: {
            option: classes.option,
            // popper: classes.popper,
            listbox: classes.listbox,
            // paper: classes.paper,
            inputRoot: classes.inputRoot,
        }, componentsProps: {
            paper: {
                sx: {
                    borderRadius: 0,
                    boxShadow: 3,
                    width: count > 3 ? 600 : (count === 3 ? 450 : 1),
                    maxWidth: { xs: '100%', md: 700 },
                    /*[theme.breakpoints.down('xl')]: {
                        maxWidth: '100%',
                    },*/
                    /*[theme.breakpoints.up('md')]: {
                        maxWidth: 700,
                    },*/
                },
            },
            popper: {
                sx: {
                    width: 1,
                },
            },
        }, id: name, multiple: multiple, renderGroup: RenderGroup, groupBy: useTitles ? groupBy : undefined, filterOptions: filterOptions, disableListWrap: true, inputValue: inputValue, onInputChange: onInputChange, 
        // count={count}
        // useTitles={useTitles}
        // autoHighlight
        // autoSelect
        readOnly: readonly, loadingText: '', 
        // defaultValue={{[dictKey]: value}}
        value: initialValue, onChange: hChange, isOptionEqualToValue: getOptionSelected, getOptionLabel: getOptionLabel, 
        // disablePortal
        renderOption: renderOption, renderTags: renderTags, options: opts, loading: loading, disabled: disabled, onKeyDown: scannerEvents.onKeyDown, renderInput: renderInput, selectOnFocus: false }));
};
var DictionaryInput = React.forwardRef(function (props, ref) { return React.createElement(Dictionary, __assign({}, props, { forwardedRef: ref })); });
export default DictionaryInput;
