import React from 'react';
import shallowEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
/* const shallowEqual: ShallowEqual = (a: any, b: any) => {
    if (a === b) {
        return true;
    }
    if (!a || !b) {
        return false;
    }
    if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length === b.length) {
            return a.every((value, i) => {
                return shallowEqual(value, b[i]);
            });
        }
        return false;
    }
    if (a && typeof a === 'object' && b && typeof b === 'object') {
        return JSON.stringify(a) === JSON.stringify(b);
    }
    return false;
}; */
var useWhenValueChanges = function (value, callback, isEqual) {
    var previous = React.useRef(value);
    React.useEffect(function () {
        if (!(isEqual || shallowEqual)(value, previous.current)) {
            callback(value);
            previous.current = cloneDeep(value);
        }
    });
};
export default useWhenValueChanges;
