import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import '@alta-m1/models/init';
import { RouterProvider } from 'atomic-router-react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import { registerInputs } from '@alta-m1/form-inputs';
import { responsiveTheme } from '@alta-m1/components/theme';
import { setInitLanguage } from '@alta-m1/models';
import { authenticate } from '@alta-m1/models/events';
import SW from '@alta-m1/components/sw';
import { createRouter } from '@alta-m1/router';

// import { route as loginRoute } from './components/pages/login-page/index';
import { routes } from './components/pages/pages';
// import { createHistoryRouter } from 'atomic-router';

// import 'effector-logger/inspector';
import { attachLogger } from 'effector-logger';


// import 'effector-logger/inspector';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import localStore from 'store';
// import Store, { getInitialState } from './store';
// import { fetchWithTimeout } from './helpers';
// import { Provider } from 'mobx-react';
// import { onSnapshot } from 'mobx-state-tree';


// const App = React.lazy(() => import('./App'));

// setLanguage(initLanguage);

/* const initialState = getInitialState();
const store = Store.create(initialState, {
    client: fetchFx,
    fetchWithTimeout,
    // authStore,
});
onSnapshot(store, (snapshot) => {
    localStore.set('store', snapshot);
}); */

// const container = document.getElementById('root') as HTMLElement;
// const root = createRoot(container);
// root.render(
//     <BrowserRouter
//     // basename={basename}
//     >
//         <StyledEngineProvider injectFirst>
//             <ThemeProvider theme={responsiveTheme}>
//                 <CssBaseline />
//                 <SW />
//                 <React.Suspense fallback={'Загрузка...'}>
//                     <App />
//                 </React.Suspense>
//             </ThemeProvider>
//         </StyledEngineProvider>
//     </BrowserRouter>,
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
/* if (process.env.NODE_ENV === 'production') {
    serviceWorkerRegistration.register();
} */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// if (import.meta.env.MODE === 'production') {
//     reportWebVitals();
// }

// registerInputs();

// trigger();


// const { trigger } = authenticate;
setInitLanguage();

let container: HTMLElement | null = null;

// Прикрепление DevTools к вашему приложению
attachLogger()

document.addEventListener('DOMContentLoaded', function () {
    if (!container) {
        const { trigger } = authenticate;
        const router = createRouter(routes, {
            // redirectOnLogout: {
            //     route: loginRoute,
            //     event: authenticate.logout,
            //     path: '/login',
            // },
        });
        setInitLanguage();
        const App = React.lazy(() => import('./App'));
        container = document.getElementById('root') as HTMLElement;
        const root = createRoot(container);
        console.log('ROUTER main', router.$activeRoutes)
        root.render(
            <BrowserRouter>
                <RouterProvider
                    router={router}
                >
                    < StyledEngineProvider injectFirst >
                        <ThemeProvider theme={responsiveTheme}>
                            <CssBaseline />
                            <SW />
                            <React.Suspense fallback={'Загрузка...'}>
                                <App />
                            </React.Suspense>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </RouterProvider>
            </BrowserRouter>
        );
        registerInputs();
        trigger();
    }
});

if (import.meta.env.MODE === 'production') {
    reportWebVitals();
}



