var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Icon, IconButton, Stack } from '@mui/material';
import { Checkbox, SelectField, TextField } from '../components';
import { useDialog } from '@alta-m1/dialstack';
import Parameters from './parameters';
import { isEmptyValue, parameters, schemaTypes } from '../utils';
import omit from 'lodash/omit';
import { useDebounce } from '@alta-m1/hooks';
var Definition = React.memo(function (_a) {
    var _b, _c;
    var _d = _a.isRoot, isRoot = _d === void 0 ? false : _d, data = _a.data, propsName = _a.name, type = _a.type, _e = _a.isChild, isChild = _e === void 0 ? false : _e, _f = _a.required, required = _f === void 0 ? false : _f, onDelete = _a.onDelete, onChangeRequired = _a.onChangeRequired, onChange = _a.onChange, index = _a.index, onChangeIndex = _a.onChangeIndex, isLast = _a.isLast, onChangeType = _a.onChangeType, onChangeName = _a.onChangeName;
    var openDialog = useDialog().openDialog;
    var _g = __read(React.useState(propsName), 2), name = _g[0], setName = _g[1];
    var mounted = React.useRef(false);
    var debouncedName = useDebounce(name, 300);
    var isNull = React.useMemo(function () { return Array.isArray(data.type) ? data.type.includes('null') : false; }, [data.type]);
    var getType = React.useCallback(function () {
        return isNull ? type : [type, 'null'];
    }, [isNull, type]);
    var handleChangeRequired = React.useCallback(function () {
        onChangeRequired === null || onChangeRequired === void 0 ? void 0 : onChangeRequired(propsName);
    }, [onChangeRequired, propsName]);
    var handleChangeValue = React.useCallback(function (key) { return function (event) {
        var _a;
        var value = event;
        if (key === 'type') {
            value = getType();
        }
        if (onChange) {
            var newData = isEmptyValue(value) ? omit(data, [key]) : __assign(__assign({}, data), (_a = {}, _a[key] = value, _a));
            onChange(newData);
        }
    }; }, [getType, onChange, data]);
    var handleChangeParams = React.useCallback(function (params) {
        if (onChange) {
            var newData = __assign(__assign({}, data), params);
            onChange(newData);
        }
    }, [data, onChange]);
    var openParams = React.useCallback(function (event) {
        if (event && 'stopPropagation' in event) {
            event.stopPropagation();
        }
        var pData = Object.fromEntries(Object.entries(data).filter(function (_a) {
            var _b = __read(_a, 1), key = _b[0];
            return Object.keys(parameters[type]).includes(key);
        }));
        openDialog('Parameters', React.createElement(Parameters, { data: pData, onChange: handleChangeParams, type: type }), 'xs');
    }, [data, openDialog, handleChangeParams, type]);
    var handleDelete = React.useCallback(function (event) {
        if (event && 'stopPropagation' in event) {
            event.stopPropagation();
        }
        onDelete === null || onDelete === void 0 ? void 0 : onDelete(propsName);
    }, [propsName, onDelete]);
    var handleChangeName = React.useCallback(function (newName) {
        setName(function () { return newName; });
    }, []);
    var disabledParams = React.useMemo(function () { return !Object.keys(parameters[type]).length; }, [type]);
    React.useEffect(function () {
        mounted.current = true;
        return function () {
            mounted.current = false;
        };
    }, []);
    React.useEffect(function () {
        if (debouncedName && propsName !== debouncedName && onChangeName && mounted.current) {
            onChangeName(propsName, debouncedName);
        }
    }, [debouncedName, name, onChangeName, propsName]);
    return (React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center', sx: { width: 1 } },
        React.createElement(Stack, { direction: 'row', spacing: 1 },
            !isRoot && isChild ? (React.createElement(TextField, { name: 'name', value: name, onChange: handleChangeName, label: 'Name' })) : null,
            React.createElement(TextField, { name: 'title', label: 'Title', value: (_b = data.title) !== null && _b !== void 0 ? _b : '', onChange: handleChangeValue('title') }),
            React.createElement(TextField, { name: 'description', label: 'Description', value: (_c = data.description) !== null && _c !== void 0 ? _c : '', onChange: handleChangeValue('description') }),
            !isRoot ? (React.createElement(React.Fragment, null,
                onChangeType ? (React.createElement(SelectField, { label: 'Type', values: schemaTypes, name: 'type', value: type, onChange: onChangeType })) : null,
                React.createElement(Stack, { spacing: .25 },
                    React.createElement("div", null,
                        React.createElement(Checkbox, { disabled: isNull || !onChangeRequired, checked: required, onChange: handleChangeRequired, label: "Required" })),
                    React.createElement("div", null,
                        React.createElement(Checkbox, { disabled: required, checked: isNull, onChange: handleChangeValue('type'), label: "AllowNULL" }))))) : null),
        React.createElement(Stack, { direction: 'row', spacing: .5 },
            isChild && typeof index === 'number' && onChangeIndex && !isRoot ? (React.createElement(React.Fragment, null,
                React.createElement(IconButton, { size: 'small', onClick: function (event) {
                        event.stopPropagation();
                        onChangeIndex(index, 'dec');
                    }, disabled: index === 0, color: 'primary' },
                    React.createElement(Icon, { fontSize: 'inherit' }, "keyboard_double_arrow_up")),
                React.createElement(IconButton, { size: 'small', onClick: function (event) {
                        event.stopPropagation();
                        onChangeIndex(index, 'inc');
                    }, disabled: isLast, color: 'primary' },
                    React.createElement(Icon, { fontSize: 'inherit' }, "keyboard_double_arrow_down")))) : null,
            React.createElement(IconButton, { color: 'primary', size: 'small', onClick: openParams, disabled: disabledParams },
                React.createElement(Icon, { fontSize: 'inherit' }, "settings")),
            isChild && onDelete ? (React.createElement(IconButton, { size: 'small', onClick: handleDelete },
                React.createElement(Icon, { fontSize: 'inherit' }, "delete"))) : null)));
});
export default Definition;
