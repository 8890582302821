var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useStore, useStoreMap } from 'effector-react';
import { fetchLogs, $logs, $session, getProvidersFx, fetchSessionFx, pingFx, $sessionStatuses, $settingsStore, $settings, } from '.';
import { useI18n } from '../hooks';
export var useIntegrationSettingsByName = function (name) {
    return useStoreMap({
        store: $settings,
        keys: [name],
        fn: function (state, _a) {
            var _b, _c;
            var _d = __read(_a, 1), name = _d[0];
            var _e = __read(name.split('.'), 2), Domain = _e[0], Name = _e[1];
            return (_c = (_b = state.find(function (item) { return item.Domain === Domain && item.Name === Name; })) === null || _b === void 0 ? void 0 : _b.Value) !== null && _c !== void 0 ? _c : '';
        },
    });
};
export var useIntegrationSettings = function () {
    return useStoreMap({
        store: $settingsStore,
        keys: [],
        fn: function (_a) {
            var values = _a.values, fields = _a.fields;
            return Object.entries(fields).reduce(function (acc, _a) {
                var _b;
                var _c = __read(_a, 2), Domain = _c[0], fields = _c[1];
                var items = Object.entries(fields).reduce(function (acc, _a) {
                    var _b;
                    var _c;
                    var _d = __read(_a, 2), key = _d[0], type = _d[1];
                    var field = values.find(function (item) { return item.Domain === Domain && item.Name === key; });
                    return __assign(__assign({}, acc), (_b = {}, _b[key] = {
                        name: "".concat(Domain, ".").concat(key),
                        type: type,
                        value: (_c = field === null || field === void 0 ? void 0 : field.Value) !== null && _c !== void 0 ? _c : '',
                    }, _b));
                }, {});
                return __assign(__assign({}, acc), (_b = {}, _b[Domain] = items, _b));
            }, {});
        },
    });
};
export var useLogs = function () {
    var logs = useStore($logs);
    return __assign(__assign({}, logs), { fetch: fetchLogs });
};
export var useSession = function () {
    var _a = __read(useI18n(), 1), getTranslate = _a[0];
    var session = useStoreMap({
        store: $session,
        keys: [],
        fn: function (session) {
            var start = session.start && !isNaN(new Date(session.start).getTime()) && session.active
                ? new Date(session.start).getTime()
                : session.now;
            var end = start + session.duration;
            return __assign(__assign({}, session), { end: end });
        },
    });
    var statuses = useStore($sessionStatuses);
    var fetch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, message, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session.connectionID) {
                        return [2 /*return*/, Promise.resolve('Instance not registered')];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetchSessionFx()];
                case 2:
                    response = _a.sent();
                    message = response.data.query.message;
                    if (message) {
                        return [2 /*return*/, Promise.resolve(message)];
                    }
                    return [2 /*return*/, Promise.resolve()];
                case 3:
                    e_1 = _a.sent();
                    return [2 /*return*/, Promise.resolve((e_1 === null || e_1 === void 0 ? void 0 : e_1.message) || String(e_1))];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var ping = function (extension) { return __awaiter(void 0, void 0, void 0, function () {
        var response, _a, value, message, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!session.active) return [3 /*break*/, 5];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, pingFx(extension)];
                case 2:
                    response = _b.sent();
                    _a = response.data.query, value = _a.value, message = _a.message;
                    if (message) {
                        return [2 /*return*/, Promise.resolve(message)];
                    }
                    if (!value) {
                        return [2 /*return*/, Promise.resolve('Unknown error!')];
                    }
                    return [2 /*return*/, Promise.resolve()];
                case 3:
                    e_2 = _b.sent();
                    return [2 /*return*/, Promise.resolve((e_2 === null || e_2 === void 0 ? void 0 : e_2.message) || String(e_2))];
                case 4: return [3 /*break*/, 6];
                case 5: return [2 /*return*/, Promise.resolve(getTranslate('labels.session.inactive'))];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var getProviders = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, message, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!session.active) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getProvidersFx()];
                case 2:
                    response = _a.sent();
                    message = response.data.query.message;
                    if (message) {
                        return [2 /*return*/, Promise.reject(message)];
                    }
                    return [2 /*return*/, Promise.resolve()];
                case 3:
                    e_3 = _a.sent();
                    return [2 /*return*/, Promise.reject((e_3 === null || e_3 === void 0 ? void 0 : e_3.message) || String(e_3))];
                case 4: return [3 /*break*/, 6];
                case 5: return [2 /*return*/, Promise.reject(getTranslate('labels.session.inactive'))];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return __assign(__assign({}, session), { fetch: fetch, ping: ping, getProviders: getProviders, statuses: statuses });
};
