var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createStore, attach, createEffect, createEvent, combine } from 'effector';
import { combineEvents, snapshot, reshape } from 'patronum';
import { fetchFx } from '../fetch';
import { logout } from '../authenticate';
export var getBalanceFx = attach({
    effect: fetchFx,
    mapParams: function (id) {
        var variables = id ? { id: id } : undefined;
        var query = 'query($id: Int) { query:GISMTBalanceRequest (GroupID: $id) { ...on StringBox { value } ... on Error { name message } } }';
        return { query: query, variables: variables };
    },
    name: '@@catalog/get-balance',
});
export var getBalanceByIdFx = attach({
    effect: getBalanceFx,
    mapParams: function (id) {
        return id;
    },
    name: '@@catalog/get-balance-id',
});
export var loadFx = createEffect('@@catalog/load');
export var updateCatalog = createEvent('@@catalog/update');
export var $store = createStore({ groups: [], groupIds: [] }, { name: '@@catalog' });
var updateSnapshot = combineEvents({
    events: {
        first: loadFx.done,
        second: $store.updates,
    },
    reset: logout,
});
export var $groups = snapshot({
    clock: updateSnapshot,
    source: $store,
    fn: function (_a) {
        var groups = _a.groups;
        return groups;
    },
});
export var updateGroupIds = createEvent('@@groupIds/update');
export var $groupIds = snapshot({
    clock: updateSnapshot,
    source: $store,
    fn: function (_a) {
        var groupIds = _a.groupIds;
        return groupIds;
    },
});
var _a = reshape({
    source: $groups,
    shape: {
        codes: function (groups) {
            return __spreadArray([], __read(new Set(groups.map(function (_a) {
                var codes = _a.codes;
                return codes !== null && codes !== void 0 ? codes : [];
            }).flat(1))), false);
        },
        excludedCodes: function (groups) {
            return __spreadArray([], __read(new Set(groups.map(function (_a) {
                var excluded = _a.excluded;
                return excluded !== null && excluded !== void 0 ? excluded : [];
            }).flat(1))), false);
        },
    },
}), codes = _a.codes, excludedCodes = _a.excludedCodes;
var balances = reshape({
    source: $groupIds,
    shape: {
        balances: function (groupIds) {
            return __spreadArray([], __read(groupIds), false).sort(function (a, b) { return a.productGroupId - b.productGroupId; });
        },
    },
}).balances;
export var $catalog = combine({
    groups: $groups,
    groupIds: $groupIds,
    codes: codes,
    excludedCodes: excludedCodes,
    balances: balances,
    loading: getBalanceFx.pending,
});
// TODO: Добавить методы для обновления баланса групп
