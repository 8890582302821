import React from 'react';
import { Typography, Stack } from '@mui/material';
var LegendInput = function (props) {
    var _a;
    var value = props.input.value, 
    // errors,
    _b = props.options, label = _b.label, options = _b.options;
    var useLabel = !!(options === null || options === void 0 ? void 0 : options.label);
    var position = (_a = options === null || options === void 0 ? void 0 : options.position) !== null && _a !== void 0 ? _a : 'left';
    var dReverse = position === 'right' || position === 'bottom';
    var orientation = position === 'left' || position === 'right' ? 'row' : 'column';
    var direction = "".concat(orientation).concat(dReverse ? '-reverse' : '');
    var bold = !!(options === null || options === void 0 ? void 0 : options.bold);
    var margin = (options === null || options === void 0 ? void 0 : options.compact) ? -1 : 0;
    // console.log(meta);
    return (React.createElement(Stack, { direction: direction, sx: { width: 1, mt: margin }, spacing: 1, alignItems: 'center' },
        useLabel ? (React.createElement(Typography, { component: 'div', variant: 'body2', sx: { color: 'grey.600' } }, label)) : null,
        React.createElement(Typography, { component: 'div', noWrap: true, sx: { fontWeight: bold ? 500 : 'inherit' }, variant: 'body2' }, value)));
};
export default LegendInput;
