import React from 'react';
// import {useQuery} from '@apollo/client';
import { Typography, Skeleton, Container } from '@mui/material';
// import {indigo, orange, red} from '@mui/material/colors';
import { REPORT } from 'src/queries';
import { useGoogleCharts, useFetch } from '@alta-m1/hooks';
import { useI18n } from '@alta-m1/models/hooks';

// Charts.register(...registerables);
interface IReport {
    Status: string | number;
    count: number;
}
// const colors = [hexToRgbA(indigo[500], 0.7), hexToRgbA(red[500], 0.7), hexToRgbA(orange[400], 0.7)];
// const colors = [`fill:${indigo[500]};fill-opacity: 0.7`, `fill:${red[500]};fill-opacity: 0.7`, `fill:${orange[500]};fill-opacity: 0.7`];
type ChartProps = { data: string | IReport[]; };
const Chart = ({ data }: ChartProps) => {
    const [getTranslate] = useI18n();
    const { google, loading } = useGoogleCharts('en', ['bar']);
    // const mounted = React.useRef(false);
    const chartEl = React.useRef<HTMLDivElement | null>(null);
    // const ctx = React.useRef<HTMLCanvasElement | null>(null);
    const bar = React.useRef<any>(null);
    const report = React.useMemo(() => {
        const array: IReport[] =
            typeof data === 'string' ? JSON.parse(data) : data;
        return array.sort((a, b) => Number(a.Status) - Number(b.Status));
    }, [data]);
    /*const chartLabels = report.map(
        ({ Status }) =>
            getTranslate(['dashboard', 'Status', Status], 'report') as string
    );*/
    const chartLabel = getTranslate('dashboard.label', 'report') as string;
    const count = report.reduce((acc, { count }) => acc + count, 0);
    const chartTitle =
        (getTranslate('dashboard.title', 'report') as string) + ': ' + count;
    React.useEffect(() => {
        if (google) {
            const data = google.visualization.arrayToDataTable([
                [chartTitle, chartLabel],
                ...report.map((item) => {
                    return [
                        getTranslate(
                            ['dashboard', 'Status', item.Status],
                            'report'
                        ) as string,
                        item.count ?? 0,
                    ];
                }),
            ]);
            const options = {
                // title: chartTitle,
                // titlePosition: 'in',
                width: '100%',
                // colors: [indigo[500], red[500], orange[400]],
                // isStacked: true,
                // dataOpacity: 0.7,
                // height: '100%',
                bar: { groupWidth: '93%' },
                legend: { position: 'none' },
                backgroundColor: 'transparent',
                chartArea: {
                    backgroundColor: 'transparent',
                },
                axes: {
                    x: {
                        0: { side: 'top', label: chartTitle }, // Top x-axis.
                    },
                },
            };
            if (!bar.current) {
                // @ts-ignore
                const chart = new google.charts.Bar(chartEl.current);
                // @ts-ignore
                chart.draw(data, google.charts.Bar.convertOptions(options));
                bar.current = chart;
            } else {
                bar.current.clearChart();
                // @ts-ignore
                bar.current.draw(
                    data,
                    // @ts-ignore
                    google.charts.Bar.convertOptions(options)
                );
            }
        }
    }, [chartLabel, chartTitle, getTranslate, google, report]);
    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <div
            ref={chartEl}
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                // maxHeight: 400,
            }}
        />
    );
};
/*<canvas id="chart" ref={ctx} />
</div>*/

const IndexPage = () => {
    const { data, error, loading } = useFetch({
        query: REPORT,
        variables: { alias: 'ReportToDashboard' },
    });
    if (loading) {
        return (
            <Skeleton
                width={'100%'}
                height={'100%'}
                variant={'rectangular'}
                animation={'wave'}
            />
        );
    }
    if (error) {
        return <Typography color={'error'}>{error.message}</Typography>;
    }
    if (data) {
        const { message, report } = data.data.query;
        if (message) {
            return <Typography color={'error'}>{message}</Typography>;
        }
        return (
            <Container fixed sx={{ height: 1 }}>
                <Chart data={report} />
            </Container>
        );
    }
    return null;
};

export default IndexPage;
