var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Button as MuiButton, CircularProgress, Icon, IconButton as MuiIconButton, ListItemButton, ListItemIcon, ListItemText, Tooltip, } from '@mui/material';
import { useI18n } from '@alta-m1/models/hooks';
var icons = {
    add: 'add_box',
    edit: 'create',
    delete: 'delete',
    upload: 'cloud_upload',
    close: 'close',
    view: 'dashboard',
    feedback: 'headset_mic',
};
var IconButton = function (_a) {
    var item = _a.item, action = _a.action, _b = _a.style, style = _b === void 0 ? {} : _b, title = _a.title, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.color, color = _d === void 0 ? 'default' : _d, _e = _a.size, size = _e === void 0 ? 'small' : _e, _f = _a.fontSize, fontSize = _f === void 0 ? 'medium' : _f;
    var icon = icons[item] || '';
    return (React.createElement(Tooltip, { title: title },
        React.createElement("div", { style: style },
            React.createElement(MuiIconButton, { size: size, onClick: action, disabled: disabled, color: color },
                React.createElement(Icon, { fontSize: fontSize, color: 'inherit' }, icon)))));
};
var ClassicButton = function (_a) {
    var item = _a.item, _b = _a.useIcon, useIcon = _b === void 0 ? false : _b, _c = _a.size, size = _c === void 0 ? 'small' : _c, title = _a.title, action = _a.action, loading = _a.loading, _d = _a.fontSize, fontSize = _d === void 0 ? 'medium' : _d, props = __rest(_a, ["item", "useIcon", "size", "title", "action", "loading", "fontSize"]);
    var icon = useIcon ? (React.createElement(Icon, { fontSize: fontSize, color: 'inherit' }, icons[item])) : (loading ? React.createElement(CircularProgress, { size: 14 }) : null);
    return (React.createElement(MuiButton, __assign({}, props, { size: size, startIcon: icon, onClick: action }), title));
};
var MenuButton = function (_a) {
    var item = _a.item, useIcon = _a.useIcon, title = _a.title, action = _a.action, _b = _a.fontSize, fontSize = _b === void 0 ? 'medium' : _b, props = __rest(_a, ["item", "useIcon", "title", "action", "fontSize"]);
    return (React.createElement(ListItemButton, __assign({}, props, { onClick: action }),
        useIcon ? React.createElement(ListItemIcon, null,
            React.createElement(Icon, { fontSize: fontSize, color: 'inherit' }, icons[item])) : null,
        React.createElement(ListItemText, { primary: title })));
};
export var Button = function (_a) {
    var type = _a.type, item = _a.item, propsTitle = _a.title, props = __rest(_a, ["type", "item", "title"]);
    // const ButtonComponent = getButton(type);
    var _b = __read(useI18n(), 1), getTranslate = _b[0];
    var title = propsTitle || getTranslate('buttons.' + item) || item;
    var rest = __assign(__assign({}, props), { title: title, item: item });
    if (type === 'icon') {
        return React.createElement(IconButton, __assign({}, rest, { type: "icon" }));
    }
    if (type === 'button') {
        return React.createElement(ClassicButton, __assign({}, rest, { type: "button" }));
    }
    if (type === 'menu') {
        return React.createElement(MenuButton, __assign({}, rest, { type: "menu" }));
    }
    return null;
};
