var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import filter from "lodash/filter";
import some from "lodash/some";
import map from "lodash/map";
export var compareArrays = function (array1, array2) {
    if (array1 === void 0) { array1 = []; }
    if (array2 === void 0) { array2 = []; }
    var count = 0;
    if (array1.length !== array2.length) {
        count += 1;
    }
    else {
        array2.forEach(function (item) {
            var index = array1.findIndex(function (obj) {
                return obj["field"] === item["field"] &&
                    obj["value"] === item["value"];
            });
            if (index === -1) {
                count += 1;
            }
        });
    }
    return Boolean(!count);
};
export var groupBy = function (array, iteratee) {
    if (!Array.isArray(array))
        return {};
    return array.reduce(function (acc, item) {
        var _a;
        var _b;
        var key = String(item[iteratee]);
        var items = (_b = acc[key]) !== null && _b !== void 0 ? _b : [];
        items.push(item);
        return __assign(__assign({}, acc), (_a = {}, _a[key] = items, _a));
    }, {});
};
export var sorter = function (source, value, options) {
    if (options === void 0) { options = {}; }
    var keys = options.keys;
    if (!Array.isArray(source))
        return source;
    return filter(source, function (item) {
        if (keys == null)
            return true;
        var values = map(keys, function (key) {
            return item[key];
        });
        return some(values, function (v) {
            if (v) {
                if (value) {
                    return String(v)
                        .toLowerCase()
                        .includes(String(value).toLowerCase());
                }
                return true;
            }
            return false;
        });
        /* return keys.map((key) => item[key]).some((v) => {
            if (v) {
                if (value) {
                    return String(v).toLowerCase().includes(String(value).toLowerCase());
                }
                return true;
            }
            return false;
        }); */
    });
};
