var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { NotifyContext } from './context';
import { Slide, Snackbar, Alert as MuiAlert, Box } from '@mui/material';
var Alert = React.forwardRef(function (props, ref) {
    return React.createElement(MuiAlert, __assign({ ref: ref, elevation: 6, variant: "filled" }, props));
});
Alert.displayName = 'Alert';
var Notify = React.memo(function (_a) {
    var duration = _a.duration, variant = _a.variant, message = _a.message, onClose = _a.onClose, close = _a.close, id = _a.id, other = __rest(_a, ["duration", "variant", "message", "onClose", "close", "id"]);
    var _b = __read(React.useState(true), 2), open = _b[0], setOpen = _b[1];
    var handleClose = React.useCallback(function (_event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }, []);
    var handleAnimationEnd = React.useCallback(function () {
        if (!open) {
            onClose(id);
        }
    }, [open, onClose, id]);
    React.useEffect(function () {
        if (close && open) {
            handleClose();
        }
    }, [close, open, handleClose]);
    return (React.createElement(Snackbar, { style: { position: 'relative', top: 0, right: 0 }, open: open, autoHideDuration: duration, onClose: handleClose, anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
        }, TransitionComponent: Slide, TransitionProps: {
            // @ts-ignore
            direction: 'left',
            onExited: handleAnimationEnd,
        } },
        React.createElement(Alert, __assign({ onClose: handleClose, severity: variant }, other), message)));
});
var NotifyContainer = React.memo(function (_a) {
    var _b = _a.notifies, notifies = _b === void 0 ? [] : _b, rest = __rest(_a, ["notifies"]);
    return (React.createElement(Box, { sx: {
            '&': {
                position: 'fixed',
                display: 'flex',
                flexDirection: 'column',
                zIndex: 'snackbar',
                right: 0,
                top: 0,
                width: 'auto',
                height: 'auto',
                p: notifies.length > 0 ? 1 : 0,
                '& > div': {
                    mb: 1.25,
                },
            },
        } }, notifies.map(function (notify) { return (React.createElement(Notify, __assign({ key: notify.id }, notify, rest))); })));
});
var defaultSnackbarOptions = {
    variant: 'info',
    duration: 5000,
};
var Provider = function (_a) {
    var children = _a.children, _b = _a.maxNotify, maxNotify = _b === void 0 ? 3 : _b;
    var _c = __read(React.useState(function () { return new Map(); }), 2), queue = _c[0], setQueue = _c[1];
    var pushSnackbarToQueue = React.useCallback(function (snackbar) {
        var count = queue.size;
        var lastKey = __spreadArray([], __read(queue.keys()), false).sort()[0];
        if (lastKey && count === maxNotify) {
            var notify = queue.get(lastKey);
            if (notify) {
                queue.set(lastKey, __assign(__assign({}, notify), { close: true }));
            }
            // queue.delete(lastKey);
        }
        queue.set(snackbar.id, snackbar);
        setQueue(function () { return new Map(queue); });
    }, [maxNotify, queue]);
    var enqueueSnackbar = React.useCallback(function (message, options) {
        if (options === void 0) { options = defaultSnackbarOptions; }
        var id = Date.now();
        var snackbar = __assign(__assign(__assign({}, defaultSnackbarOptions), options), { id: id, message: String(message).slice(0, 249) });
        pushSnackbarToQueue(snackbar);
        return id;
    }, [pushSnackbarToQueue]);
    var closeSnackbar = React.useCallback(function (key) {
        if (queue.has(key)) {
            queue.delete(key);
        }
        setQueue(function () { return new Map(queue); });
    }, [queue]);
    var contextValue = React.useMemo(function () { return ({
        enqueueSnackbar: enqueueSnackbar,
        closeSnackbar: closeSnackbar,
    }); }, [closeSnackbar, enqueueSnackbar]);
    return (React.createElement(NotifyContext.Provider, { value: contextValue },
        children,
        React.createElement(NotifyContainer, { notifies: __spreadArray([], __read(queue.values()), false), onClose: closeSnackbar })));
};
export default Provider;
