import React from 'react';
import PageNavigation from './page-navigation';
import Grid from '@mui/material/Grid';
var FormWrapper = function (_a) {
    var children = _a.children;
    return (React.createElement(Grid, { id: 'form-root', container: true, rowSpacing: 1, sx: { position: 'relative', pb: 1 } },
        React.createElement(PageNavigation
        // style={{maxHeight: 48}}
        , null),
        children));
};
export default FormWrapper;
