var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useStoreMap } from 'effector-react';
import { $views } from '.';
export var useView = function (view) {
    return useStoreMap({
        store: $views,
        keys: [view],
        fn: function (state, _a) {
            var _b = __read(_a, 1), key = _b[0];
            return state[key || ''] || {};
        },
    });
};
