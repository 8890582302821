var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Alert, Slide, Snackbar, } from '@mui/material';
import { useDebounce } from '@alta-m1/hooks';
var SlideTransition = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Slide, __assign({}, props, { direction: "up" }), children));
};
var StyledSnackbar = React.memo(function (_a) {
    var message = _a.message, onClose = _a.onClose;
    var _b = __read(React.useState(!!message), 2), open = _b[0], setOpen = _b[1];
    var handleClose = React.useCallback(function (_, reason) {
        if (reason === 'timeout') {
            onClose();
        }
        return;
    }, [onClose]);
    React.useEffect(function () {
        if (open !== !!message) {
            setOpen(function (open) { return !open; });
        }
    }, [message, open]);
    var deferredOpen = useDebounce(open, 300);
    var deferredMessage = useDebounce(message, 300);
    return (React.createElement(Snackbar, { open: open ? deferredOpen : open, autoHideDuration: 4000, onClose: handleClose, TransitionComponent: SlideTransition, anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
        } },
        React.createElement(Alert, { onClose: function () { return handleClose(null, 'timeout'); }, severity: "error", sx: { width: '100%' }, variant: 'filled' }, deferredMessage)));
});
export default StyledSnackbar;
