import { useView } from '@alta-m1/models/hooks';
import { useStore } from 'effector-react';
import { mapedRoutes } from './routes';
import ViewWrapper from './wrapper';

type OnlyPartial<T> = T extends Partial<infer U> ? Exclude<T, U> : T;
type InferPartial<T> = OnlyPartial<T> extends Partial<infer U> ? U : T;

type ViewProps = {
    name: string;
    component: React.ComponentType<Page.IFormProps | Page.IListProps | Page.IProps>;
    [key: string]: any;
};

const View = ({ name, component: Component, ...props }: ViewProps) => {
    const view = useView(name);
    const { id = '', type = '' } = useStore(mapedRoutes[name].$query);
    const rest = { id, type };

    return (
        <ViewWrapper mode={view.mode} title={view.title}>
            <Component
                {...props}
                {...rest}
                view={view as InferPartial<typeof view>}
                meta={view as InferPartial<typeof view>}
                route={mapedRoutes[name]}
            />
        </ViewWrapper>
    );
};

export default View;