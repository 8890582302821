var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Icon, List, ListItemButton, ListItemIcon, ListItemText, Box, Skeleton, Stack, } from '@mui/material';
import { $appLoading } from '@alta-m1/models';
import { menu } from '@alta-m1/models/events';
import { useView, useMenu, useMenuActive } from '@alta-m1/models/hooks';
import { useStore } from 'effector-react';
import { SvgOrMaterialIcon } from '../styled-mui-components';
import { useNavigate, usePath } from '@alta-m1/router';
// import { useNavigate, useLocation } from 'react-router-dom';
import useAppMenu from './use-app-menu';
var toggleActive = menu.toggleActive;
var StyledListItem = function (_a) {
    var href = _a.href, onClick = _a.onClick, selected = _a.selected, icon = _a.icon, title = _a.title, isLink = _a.isLink, isFolder = _a.isFolder, disabled = _a.disabled, _b = _a.description, description = _b === void 0 ? 'Description' : _b;
    var props = isLink ? { component: 'a', href: href !== null && href !== void 0 ? href : '', target: '_blank' } : {};
    return (React.createElement(ListItemButton, __assign({}, props, { dense: true, onClick: onClick, selected: selected, sx: {
            borderBottom: function (theme) { return "1px solid ".concat(theme.accents.hulk.border); },
            p: '10px 16px',
            flexWrap: 'wrap',
            ':hover': {
                backgroundColor: function (theme) { return theme.accents.hulk.light; },
            },
            '&.selected': {
                backgroundColor: function (theme) { return "".concat(theme.accents.hulk.dark, " !important"); },
            },
        }, disabled: disabled, classes: {
            selected: 'selected',
        } }),
        React.createElement(Box, { sx: {
                width: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
            } },
            React.createElement(ListItemIcon, { sx: {
                    fontSize: function (theme) { return theme.typography.pxToRem(20); },
                    color: function (theme) { return theme.accents.hulk.contrastText; },
                    minWidth: 0,
                } },
                React.createElement(SvgOrMaterialIcon, { icon: icon })),
            React.createElement(ListItemText, { sx: {
                    '& .text': {
                        fontSize: function (theme) { return theme.typography.pxToRem(13); },
                        pl: '12px',
                        color: function (theme) { return theme.accents.hulk.contrastText; },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                }, primary: title, primaryTypographyProps: {
                    title: title,
                    variant: 'body2',
                    className: 'text',
                } }),
            isLink || isFolder ? (React.createElement(Icon, { fontSize: 'small', style: {
                    color: '#fff',
                } }, isFolder ? 'chevron_right' : 'launch')) : null),
        React.createElement(Box, { sx: {
                color: function (theme) { return theme.accents.hulk.description; },
                fontSize: function (theme) { return theme.typography.pxToRem(10); },
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                minHeight: 14,
                maxHeight: 14,
            } }, description || 'Description')));
};
var MenuFolder = function (_a) {
    var item = _a.item;
    // const classes = useStyles();
    var title = item.title, icon = item.icon, description = item.description, id = item.id;
    var active = useMenuActive();
    var handleClick = function () {
        toggleActive(id);
    };
    return (React.createElement(StyledListItem, { onClick: handleClick, selected: item.id === (active === null || active === void 0 ? void 0 : active.id), icon: icon, title: title, isFolder: true, description: description }));
};
var MenuLink = function (_a) {
    var onClickItem = _a.onClickItem, item = _a.item;
    var pathname = usePath();
    var menu = useMenu();
    var icon = item.icon, id = item.id;
    var view = useView(item.view);
    var _b = view.title, title = _b === void 0 ? item.title : _b, name = view.name, access = view.access, developing = view.developing, label = view.label, description = view.description, mode = view.mode, alias = view.alias;
    var handleClick = function () {
        if (mode === 'link') {
            return;
        }
        toggleActive(id);
        name && onClickItem(name);
    };
    var active = pathname.replaceAll('/', '') === name && !menu.active;
    return (React.createElement(StyledListItem, { onClick: handleClick, disabled: !access || developing || !name, icon: icon, title: title, description: label || description, selected: active, isLink: mode === 'link', href: alias }));
};
var MenuWrapper = function (_a) {
    var item = _a.item, props = __rest(_a, ["item"]);
    // const item = useStore($item);
    if (item.type === 'folder') {
        return (React.createElement(MenuFolder, __assign({}, props, { item: item })));
    }
    return (React.createElement(MenuLink, __assign({}, props, { item: item })));
};
var Menu = function () {
    var menu = useMenu();
    var loading = useStore($appLoading);
    var _a = useAppMenu(), toggle = _a.toggle, isMobile = _a.isMobile;
    var items = menu.items;
    // const location = useLocation();
    // const [active, setActive] = React.useState(() => location.pathname.replace('/', ''));
    // const items = user.menu;
    var navigate = useNavigate();
    var handleClickItem = function (name) {
        // setActive(name);
        isMobile && toggle(false);
        navigate(name);
    };
    if (loading) {
        return (React.createElement(Stack, { spacing: 1, sx: { p: 1 } }, Array(5).fill(null).map(function (_, index) {
            return (React.createElement(Skeleton, { key: index, sx: { bgcolor: 'grey.700' }, height: 50, width: '100%', variant: 'rectangular' }));
        })));
    }
    return (React.createElement(List, { dense: true, disablePadding: true, component: 'div' }, items.map(function (item) {
        return React.createElement(MenuWrapper, { key: item.id, item: item, onClickItem: handleClickItem });
    })));
};
export default Menu;
