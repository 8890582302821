var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { createStore, createEvent, createEffect, combine, attach } from 'effector';
import { $currentTranslate } from '../i18n';
import { $granted, $isAdmin } from '../user';
import getValue from 'lodash/get';
// import objectPath from 'object-path';
export var loadFx = createEffect('@views/load');
export var updateView = createEvent('@views/change');
export var updateViews = createEvent('@views/update');
export var $viewsStore = createStore({}, { name: '@views' });
export var $viewsTitle = combine($viewsStore, $currentTranslate, function (views, translate) {
    return Object.keys(views).reduce(function (acc, key) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[key] = getValue(translate, "forms.".concat(key, ".title"), null), _a)));
    }, {});
});
export var $views = combine($viewsStore, $granted, $currentTranslate, $isAdmin, function (views, granted, translate, isAdmin) {
    var entries = Object.entries(views).map(function (_a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], view = _c[1];
        var access = view.mode === 'admin'
            ? isAdmin
            : view.mode === 'page' || view.mode === 'link'
                ? true
                : granted.includes((_b = view.alias) !== null && _b !== void 0 ? _b : '') || isAdmin;
        var title = getValue(translate, "forms.".concat(view.name, ".title"), view.name);
        var label = view.developing
            ? getValue(translate, 'interface.labels.developing', '')
            : !access
                ? getValue(translate, 'interface.labels.access', '')
                : '';
        var description = getValue(translate, "forms.".concat(view.name, ".description"), view.name);
        return [
            key,
            __assign(__assign({}, view), { access: access, title: title, label: label, description: description }),
        ];
    });
    return Object.fromEntries(entries);
});
var getAccessByViewBaseFx = createEffect('get-access', {
    handler: function (view) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (view == null)
                    throw 'not-found';
                if (view.developing)
                    throw 'developing';
                if (!view.access)
                    throw 'forbidden';
                return [2 /*return*/, 'success'];
            });
        });
    },
});
export var getAccessViewFx = attach({
    effect: getAccessByViewBaseFx,
    source: $views,
    mapParams: function (name, views) {
        return views[name];
    },
});
