var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
var Emitter = /** @class */ (function () {
    function Emitter() {
        var _this = this;
        this._subscribers = [];
        this.subscribe = function (subscriber) {
            _this._subscribers = __spreadArray(__spreadArray([], __read(_this._subscribers), false), [subscriber], false);
        };
        this.unsubscribe = function (name) {
            _this._subscribers = _this._subscribers.filter(function (subscriber) { return subscriber.name !== name; });
        };
        this.emit = function (type, statistic, msgType) {
            _this._subscribers.forEach(function (_a) {
                var action = _a.action;
                if (typeof action === 'function') {
                    if (type === 'success') {
                        action(msgType || true, false, statistic);
                    }
                    else {
                        action(false, msgType || true, statistic);
                    }
                }
            });
        };
    }
    return Emitter;
}());
export var subscribe = (_a = new Emitter(), _a.subscribe), unsubscribe = _a.unsubscribe, emit = _a.emit;
