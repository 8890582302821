var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button } from '@alta-m1/components/buttons';
import { Grid, Paper, Stack, } from '@mui/material';
import React from 'react';
import { controls } from '@alta-m1/router';
import clsx from 'clsx';
import { useIntersectionObserver, } from '@alta-m1/hooks';
var options = {
    rootMargin: '-49px 0px 0px 0px',
    threshold: [1],
};
var PageNavigation = function (props) {
    var _a = __read(React.useState(false), 2), fixed = _a[0], setFixed = _a[1];
    var root = React.useRef(null);
    var entry = useIntersectionObserver(root, options);
    React.useEffect(function () {
        if (entry) {
            if (entry.isIntersecting) {
                setFixed(false);
            }
            else {
                setFixed(true);
            }
        }
    }, [entry]);
    var handleNavigateBack = React.useCallback(function () {
        controls.back();
        /*if (historyLength > 1) {
            goBack();
        } else {
            replace('/');
        }*/
    }, []);
    return (React.createElement(Grid, { ref: root, item: true, xs: 12, sx: { position: 'sticky', top: '48px', zIndex: 1 } },
        React.createElement(Paper, __assign({}, props, { 
            // ref={root}
            variant: 'outlined', square: true, className: clsx({ fixed: fixed }), sx: {
                width: 1,
                mt: -1,
                backgroundColor: 'transparent',
                border: 'none',
                borderBottom: function (theme) { return "1px solid ".concat(theme.palette.divider); },
                zIndex: 1,
                transition: 'background .2s ease-out',
                // offsetPosition: 'auto',
                '&.fixed': {
                    /*width,
                    mt: -2,
                    position: 'fixed',
                    top: 'auto',*/
                    // left: 0,
                    backgroundColor: 'background.paper',
                    border: function (theme) { return "1px solid ".concat(theme.palette.divider, " !important"); },
                    // animationDuration: '.7s',
                    // animationName: 'fade',
                    // animationTimingFunction: 'ease-in-out',
                    // willChange: 'transform',
                },
            } }),
            React.createElement(Stack, { direction: 'row', spacing: 2, alignItems: 'center', sx: { p: 1, width: 1 } },
                React.createElement(Button, { type: 'button', item: 'back', variant: 'outlined', color: 'default', action: handleNavigateBack, size: 'small' }),
                React.createElement(Stack, { id: 'navigation-placeholder', direction: 'row', spacing: 1, alignItems: 'center' })))));
};
export default PageNavigation;
