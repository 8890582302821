import React from 'react';
var useIsMounted = function () {
    var mounted = React.useRef(false);
    var get = React.useCallback(function () { return mounted.current; }, []);
    React.useEffect(function () {
        mounted.current = true;
        return function () {
            mounted.current = false;
        };
    }, []);
    return get;
};
export default useIsMounted;
