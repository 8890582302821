var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { sample } from 'effector';
import { $gridCount, $counters, addCounter, clearCounters, printedInc, verifiedInc, $printed, $verified, resetPrinted, resetVerified, setCounter, 
// setCounterPatched,
setSize, $gridSize, 
// updateCountersFx,
updateCounters, updatePrinted, updateVerified,
// updateTrigger,
 } from '.';
// import { closed } from '../';
// import { CupCounterPatchedArgs } from '../types';
import map from 'lodash/map';
import concat from 'lodash/concat';
import drop from 'lodash/drop';
$printed.on(updatePrinted, function (_, value) { return value; }).reset(resetPrinted);
$verified.on(updateVerified, function (_, value) { return value; }).reset(resetVerified);
sample({
    clock: printedInc,
    source: { size: $gridCount, printed: $printed },
    fn: function (_a, value) {
        var printed = _a.printed, size = _a.size;
        if (value) {
            if (value > size)
                return value % size || size;
            var sum = printed + value;
            return sum > size ? sum - size : sum;
        }
        return printed + 1 > size ? 1 : ++printed;
    },
    target: updatePrinted,
});
sample({
    clock: verifiedInc,
    source: { size: $gridCount, verified: $verified },
    fn: function (_a, value) {
        var verified = _a.verified, size = _a.size;
        if (value) {
            if (value > size)
                return value % size || size;
            var sum = verified + value;
            return sum > size ? sum - size : sum;
        }
        return verified + 1 > size ? 1 : ++verified;
    },
    target: updateVerified,
});
/* updateCountersFx.use(async ({ rewrite: type, count }) => {
    try {
        const payload = map(new Array(count).fill(''), (ignored, index) => ({ type, counter: index + 1 }));
        return Promise.resolve(payload);
    } catch (e) {
        return Promise.reject(e);
    }
}); */
$counters
    .on(addCounter, function (state, _a) {
    var type = _a.rewrite, counter = _a.counter, max = _a.max;
    var counters = __spreadArray([], __read(state), false);
    var index = counters.findIndex(function (item) { return item.counter === counter; });
    if (index === -1) {
        if (state.length === max || counter > state.length) {
            return [{ type: type, counter: 1 }];
        }
        counters.push({ type: type, counter: counter });
    }
    else {
        counters.splice(index, 1, { type: type, counter: counter });
    }
    return counters;
})
    .on(updateCounters, function (state, _a) {
    var type = _a.type, rewrite = _a.rewrite, count = _a.count;
    var counter = rewrite ? 1 : state.length + 1;
    var payload = map(new Array(count).fill(''), function (_, index) { return ({ type: type, counter: index + counter }); });
    if (rewrite) {
        return concat(payload, drop(state, payload.length));
    }
    return concat(state, payload);
    // return unionBy(payload, state, 'counter');
})
    .reset(clearCounters);
$gridSize.on(setSize, function (_, payload) { return payload; });
sample({
    clock: $printed,
    source: { max: $gridCount, verified: $verified },
    filter: function (_a, printed) {
        var max = _a.max, verified = _a.verified;
        return printed > 0 && verified === max;
    },
    target: resetVerified,
});
sample({
    clock: $verified,
    source: $gridCount,
    filter: function (count, verified) {
        return verified === count;
    },
    target: resetPrinted,
});
/* sample({
    clock: setCounter,
    source: { printed: $printed, verified: $verified, size: $gridCount },
    fn({ printed, verified, size }, args) {
        const counter = args[0] === 'print' ? printed : verified;
        return [...args, counter, size] as CupCounterPatchedArgs;
    },
    target: setCounterPatched,
}); */
/* sample({
    clock: updateCounters,
    filter(params) {
        return params.type === 'print';
    },
    fn(params) {
        return params.count;
    },
    target: printedInc,
});
sample({
    clock: updateCounters,
    filter(params) {
        return params.type === 'confirm';
    },
    fn(params) {
        return params.count;
    },
    target: verifiedInc,
});
*/
/* sample({
    clock: addCounter,
    filter({ type }) {
        return type === 'print';
    },
    fn() {
        return undefined;
    },
    target: printedInc,
});
sample({
    clock: addCounter,
    filter({ type }) {
        return type === 'confirm';
    },
    fn() {
        return undefined;
    },
    target: verifiedInc,
}); */
sample({
    clock: setCounter,
    filter: function (args) {
        return args[0] === 'print';
    },
    fn: function (args) {
        return args[1];
    },
    target: printedInc,
});
sample({
    clock: setCounter,
    filter: function (args) {
        return args[0] === 'confirm';
    },
    fn: function (args) {
        return args[1];
    },
    target: verifiedInc,
});
sample({
    clock: setCounter,
    source: { size: $gridCount, state: $counters },
    filter: function (_, args) {
        return args[1] != null;
    },
    fn: function (_a, _b) {
        var size = _a.size, state = _a.state;
        var _c = __read(_b, 3), type = _c[0], count = _c[1], rewriteType = _c[2];
        var length = count > size ? count % size || size : count;
        var sLength = state.length;
        var sumLength = length + sLength;
        var isMore = sumLength > size;
        var rewrite = rewriteType != null;
        var counter = isMore || rewrite ? 1 : sLength + 1;
        var newCount = rewrite ? length : isMore ? sumLength - size : length;
        var payload = map(new Array(newCount).fill(''), function (_, index) { return ({ type: type, counter: index + counter }); });
        if (rewrite) {
            return concat(payload, drop(state, payload.length));
        }
        if (isMore) {
            return payload;
        }
        return concat(state, payload);
    },
    target: $counters,
});
/* sample({
    clock: updateTrigger,
    source: { size: $gridCount },
    filter(_, { clock: args }) {
        return args[1] != null;
    },
    fn({ size }, { clock: [type, count, rewriteType] }) {
        // type = rewriteType ?? type;
        let length = count!;
        if (length >= size) {
            length = length % size || size;
        }
        return { type: rewriteType ?? type, rewrite: rewriteType != null, count: length };
    },
    target: updateCounters,
}); */
sample({
    clock: setCounter,
    source: { printed: $printed, verified: $verified, size: $gridCount },
    filter: function (_, args) {
        return args[1] == null;
    },
    fn: function (_a, _b) {
        var printed = _a.printed, verified = _a.verified, size = _a.size;
        var _c = __read(_b, 3), type = _c[0], _ = _c[1], rewriteType = _c[2];
        // rewriteType = rewriteType ?? type;
        var counter = (type === 'print' ? printed : verified) + 1;
        return { type: type, rewrite: rewriteType !== null && rewriteType !== void 0 ? rewriteType : type, counter: counter, max: size };
    },
    target: addCounter,
});
// eslint-disable-next-line effector/no-watch
/* setCounterPatched.watch(([type, count, rewriteType, counter, size]) => {
    rewriteType = rewriteType ?? type;
    if (count) {
        let length = count;
        if (count >= size) {
            length = count % size || size;
        }
        updateCountersFx({ type, rewrite: rewriteType, count: length });
    } else {
        addCounter({ type, rewrite: rewriteType, counter });
    }
}); */
