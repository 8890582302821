var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { DateTimePicker as KeyboardDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Icon, TextField } from '@mui/material';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { ErrorAdornment } from '../components';
import { isValidDate } from '../../helpers';
import { getCurrentCulture } from '@alta-m1/models/helpers';
var maskMap = {
    en: '__/__/____ __:__ __',
    ru: '__.__.____ __:__',
};
var formats = {
    en: 'MM/dd/yyyy hh:mm aaa',
    ru: 'dd.MM.yyyy HH:mm',
};
var localeMap = {
    en: enLocale,
    ru: ruLocale,
};
var DateInput = function (props) {
    var _a, _b, _c;
    var _d = props.input, originalValue = _d.value, onChange = _d.onChange, name = _d.name, onBlur = _d.onBlur, onFocus = _d.onFocus, active = props.meta.active, _e = props.options, label = _e.label, readonly = _e.readonly, disabled = _e.disabled, options = _e.options, labelProps = props.labelProps, 
    // required,
    loading = props.loading, error = props.error;
    var locale = getCurrentCulture();
    var variant = (_a = options === null || options === void 0 ? void 0 : options.variant) !== null && _a !== void 0 ? _a : 'standard';
    var inputFormat = (_b = options === null || options === void 0 ? void 0 : options.format) !== null && _b !== void 0 ? _b : formats[locale];
    var convertValue = (_c = options === null || options === void 0 ? void 0 : options.convert) !== null && _c !== void 0 ? _c : false;
    var value = React.useMemo(function () {
        if (convertValue && originalValue && typeof originalValue === 'string' && !isValidDate(originalValue)) {
            return parse(originalValue, inputFormat, new Date());
        }
        if (isValidDate(originalValue)) {
            return new Date(originalValue);
        }
        return null;
    }, [convertValue, inputFormat, originalValue]);
    return (React.createElement(LocalizationProvider
    // utils={MomentUtils}
    , { 
        // utils={MomentUtils}
        adapterLocale: localeMap[locale], dateAdapter: AdapterDateFns },
        React.createElement(KeyboardDatePicker, { mask: maskMap[locale], value: value || null, inputFormat: inputFormat, onChange: function (value) {
                onChange(convertValue && value ? format(value, inputFormat) : value);
            }, ampm: locale === 'en', label: label, renderInput: function (props) {
                var _a;
                return (React.createElement(TextField, __assign({ name: name, onBlur: onBlur, onFocus: onFocus }, props, { InputLabelProps: __assign(__assign({}, props.InputLabelProps), labelProps), error: (!!error || props.error) && !disabled, InputProps: __assign(__assign({}, props.InputProps), { readOnly: readonly, endAdornment: (React.createElement(React.Fragment, null, (_a = props.InputProps) === null || _a === void 0 ? void 0 :
                            _a.endAdornment,
                            React.createElement(ErrorAdornment, { message: error, open: active }))) }), fullWidth: true, variant: variant })));
            }, 
            // error={Boolean(error)}
            // invalidDateMessage="Некорректная дата"
            // maxDateMessage="Дата не должна быть после даты 2100-01-01"
            // minDateMessage="Дата не должна быть до даты 1900-01-01"
            // inputFormat={inputFormat}
            // autoOk
            // mask={(value) => (value ? [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/] : [])}
            // cancelText="Отмена"
            // showTodayButton
            // todayText="Сегодня"
            components: {
                LeftArrowIcon: function () { return React.createElement(Icon, null, "chevron_left"); },
                RightArrowIcon: function () { return React.createElement(Icon, null, "chevron_right"); },
                OpenPickerIcon: function () { return React.createElement(Icon, null, "today"); },
            }, disabled: disabled || loading })));
};
export default DateInput;
