var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Icon, InputAdornment, Tooltip } from '@mui/material';
var List = function (_a) {
    var options = _a.options;
    return (React.createElement("ul", { style: { padding: 0, margin: 0, marginLeft: 4 } }, options.map(function (option, index) { return (React.createElement("li", { key: index }, option)); })));
};
var Error = function (_a) {
    var _b = _a.message, message = _b === void 0 ? '' : _b, _c = _a.position, position = _c === void 0 ? 'end' : _c, propOpen = _a.open;
    var _d = __read(React.useState(false), 2), open = _d[0], setOpen = _d[1];
    var tooltipOpen = propOpen || open;
    var handlePointerEnter = React.useCallback(function () {
        if (!propOpen) {
            setOpen(true);
        }
    }, [propOpen]);
    var handlePointerOut = React.useCallback(function () {
        if (!propOpen && open) {
            setOpen(false);
        }
    }, [open, propOpen]);
    var title = React.useMemo(function () {
        if (Array.isArray(message)) {
            if (message.length === 1)
                return message[0];
            return React.createElement(List, { options: message });
        }
        return message;
    }, [message]);
    if (message) {
        return (React.createElement(InputAdornment, { position: position, disableTypography: true },
            React.createElement(Tooltip, { id: 'controlled', title: title, placement: 'top', arrow: true, disableFocusListener: true, open: tooltipOpen },
                React.createElement(Icon, { onPointerEnter: handlePointerEnter, onPointerOut: handlePointerOut, fontSize: 'small', color: 'error' }, "error_outline"))));
    }
    return null;
};
export default Error;
