var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { 
// forward,
sample, merge, } from 'effector';
import { debounce, delay,
// combineEvents
 } from 'patronum';
import { b64DecodeUnicode, b64EncodeUnicode } from '@alta-m1/helpers';
import { settingsLocalStorage, changeSettings, $settings, updateSettings, 
// updateSnapshots,
loadFx, saveFx, $printers, setDefaultPrinter, deletePrinter, updatePrinter, $customization, updateCustomization, updateCustomizationItem, $certificate, updateCertificateSettings, $serializers, deleteSerializer, updateSerializer, setDefaultSerializer, } from '.';
import { logout, triggerFx } from '../authenticate';
$settings.watch(function (state) {
    console.log('SETTINGS STORE: ', state);
});
$serializers.watch(function (state) {
    console.log('serializers STORE: ', state);
});
$printers.watch(function (state) {
    console.log('printers STORE: ', state);
});
$settings
    .on(updateSettings, function (state, payload) { return (__assign(__assign({}, state), payload)); })
    .on(logout, function () { return ({ printers: {}, print: {}, customization: {}, certificate: {}, serializers: {} }); });
/* forward({
    from: $settings,
    to: updateSnapshots,
}); */
$certificate.on(updateCertificateSettings, function (state, payload) { return (__assign(__assign({}, state), payload)); });
/* const certificateTrigger = combineEvents({
    events: [updateCertificateSettings, $certificate.updates],
    reset: saveFx.finally,
}); */
/* sample({
    clock: certificateTrigger,
    source: $certificate,
    fn(certificate) {
        return ['certificate', certificate] as EventPayload<typeof changeSettings>;
    },
    target: changeSettings,
}); */
debounce({
    source: sample({
        clock: delay({
            source: updateCertificateSettings,
            timeout: 500,
        }),
        source: $certificate,
        fn: function (certificate) {
            return ['certificate', certificate];
        },
    }),
    timeout: 10000,
    target: changeSettings,
});
$customization.on(updateCustomization, function (state, _a) {
    var _b;
    var _c = __read(_a, 2), key = _c[0], value = _c[1];
    return __assign(__assign({}, state), (_b = {}, _b[key] = value, _b));
});
var defaultCustomizationItem = {
    order: [],
    widths: [],
    sort: [],
    filters: {},
    hidden: [],
};
sample({
    clock: debounce({
        source: updateCustomizationItem,
        timeout: 500,
    }),
    source: $customization,
    fn: function (state, _a) {
        var _b;
        var _c;
        var _d = __read(_a, 3), key = _d[0], property = _d[1], value = _d[2];
        var item = (_c = state[key]) !== null && _c !== void 0 ? _c : defaultCustomizationItem;
        return [
            key,
            __assign(__assign({}, item), (_b = {}, _b[property] = value, _b)),
        ];
    },
    target: updateCustomization,
});
/* const customizationTrigger = combineEvents({
    events: [updateCustomization, $customization.updates],
    reset: saveFx.finally,
}); */
/* sample({
    clock: customizationTrigger,
    source: $customization,
    fn(customization) {
        return ['customization', customization] as EventPayload<typeof changeSettings>;
    },
    target: changeSettings,
}); */
debounce({
    source: sample({
        clock: delay({
            source: updateCustomization,
            timeout: 500,
        }),
        source: $customization,
        fn: function (customization) {
            return ['customization', customization];
        },
    }),
    timeout: 10000,
    target: changeSettings,
});
/* sample({
    // clock: customizationTrigger,
    source: $customization,
    fn(customization) {
        return ['customization', customization] as EventPayload<typeof changeSettings>;
    },
    target: changeSettings,
}); */
$printers
    .on(setDefaultPrinter, function (state, id) { return (__assign(__assign({}, state), { default: id })); })
    .on(deletePrinter, function (state, id) {
    if (id in state.list) {
        delete state.list[id];
    }
    return __assign({}, state);
})
    .on(updatePrinter, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { list: __assign(__assign({}, state.list), (_a = {}, _a[payload.id] = payload, _a)) });
});
// const printersTrigger1 = combineEvents({ events: [setDefaultPrinter, $printers.updates], reset: saveFx.finally });
// const printersTrigger2 = combineEvents({ events: [deletePrinter, $printers.updates], reset: saveFx.finally });
// const printersTrigger3 = combineEvents({ events: [updatePrinter, $printers.updates], reset: saveFx.finally });
/* sample({
    clock: [printersTrigger1, printersTrigger2, printersTrigger3],
    source: $printers,
    fn(printers) {
        return ['printers', printers] as EventPayload<typeof changeSettings>;
    },
    target: changeSettings,
}); */
// код гарантирует, что событие changeSettings будет отправлено только после того, как 
// список принтеров будет обновлен и пройдет 500 миллисекунд после последнего изменения. 
// Однако, если после обновления принтеров проходит более 10 секунд, событие changeSettings будет 
// отправлено независимо от того, были ли еще изменения или нет.
debounce({
    source: sample({
        clock: delay({
            source: merge([setDefaultPrinter, deletePrinter, updatePrinter]),
            timeout: 500,
        }),
        source: $printers,
        fn: function (printers) {
            return ['printers', printers];
        },
    }),
    timeout: 10000,
    target: changeSettings,
});
/* sample({
    // clock: [printersTrigger1, printersTrigger2, printersTrigger3],
    source: $printers,
    fn(printers) {
        return ['printers', printers] as EventPayload<typeof changeSettings>;
    },
    target: changeSettings,
}); */
sample({
    clock: changeSettings,
    fn: function (params) {
        var _a;
        return _a = {}, _a[params[0]] = params[1], _a;
    },
    target: updateSettings,
});
delay({
    source: sample({
        clock: changeSettings,
        fn: function (_a) {
            var _b = __read(_a, 2), name = _b[0], value = _b[1];
            return {
                data: {
                    Domain: window.location.origin,
                    ParamName: name,
                    ParamValue: JSON.stringify({ value: b64EncodeUnicode(JSON.stringify(value)) }),
                },
            };
        },
    }),
    timeout: 1000,
    target: saveFx,
});
//обеспечивает автоматическое обновление локального хранилища или 
//интерфейса при изменении настроек в переменной состояния $settings.
// eslint-disable-next-line effector/no-watch
$settings.watch(settingsLocalStorage);
sample({
    clock: triggerFx.done,
    filter: function (_a) {
        var result = _a.result;
        return result;
    },
    target: loadFx,
});
sample({
    clock: loadFx.doneData,
    filter: function (_a) {
        var data = _a.data;
        return data.query.message == null;
    },
    fn: function (response) {
        var list = response.data.query.list;
        return list.reduce(function (acc, _a) {
            var _b;
            var ParamName = _a.ParamName, ParamValue = _a.ParamValue;
            var key = String(ParamName).toLowerCase();
            var value = JSON.parse(b64DecodeUnicode(JSON.parse(ParamValue).value));
            return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
        }, {});
    },
    target: updateSettings,
});
$serializers
    // .on(setDefaultPrinter, (state, id) => ({ ...state, default: id }))
    .on(deleteSerializer, function (state, id) {
    if (id in state.list) {
        delete state.list[id];
    }
    return __assign({}, state);
})
    .on(updateSerializer, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { list: __assign(__assign({}, state.list), (_a = {}, _a[payload.id] = payload, _a)) });
});
debounce({
    source: sample({
        clock: delay({
            source: merge([setDefaultSerializer, deleteSerializer, updateSerializer]),
            timeout: 500,
        }),
        source: $serializers,
        fn: function (serializers) {
            return ['serializers', serializers];
        },
    }),
    timeout: 10000,
    target: changeSettings,
});
