var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useCustomization as useCustomizationItem } from '@alta-m1/models/hooks';
import { settings } from '@alta-m1/models/events';
var updateCustomizationItem = settings.updateCustomizationItem;
var noop = function () {
    var _ = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        _[_i] = arguments[_i];
    }
};
var useCustomization = function (_a) {
    var name = _a.name, use = _a.use;
    // const { settings } = useStores();
    var customization = useCustomizationItem(name);
    // const c = settings.customization.getValue(name);
    // const mounted = React.useRef<boolean>(false);
    var set = React.useCallback(function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        updateCustomizationItem(__spreadArray([name], __read(args), false));
    }, [name]);
    /* React.useEffect(() => {
        mounted.current = true;
        settings.customization.init(name);
        return () => {
            mounted.current = false;
        };
    }, []); // eslint-disable-line */
    if (!use) {
        return [{}, noop];
    }
    return [customization, set];
};
export default useCustomization;
