var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { Icon, SvgIcon } from '@mui/material';
import convert from '@alta-m1/react-from-dom';
export var SvgOrMaterialIcon = function (_a) {
    var icon = _a.icon;
    var _b = __read(React.useState(), 2), data = _b[0], setData = _b[1];
    var temp = icon.split(':');
    React.useEffect(function () {
        if (temp[0] === 'custom') {
            fetch("/icons/".concat(temp[1]))
                .then(function (response) { return response.ok ? response.text() : Promise.reject(new Error(response.statusText)); })
                .then(function (data) {
                setData(data);
            })
                .catch(function () { return setData(null); });
        }
    }, [temp]);
    var Component = React.useMemo(function () {
        if (data) {
            var root = document.createElement('div');
            root.innerHTML = data.trim();
            var svg_1 = root.firstChild;
            if ((svg_1 === null || svg_1 === void 0 ? void 0 : svg_1.nodeName) === 'svg') {
                root.remove();
                return function () { return React.createElement(React.Fragment, null, __spreadArray([], __read(svg_1.childNodes), false).map(function (child) { return convert(child); })); };
            }
            else {
                return null;
            }
        }
        return null;
    }, [data]);
    if (temp[0] === 'custom') {
        if (Component) {
            return (React.createElement(SvgIcon
            // component={component as any}
            , { 
                // component={component as any}
                viewBox: "0 0 24 24" },
                React.createElement(Component, null)));
        }
        return (React.createElement(Icon, null, "image"));
    }
    return (React.createElement(Icon, { style: { color: 'inherit' } }, temp[0]));
};
export default SvgOrMaterialIcon;
