var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { TextField, Box, Popover, List, ListItemButton, ListItemText, 
// Grid,
Divider, InputAdornment, Icon, CircularProgress, Button, Typography, Stack, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ErrorAdornment } from '@alta-m1/form';
import { createSystemDictionaryQuery } from '@alta-m1/client';
import { fetchFx } from '@alta-m1/models';
import { useI18n, useCatalog } from '@alta-m1/models/hooks';
import { b64EncodeUnicode, noop, sorter, } from '@alta-m1/helpers';
var query = createSystemDictionaryQuery('tnved', {
    params: [
        {
            name: 'TNVEDParam',
            type: 'String',
        },
    ],
});
// FIXME: use sx prop
var useGroupStyles = makeStyles(function (theme) { return ({
    list: __assign(__assign({}, theme.getScrollbarStyles(.5)), { overflowX: 'hidden', overflowY: 'auto', maxHeight: 'calc(100% - 37px)', height: 'calc(100% - 37px)' }),
    multiline: {
        margin: 0,
    },
}); });
var Group = function (props) {
    var data = props.data, loading = props.loading, name = props.name, onClick = props.onClick, first = props.first;
    var _a = __read(React.useState(''), 2), selected = _a[0], setSelected = _a[1];
    var _b = __read(React.useState(''), 2), value = _b[0], setValue = _b[1];
    // const search = useDebounce(value, 300);
    var filteredData = React.useMemo(function () { return sorter(data, value, { keys: ['id', 'text'] }); }, [data, value]);
    var handleClick = React.useCallback(function (code) { return function () {
        setSelected(code);
        onClick(name, code);
    }; }, [name, onClick]);
    var handleChange = React.useCallback(function (event) {
        React.startTransition(function () {
            setValue(event.target.value);
        });
    }, []);
    var classes = useGroupStyles();
    var count = React.useMemo(function () { return data.length; }, [data]);
    React.useEffect(function () {
        if (first && count === 1 && selected !== data[0].id) {
            var code = data[0].id;
            setSelected(code);
            onClick(name, code);
        }
    }, [count, data, first, name, onClick, selected]);
    return (React.createElement(Stack, { sx: { maxWidth: 'calc(33.33% - 11px)', width: 'calc(33.33% - 11px)', height: '100%' }, spacing: 1, alignItems: 'stretch' },
        React.createElement(TextField, { size: 'small', variant: 'outlined', InputProps: {
                endAdornment: React.createElement(InputAdornment, { position: 'end' },
                    React.createElement(Icon, { fontSize: 'small', color: count === 1 ? 'disabled' : 'inherit' }, "search")),
            }, fullWidth: true, value: value, onChange: handleChange, disabled: count === 1 }),
        React.createElement(Box, { width: '100%', 
            // className={classes.list}
            sx: {
                '&': function (theme) { return theme.getScrollbarStyles(.5); },
                overflowX: 'hidden',
                overflowY: 'auto',
                maxHeight: 'calc(100% - 37px)',
                height: 'calc(100% - 37px)',
            } }, loading ? (React.createElement(CircularProgress, { size: 14, color: 'primary', disableShrink: true })) : (React.createElement(List, { dense: true, disablePadding: true }, filteredData.map(function (_a) {
            var GUID = _a.GUID, id = _a.id, text = _a.text;
            var secondary = text.slice(text.search(/[а-я]/) - 1);
            return (React.createElement(ListItemButton, { key: GUID, dense: true, selected: id === selected, disableGutters: true, disableRipple: true, disabled: id === selected, onClick: handleClick(id) },
                React.createElement(ListItemText, { primary: id, secondary: secondary, secondaryTypographyProps: {
                        noWrap: true,
                        title: secondary,
                    }, classes: {
                        multiline: classes.multiline,
                    } })));
        }))))));
};
var computeData = function (data, length) {
    var output = data.filter(function (_a) {
        var id = _a.id;
        return id.length === length;
    });
    var excludeIds = output.map(function (_a) {
        var id = _a.id;
        return id;
    });
    var items = data
        .filter(function (_a) {
        var id = _a.id;
        return id.length >= length && !excludeIds.includes(id.slice(0, length));
    })
        .map(function (item) { return (__assign(__assign({}, item), { id: item.id.slice(0, length) })); })
        .reduce(function (acc, item) { return acc.find(function (_a) {
        var id = _a.id;
        return id === item.id;
    }) ? acc : __spreadArray(__spreadArray([], __read(acc), false), [item], false); }, []);
    return output.concat(items).sort(function (a, b) { return a.id < b.id ? -1 : 1; });
};
var defaultValues = [
    ['group1', { data: [], loading: false, error: '', codeLength: 4, step: 2, first: true, child: 'group2' }],
    ['group2', { data: [], loading: false, error: '', codeLength: 6, step: 4, child: 'group3' }],
    ['group3', { data: [], loading: false, error: '', codeLength: 10, step: 0 }],
];
var FEACNInput = function (props) {
    // const {query: client} = useApolloClient();
    var _a = __read(React.useState(function () { return new Map(defaultValues); }), 2), groups = _a[0], setGroups = _a[1];
    /* handleChange = event => {
        event.preventDefault()
        const name = event.target.name
        const value = event.target.value
        this.props.onChange(name, value)
    }*/
    var 
    // value,
    _b = props.input, onChange = _b.onChange, onFocus = _b.onFocus, inputs = __rest(_b, ["onChange", "onFocus"]), _c = props.meta, active = _c.active, initial = _c.initial, 
    // errors,
    _d = props.options, label = _d.label, disabled = _d.disabled, labelProps = props.labelProps, 
    // required,
    loading = props.loading, error = props.error;
    // const { catalog } = useStores();
    var catalog = useCatalog();
    var _e = __read(useI18n(), 1), getTranslate = _e[0];
    var mounted = React.useRef(false);
    var _f = __read(React.useState(null), 2), anchorEl = _f[0], setAnchorEl = _f[1];
    var _g = __read(React.useState(''), 2), selected = _g[0], setSelected = _g[1];
    var handleOpen = React.useCallback(function (event) {
        setAnchorEl(event.currentTarget);
        onFocus();
    }, [onFocus]);
    var handleClear = React.useCallback(function () {
        setGroups(function () { return new Map(defaultValues); });
        setSelected('');
    }, []);
    var handleClose = React.useCallback(function () {
        setAnchorEl(null);
        handleClear();
    }, [handleClear]);
    var handleChange = React.useCallback(function () {
        onChange(selected);
        handleClose();
    }, [handleClose, onChange, selected]);
    var open = !!anchorEl;
    var id = open ? 'transitions-popper' : undefined;
    var setGroupState = React.useCallback(function (key, state) {
        var _a;
        var group = (_a = groups.get(key)) !== null && _a !== void 0 ? _a : {};
        if (group.child) {
            setGroupState(group.child, { data: [] });
        }
        groups.set(key, __assign(__assign({}, group), state));
        React.startTransition(function () {
            setGroups(function () { return new Map(groups); });
        });
    }, [groups]);
    var getGroupState = React.useCallback(function (key) {
        return groups.get(key);
    }, [groups]);
    var getFirstGroupState = React.useCallback(function () {
        return __spreadArray([], __read(groups.values()), false).find(function (_a) {
            var first = _a.first;
            return first;
        });
    }, [groups]);
    var handleLoadGroupData = React.useCallback(function (key, code) {
        if (code === void 0) { code = catalog.codesFourDigits; }
        return __awaiter(void 0, void 0, void 0, function () {
            var group, groupCodeLength, groupStep, length, params, data, computedData, e_1;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        setGroupState(key, { loading: true, data: [], error: '' });
                        group = getGroupState(key);
                        groupCodeLength = (_a = group === null || group === void 0 ? void 0 : group.codeLength) !== null && _a !== void 0 ? _a : 4;
                        groupStep = (_b = group === null || group === void 0 ? void 0 : group.step) !== null && _b !== void 0 ? _b : 2;
                        length = groupCodeLength + groupStep;
                        params = b64EncodeUnicode(JSON.stringify({
                            TNVED: code,
                            TNVEDLength: length,
                        }));
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetchFx({ query: query, variables: { TNVEDParam: params, Alias: 'GS1.TNVED' } })
                                .then(function (_a) {
                                var data = _a.data;
                                var _b = data.system, message = _b.message, list = _b.list;
                                if (message) {
                                    return Promise.reject(new Error(message));
                                }
                                if (list) {
                                    try {
                                        var json = JSON.parse(list);
                                        return Promise.resolve(json);
                                    }
                                    catch (e) {
                                        return Promise.resolve([]);
                                    }
                                }
                                return Promise.resolve([]);
                            })];
                    case 2:
                        data = _c.sent();
                        computedData = computeData(data, groupCodeLength);
                        setGroupState(key, { loading: false, data: computedData });
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _c.sent();
                        setGroupState(key, { loading: false, data: [], error: e_1.message || String(e_1) });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }, [catalog.codesFourDigits, getGroupState, setGroupState]);
    var handleClickItem = React.useCallback(function (key, code) {
        setSelected(code);
        switch (key) {
            case 'group1':
                handleLoadGroupData('group2', code).then();
                break;
            case 'group2':
                handleLoadGroupData('group3', code).then();
                break;
            default:
                break;
        }
    }, [handleLoadGroupData]);
    var handleLoadFirstGroupData = React.useCallback(function () {
        var codes;
        if (initial) {
            codes = catalog.getCodesFourDigitsByCode(initial);
            // codes = group?.codesFourDigits;
        }
        // console.log(catalog.codesFourDigits);
        handleLoadGroupData('group1', codes).then();
    }, [catalog, handleLoadGroupData, initial]);
    React.useEffect(function () {
        mounted.current = true;
        handleLoadFirstGroupData();
        return function () {
            mounted.current = false;
        };
    }, []); // eslint-disable-line
    React.useEffect(function () {
        var firstGroup = getFirstGroupState();
        if (mounted.current && open && firstGroup && !(firstGroup.data.length > 0 || firstGroup.error || firstGroup.loading)) {
            handleLoadFirstGroupData();
        }
    }, [getFirstGroupState, handleLoadFirstGroupData, open]);
    return (React.createElement(Box, { width: '100%', sx: { position: 'relative' } },
        React.createElement(TextField, __assign({}, inputs, { label: label, fullWidth: true, autoComplete: "off", type: "text", InputLabelProps: labelProps, onChange: noop, onFocus: handleOpen, InputProps: {
                // readOnly: true,
                endAdornment: (React.createElement(ErrorAdornment, { message: error, open: active })
                /*<React.Fragment>
                    <InputAdornment position={'end'}><IconButton onClick={() => {
                        onChange('');
                        handleClear();
                    }} size={'small'}><Icon fontSize={'small'}>close</Icon></IconButton></InputAdornment>
                    <ErrorAdornment message={error} open={active}/>
                </React.Fragment>*/
                ),
            }, variant: 'standard', error: !!error && !disabled, "aria-readonly": true, disabled: disabled || loading })),
        React.createElement(Popover, { open: open, id: id, anchorEl: anchorEl, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }, onClose: handleClose, disableScrollLock: true, elevation: 3, sx: { zIndex: 'tooltip' }, PaperProps: {
                sx: {
                    width: 550,
                    overflow: 'hidden',
                    height: 250,
                    p: 1,
                    zIndex: 'tooltip',
                },
            } },
            React.createElement(Stack, { spacing: 1, alignItems: 'stretch', justifyContent: 'flex-start', sx: { height: '100%' } },
                React.createElement(Stack, { direction: 'row', justifyContent: 'space-between', alignItems: 'center' },
                    React.createElement(Typography, { variant: 'body2', color: 'textSecondary' }, selected),
                    React.createElement(Button, { size: 'small', disabled: selected === inputs.value, variant: 'outlined', onClick: handleChange }, getTranslate('buttons.apply'))),
                React.createElement(Stack, { direction: 'row', spacing: 1, divider: React.createElement(Divider, { orientation: 'vertical', flexItem: true }), alignItems: 'stretch', sx: { maxHeight: 'calc(100% - 40px)', height: 'calc(100% - 40px)', position: 'relative', width: '100%' } }, __spreadArray([], __read(groups.entries()), false).map(function (_a) {
                    var _b = __read(_a, 2), name = _b[0], state = _b[1];
                    return (React.createElement(Group, __assign({ key: name, name: name }, state, { onClick: handleClickItem })));
                }))))));
};
export default FEACNInput;
